import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  RegisterOptions,
} from "react-hook-form";
import { DurationPicker } from "../DurationPicker";
import { errorObject } from "./form_helpers";

export function FormDurationPicker<
  T extends FieldValues,
  N extends Path<T>
>(props: {
  name: N;
  control: Control<T>;
  errors?: FieldErrors<T>;
  disabled?: boolean;
  rules?: RegisterOptions;
}) {
  return (
    <Controller
      control={props.control}
      name={props.name}
      rules={props.rules}
      render={({ field }) => (
        <DurationPicker
          name={props.name}
          value={field.value}
          onChange={field.onChange}
          disabled={props.disabled}
          error={!!errorObject(props.errors, props.name)}
          helperText={errorObject(props.errors, props.name)?.message as string}
        />
      )}
    ></Controller>
  );
}
