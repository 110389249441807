import type { Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import type { FC, ReactNode } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      margin: theme.spacing(4),
      maxWidth: 1280,
    },
    header: {
      paddingBottom: theme.spacing(4),
    },
  })
);

export const Page: FC<{
  title?: string;
  subTitle?: string;
  children: ReactNode;
}> = (props) => {
  const classes = useStyles();

  return (
    <main className={classes.main}>
      {props.title && (
        <header className={classes.header}>
          <Typography variant="h1">{props.title}</Typography>
          <Typography variant="h2">{props.subTitle}</Typography>
        </header>
      )}
      {props.children}
    </main>
  );
};
