import CheckCircle from "@mui/icons-material/CheckCircle";
import HighlightOff from "@mui/icons-material/HighlightOff";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DateTime } from "luxon";
import { FC, Fragment } from "react";
import { S3Image } from "../../components/S3Image";
import type { Requirement } from "../../shared/api_schema";
import { RequirementType } from "../../shared/api_schema";
import { Colors, toLocale } from "../../shared/frontend";

export function requirementTypeString(type: RequirementType) {
  switch (type) {
    case RequirementType.ID:
      return "ID";
    case RequirementType.PHOTO:
      return "Photo";
    case RequirementType.SCAN:
      return "Scan";
    case RequirementType.SIGNATURE:
      return "Signature";
    case RequirementType.DRIVER_CODE:
      return "Driver Code";
  }
}

function detailKeyString(key: string) {
  switch (key) {
    case "receivedBy":
      return "Received By";
    default:
      return key;
  }
}

const StatusIcon: FC<{ complete: boolean }> = ({ complete }) => {
  if (complete) {
    return (
      <CheckCircle
        fontSize="large"
        style={{ color: Colors.DARK_GREEN, marginRight: "0.1em" }}
      />
    );
  } else {
    return (
      <HighlightOff
        fontSize="large"
        style={{ color: Colors.ERROR, marginRight: "0.1em" }}
      />
    );
  }
};

const useStyles = makeStyles({
  definitionTerm: {
    fontWeight: "bold",
  },
});

export const RequirementCard: FC<{
  requirement: Requirement;
  timeZone: string;
}> = ({ requirement, timeZone }) => {
  const classes = useStyles();

  return (
    <Grid item md={3}>
      <Card style={{ height: "100%" }}>
        <CardContent>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <StatusIcon complete={requirement.completedAt != null} />
            <Typography variant="h4" style={{ textTransform: "capitalize" }}>
              {requirementTypeString(requirement.type)}
            </Typography>
          </Box>
          {requirement.completedAt && (
            <Typography variant="body1">
              {toLocale(
                requirement.completedAt,
                timeZone,
                DateTime.DATETIME_MED
              )}
            </Typography>
          )}
          {requirement.completedAt && requirement.attachmentPath && (
            // Use presetS3Key() function here to test with known image if nescessary
            <S3Image s3Key={requirement.attachmentPath} />
          )}
          {requirement.detail && (
            <dl>
              {Object.entries(requirement.detail).map(([key, value]) => (
                <Fragment key={key}>
                  <dt className={classes.definitionTerm}>
                    {detailKeyString(key)}
                  </dt>
                  <dd>{value}</dd>
                </Fragment>
              ))}
            </dl>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};
