export enum Colors {
  // Colors
  BRAND_GREEN = "#49a641",
  NAVY = "#0c2533",
  CYAN = "#44c3cf",
  BLUE = "#3b84d9",
  GOLD = "#f2b21b",
  BEIGE = "#f1ede3",
  OFF_WHITE = "#f9f9f9",
  WHITE = "#ffffff",
  DARK_GREEN = "#006d11",

  // Tints
  BRAND_GREEN_80 = "#6db867",
  NAVY_92 = "#1f3643",
  NAVY_90 = "#243b47",
  NAVY_88 = "#293f4b",
  CYAN_88 = "#5acad5",
  CYAN_6 = "#f4fbfc",
  GOLD_88 = "#f4bb36",
  BEIGE_50 = "#f8f6f1",

  // Greys
  GREY_SLATE = "#8b9297",
  GREY_SMOKE = "#cbcecf",
  GREY_CLOUD = "#e4e6e6",

  // States (error, etc)
  SUCCESS = "#49a641", // Colors.BRAND_GREEN -- Adam says it's OK to use for "success" for now
  INFO = "#3b84d9", // Colors.BLUE
  ERROR = "#ba0007",
  DISABLED = "#e4e6e6", // Colors.GREY_CLOUD
}
