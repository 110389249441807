// Nested properties use dot notation, so have to dig
export function errorObject(
  errors: any | undefined,
  name: string
): any | undefined {
  if (!errors) return;
  const split = name.split(".");
  if (split.length === 1) {
    return errors[name];
  } else {
    return errorObject(errors[split[0]], split.slice(1).join("."));
  }
}

// Required where the form has an object in it that we return *null* values for
// A controlled form value can't be null/undefined. It should be the empty string.
export function nullsToEmptyStrings(obj: any | undefined): any {
  if (!obj) {
    return;
  }
  const returned: any = {};
  for (const [k, v] of Object.entries(obj)) {
    returned[k] = v ?? "";
  }
  return returned;
}
