import { useQuery } from "react-query";
import { get } from "../../lib/amplify";
import { RelevantRoutesResponse } from "../../shared/api_schema";

export function relevantRoutesKey(date: string) {
  return ["relevant_routes", date];
}

export const useRelevantRoutes = (date: string) => {
  return useQuery(relevantRoutesKey(date), async () =>
    get<RelevantRoutesResponse>(`/relevant_routes?date=${date}`)
  );
};
