import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  SelectProps,
} from "@mui/material";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  UseControllerProps,
} from "react-hook-form";

export function FormSelect<T extends FieldValues, N extends Path<T>>(
  props: {
    name: N;
    label: string;
    control: Control<T>;
    errors: FieldErrors<T>;
    rules?: UseControllerProps<T, N>["rules"];
  } & SelectProps
) {
  return (
    <Controller
      control={props.control}
      name={props.name}
      rules={props.rules}
      render={({ field }) => (
        <FormControl
          error={!!props.errors[props.name]}
          className={props.className}
          variant="outlined"
          fullWidth
        >
          <InputLabel>{props.label}</InputLabel>
          <Select
            label={props.label}
            value={field.value}
            inputRef={field.ref}
            onChange={(e, child) => {
              field.onChange(e);
              props.onChange?.(e, child);
            }}
            onBlur={field.onBlur}
            disabled={props.disabled}
          >
            {props.children}
          </Select>
          <FormHelperText>
            {props.errors[props.name]?.message as string}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
}
