import { Grid, MenuItem } from "@mui/material";
import { DateTime } from "luxon";
import type { FC } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { RButton } from "../../components/RButton";
import { RDialog } from "../../components/RDialog";
import { RFormButtons } from "../../components/RFormButtons";
import { FormDurationPicker } from "../../components/forms/FormDurationPicker";
import { FormSelect } from "../../components/forms/FormSelect";
import { FormTimePicker } from "../../components/forms/FormTimePicker";
import { FormZonePicker } from "../../components/forms/FormZonePicker";
import { del, post } from "../../lib/amplify";
import type {
  Clock24hrTime,
  CreateScheduledAppointmentRequest,
  Customer,
  ScheduledAppointment,
} from "../../shared/api_schema";
import { dayOfWeekFullName } from "../../shared/frontend";

type ScheduledAppointmentFormTypes = {
  zoneId: string;
  weekday: number;
  open: Clock24hrTime;
  close: Clock24hrTime;
  service: number;
};

export const ScheduledAppointmentDialog: FC<{
  customer: Customer;
  onClose: (removedId?: string) => void;
  appointment?: ScheduledAppointment;
}> = ({ appointment, customer, onClose }) => {
  const queryClient = useQueryClient();

  const createScheduledAppointment = useMutation(
    async (payload: CreateScheduledAppointmentRequest) =>
      post("/scheduled_appointments", payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("customer_scheduled_appointments");
        onClose();
      },
    }
  );

  const deleteScheduledAppointmentMutation = useMutation(
    async (appointmentId: string) =>
      del(`/scheduled_appointments/${appointmentId}`),
    {
      onSuccess: (_, appointmentId) => {
        queryClient.invalidateQueries("customer_scheduled_appointments");
        onClose(appointmentId);
      },
    }
  );

  const userLocalCurrentWeekday = () =>
    DateTime.now().setZone(customer.location.timeZone).weekday;

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<ScheduledAppointmentFormTypes>({
    defaultValues: {
      zoneId: appointment?.zone.id ?? "",
      weekday: appointment?.dayOfWeek ?? userLocalCurrentWeekday(),
      open: appointment?.open ?? "09:00",
      close: appointment?.close ?? "17:00",
      service: appointment?.service ?? 0,
    },
  });

  const weekdayNames = [1, 2, 3, 4, 5, 6, 7].map((v) => dayOfWeekFullName(v));

  function formSubmit(data: ScheduledAppointmentFormTypes) {
    createScheduledAppointment.mutate({
      customer,
      zone: { id: data.zoneId },
      dayOfWeek: data.weekday,
      open: data.open,
      close: data.close,
      service: data.service,
    });
  }

  return (
    <RDialog
      open={true}
      title={`${appointment ? "Edit" : "Add"} Scheduled Appointment`}
      closeCallback={() => onClose()}
    >
      <form noValidate autoComplete="off" onSubmit={handleSubmit(formSubmit)}>
        <Grid container spacing={4}>
          <Grid item md={6}>
            <FormZonePicker
              name="zoneId"
              setValue={setValue}
              control={control}
              errors={errors}
              disabled={!!appointment}
            />
          </Grid>
          <Grid item md={6}>
            <FormSelect
              disabled={!!appointment}
              control={control}
              errors={errors}
              label="Weekday"
              name="weekday"
              rules={{ required: "Weekday is required" }}
            >
              {weekdayNames.map((name, idx) => {
                return (
                  <MenuItem key={idx + 1} value={idx + 1}>
                    {name}
                  </MenuItem>
                );
              })}
            </FormSelect>
          </Grid>
          <Grid item md={4}>
            <FormTimePicker
              disabled={!!appointment}
              name="open"
              label="Earliest Arrival"
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item md={4}>
            <FormTimePicker
              disabled={!!appointment}
              name="close"
              label="Latest Arrival"
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item md={4}>
            <FormDurationPicker
              control={control}
              name="service"
              rules={{ required: "A duration is required" }}
              disabled={!!appointment}
              errors={errors}
            />
          </Grid>
        </Grid>
        <RFormButtons>
          {appointment && (
            <RButton
              color="secondary"
              onClick={async () =>
                deleteScheduledAppointmentMutation.mutateAsync(appointment.id)
              }
              loading={deleteScheduledAppointmentMutation.isLoading}
            >
              Delete
            </RButton>
          )}
          {!appointment && (
            <RButton
              loading={createScheduledAppointment.isLoading}
              type="submit"
            >
              Save
            </RButton>
          )}
        </RFormButtons>
      </form>
    </RDialog>
  );
};
