import type { TextFieldProps } from "@mui/material";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import type { FC } from "react";
import { useState } from "react";

export const DurationPicker: FC<
  {
    name: string;
    value: number | null;
    onChange: (...event: any[]) => void;
  } & TextFieldProps
> = (props) => {
  const [durationUnits, setDurationUnits] = useState<"minutes" | "seconds">(
    "minutes"
  );

  function toSeconds(duration: number): number {
    return durationUnits === "seconds" ? duration : duration * 60;
  }

  function fromSeconds(): string {
    if (props.value === null) return "";

    return (
      durationUnits === "seconds" ? props.value : Math.floor(props.value / 60)
    ).toString();
  }

  return (
    <Box display="flex">
      <TextField
        disabled={props.disabled}
        name={props.name}
        label={props.label ?? "Duration"}
        type="number"
        value={fromSeconds()}
        onChange={(e) =>
          props.onChange({
            target: {
              value: e.target.value
                ? toSeconds(parseInt(e.target.value))
                : null,
            },
          })
        }
        error={props.error}
        helperText={props.helperText}
      />
      <RadioGroup
        value={durationUnits}
        onChange={(e) =>
          setDurationUnits(e.target.value === "minutes" ? "minutes" : "seconds")
        }
      >
        <FormControlLabel
          value="minutes"
          label="minutes"
          control={<Radio style={{ marginLeft: 0, padding: "0 8px" }} />}
          style={{ marginLeft: 0 }}
          disabled={props.disabled}
        />
        <FormControlLabel
          value="seconds"
          label="seconds"
          control={
            <Radio style={{ marginLeft: 0, padding: "8px 8px 0px 8px" }} />
          }
          style={{ marginLeft: 0 }}
          disabled={props.disabled}
        />
      </RadioGroup>
    </Box>
  );
};
