import { useEffect } from "react";
import type { WebsocketPrivateResponse } from "../../../api_schema";
import { useWebsocketsZ } from "./useWebsocketsZ";

export function useWebsocketPrivateSubscribeZ(
  callback: (message: WebsocketPrivateResponse) => void,
  name: string
) {
  const privateSubscribe = useWebsocketsZ((state) => state.privateSubscribe);

  useEffect(() => {
    if (!privateSubscribe) {
      return;
    }

    return privateSubscribe(callback, name);
  }, [callback, name, privateSubscribe]);
}
