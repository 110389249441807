import { Box, CircularProgress, Typography } from "@mui/material";
import type { FC } from "react";
import { Colors } from "../shared/frontend";

export const LoadingScreen: FC<{
  message?: string;
}> = (props) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      style={{ height: "100%" }}
      gap={4}
    >
      <CircularProgress />
      {props.message && (
        <Typography color={Colors.GREY_SLATE}>{props.message}</Typography>
      )}
    </Box>
  );
};
