import { Box, Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { FC, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { RButton } from "../../components/RButton";
import { post } from "../../lib/amplify";
import type {
  DeepRoute,
  DispatchRouteRequest,
  DispatchRouteResponse,
} from "../../shared/api_schema";
import { DriverPicker } from "./DriverPicker";

export const RedispatchRouteDialog: FC<{
  open: boolean;
  onClose: () => void;
  route: DeepRoute;
}> = ({ open, onClose, route }) => {
  const queryClient = useQueryClient();
  const [selectedDriverId, setSelectedDriverId] = useState(route.driver!.id);

  const redispatchMutation = useMutation(
    async (driverId: string) =>
      post<DispatchRouteResponse, DispatchRouteRequest>(
        `/routes/${route.id}/dispatch`,
        {
          driver: { id: driverId },
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["route", route.id]);
        onClose();
      },
    }
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Redispatch Route</DialogTitle>
      <Box m={4}>
        <DriverPicker
          onChange={setSelectedDriverId}
          initialDriverId={route.driver!.id}
        />
      </Box>
      <Box m={2} mt={0}>
        <DialogActions>
          <Button autoFocus onClick={onClose}>
            Cancel
          </Button>
          <RButton
            onClick={() => redispatchMutation.mutate(selectedDriverId)}
            color="secondary"
            loading={redispatchMutation.isLoading}
          >
            OK
          </RButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
