// Very naive pluralization function
// e.g. pluralize("stop", 2) => "stops"
export function pluralize(singleWord: string, count: number) {
  if (count === 1) {
    return singleWord;
  } else {
    // Irregular
    if (singleWord === "was") {
      return "were";
    }

    return `${singleWord}s`;
  }
}
