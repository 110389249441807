import { number, object, ObjectSchema, string } from "yup";
import { idSchema } from "../id_only";
import type { ScheduledAppointment } from "./scheduled_appointment";

export type CreateScheduledAppointmentRequest = Omit<
  ScheduledAppointment,
  "id"
>;

export const createScheduledAppointmentSchema: ObjectSchema<CreateScheduledAppointmentRequest> =
  object({
    customer: idSchema.required(),
    zone: idSchema.required(),
    dayOfWeek: number().required(),
    open: string().required(),
    close: string().required(),
    service: number().required(),
  });
