import { Typography } from "@mui/material";
import axios from "axios";
import type { FC } from "react";
import { Page } from "./Page";

export const isPaymentRequiredError = (error: unknown) =>
  axios.isAxiosError(error) && error.response?.status === 402;

export const PaymentRequired: FC = () => {
  return (
    <Page title="Payment Required">
      <Typography variant="h2">
        Your Routes.io subscription is not active. Please contact support.
      </Typography>
    </Page>
  );
};
