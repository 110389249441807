import { FormControlProps, MenuItem } from "@mui/material";
import { useEffect } from "react";
import {
  Control,
  FieldErrors,
  FieldValues,
  Path,
  UseFormSetValue,
} from "react-hook-form";
import useZones from "../../hooks/queries/useZones";
import { LoadingScreen } from "../LoadingScreen";
import { FormSelect } from "./FormSelect";

export function FormZonePicker<T extends FieldValues, N extends Path<T>>({
  name,
  setValue,
  control,
  errors,
  disabled,
}: {
  name: N;
  setValue: UseFormSetValue<T>;
  control: Control<T>;
  errors: FieldErrors<T>;
} & FormControlProps) {
  const zonesQuery = useZones();

  // When we get a list of zones, auto-pick if there's only 1
  useEffect(() => {
    const zones = zonesQuery.data?.zones;
    if (zones?.length === 1) {
      setValue(name, zones[0].id as any);
    }
  }, [zonesQuery.data, name, setValue]);

  if (zonesQuery.isLoading) {
    return <LoadingScreen />;
  }

  return (
    <FormSelect
      control={control}
      errors={errors}
      rules={{ required: "A zone is required" }}
      name={name}
      label="Zone"
      disabled={disabled}
    >
      {zonesQuery.data!.zones.map((zone) => (
        <MenuItem key={zone.id} value={zone.id}>
          {zone.name}
        </MenuItem>
      ))}
    </FormSelect>
  );
}
