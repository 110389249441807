import { useLocation } from "react-router-dom";

export function useQueryString() {
  const qs = useFullQueryString();
  return new URLSearchParams(qs);
}

export function useFullQueryString() {
  return useLocation().search;
}
