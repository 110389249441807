import Grid from "@mui/material/Grid";
import type { FC } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { FormTextField } from "../../components/forms/FormTextField";
import { FormTimePicker } from "../../components/forms/FormTimePicker";
import { FormTimezonePicker } from "../../components/forms/FormTimezonePicker";
import { RButton } from "../../components/RButton";
import { RFormButtons } from "../../components/RFormButtons";
import { useAuth } from "../../hooks/useAuth";
import { useSnackbar } from "../../hooks/useSnackbar";
import { del, post, put } from "../../lib/amplify";
import type {
  Clock24hrTime,
  CreateZoneRequest,
  CreateZoneResponse,
  IdOnly,
  UpdateZoneRequest,
  UpdateZoneResponse,
  Zone,
} from "../../shared/api_schema";

// These should match what the UI form component uses for its state
type ZoneFormTypes = {
  name: string;
  timeZone: string;
  open: Clock24hrTime;
};

export const ZoneForm: FC<{
  zone?: Zone;
  onSubmitSuccess: (zone: Zone | undefined) => void; // Return is undefined if we performed a deletion
}> = ({ zone, onSubmitSuccess }) => {
  const { currentUser } = useAuth();
  const tzDetails = currentUser!.timeZoneDetails;

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<ZoneFormTypes>({
    defaultValues: {
      name: zone?.name ?? tzDetails.mainCities[0],
      open: zone?.open ?? "00:00",
      timeZone: zone?.timeZone ?? tzDetails.name,
    },
  });
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();

  const addZoneMutation = useMutation(
    async (payload: CreateZoneRequest) =>
      post<CreateZoneResponse>("/zones", payload),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries("zones");
        snackbar.show("Zone added!");
        onSubmitSuccess?.(response.zone);
      },
    }
  );

  const updateZoneMutation = useMutation(
    async (zone: UpdateZoneRequest & IdOnly) =>
      put<UpdateZoneResponse>(`/zones/${zone.id}`, zone),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries("zones");
        snackbar.show("Zone updated!");
        onSubmitSuccess?.(response.zone);
      },
    }
  );

  const deleteZoneMutation = useMutation(
    async (zoneId: string) => del(`/zones/${zoneId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("zones");
        snackbar.show("Zone deleted!");
        onSubmitSuccess?.(undefined);
      },
    }
  );

  function formSubmit(data: ZoneFormTypes) {
    if (zone) {
      updateZoneMutation.mutate({
        ...data,
        id: zone.id,
      });
    } else {
      addZoneMutation.mutate(data);
    }
  }

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(formSubmit)}
      data-id={zone?.id}
    >
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <FormTextField
            control={control}
            errors={errors}
            name="name"
            label="Name"
            rules={{ required: "A name is required" }}
            autoFocus
          />
        </Grid>
        <Grid item xs={6}>
          <FormTimePicker
            control={control}
            name="open"
            label="Open Time"
            errors={errors}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTimezonePicker
            control={control}
            errors={errors}
            rules={{ required: "A time zone is required" }}
            name="timeZone"
          />
        </Grid>
        <Grid item xs={12}>
          <RFormButtons>
            {zone && (
              <RButton
                color="secondary"
                onClick={async () => deleteZoneMutation.mutateAsync(zone.id)}
                loading={deleteZoneMutation.isLoading}
              >
                Delete
              </RButton>
            )}
            <RButton
              loading={
                addZoneMutation.isLoading || updateZoneMutation.isLoading
              }
              type="submit"
            >
              Save
            </RButton>
          </RFormButtons>
        </Grid>
      </Grid>
    </form>
  );
};
