import polyline from "@mapbox/polyline";
import { Box } from "@mui/material";
import type { LatLngExpression } from "leaflet";
import type { FC } from "react";
import { useState } from "react";
import { MapContainer, Polyline } from "react-leaflet";
import { useQuery } from "react-query";
import { BoundsFitter } from "../../components/BoundsFitter";
import { MapTiles } from "../../components/map/MapTiles";
import { get } from "../../lib/amplify";
import type {
  DeepRoute,
  GetRouteDetailsResponse,
} from "../../shared/api_schema";
import { Colors } from "../../shared/frontend";
import { MAX_ZOOM } from "../dashboard/OverviewMap";
import { RouteAppointments } from "./RouteAppointments";
import { RouteStopList } from "./RouteStopList";

// Pre-dispatch (no vehicle/position/history etc.)
export const RouteDisplay: FC<{
  route: DeepRoute;
}> = ({ route }) => {
  const [plan, setPlan] = useState<LatLngExpression[]>();

  useQuery(
    ["route_details", route.id],
    async () => get<GetRouteDetailsResponse>(`/routes/${route.id}/details`),
    {
      onSuccess: (response) => {
        setPlan(polyline.decode(response.plan ?? "", 5));
      },
    }
  );

  return (
    <Box display="flex" overflow="auto" flex={1}>
      {route && <RouteStopList route={route} />}
      <MapContainer maxZoom={MAX_ZOOM} style={{ flex: 1 }}>
        <MapTiles />
        <BoundsFitter
          coords={[
            route.start.coord,
            ...route.stops.map((s) => s.location.coord),
            route.finish.coord,
          ]}
        />
        {plan && <Polyline color={Colors.BLUE} positions={plan} />}
        <RouteAppointments route={route} />
      </MapContainer>
    </Box>
  );
};
