import { Box } from "@mui/material";
import type { FC, ReactNode } from "react";
import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { relevantRoutesKey } from "../hooks/queries/useRelevantRoutes";
import { zonesRequiringRouteKey } from "../hooks/queries/useZonesRequiringRoute";
import { useDateContext } from "../hooks/useDateContext";
import { useInvitationAuthenticatedRedirect } from "../hooks/useInvitation";
import { useSnackbar } from "../hooks/useSnackbar";
import { WebsocketPrivateResponse } from "../shared/api_schema";
import { useWebsocketPrivateSubscribeZ } from "../shared/frontend";
import { Sidebar } from "./Sidebar";

export const Layout: FC<{ children: ReactNode }> = ({ children }) => {
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();

  // Redirects user to a prompt to accept/reject an invite from organization
  useInvitationAuthenticatedRedirect();

  const privateSubscriptionCallback = useCallback(
    (message: WebsocketPrivateResponse) => {
      switch (message.action) {
        case "route_created":
          snackbar.show("A new route has been created");
          queryClient.invalidateQueries(
            zonesRequiringRouteKey(useDateContext.getState().dateContext)
          );
          queryClient.invalidateQueries(
            relevantRoutesKey(useDateContext.getState().dateContext)
          );
          break;
        case "route_stop_added":
          snackbar.show("A stop was added to a route");
          break;
        case "route_stop_removed":
          snackbar.show("A stop was removed from a route");
          break;
        case "user_message_created":
          snackbar.show(
            `A new message has come in from ${message.message.sender.firstName}`
          );
          queryClient.invalidateQueries("messages");
          break;
      }
    },
    [snackbar, queryClient]
  );

  useWebsocketPrivateSubscribeZ(privateSubscriptionCallback, "Layout");

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
      }}
    >
      <Sidebar />
      <Box
        sx={{
          flexGrow: 1,
          "@media print": {
            flexBasis: "auto",
            width: "7.5in",
          },
          overflowY: "auto",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
