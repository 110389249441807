import polyline from "@mapbox/polyline";
import Navigation from "@mui/icons-material/Navigation";
import { Box } from "@mui/material";
import type { LatLngTuple } from "leaflet";
import { divIcon } from "leaflet";
import { FC, useCallback, useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import { GeoJSON, MapContainer, Marker, Polyline } from "react-leaflet";
import { useQuery } from "react-query";
import { useAuth } from "../hooks/useAuth";
import { useRegionOfInterest } from "../hooks/useRegionOfInterest";
import { get } from "../lib/amplify";
import { routeHasBegun, routeHasEnded } from "../lib/map_helpers";
import { MAX_ZOOM } from "../pages/dashboard/OverviewMap";
import { RouteAppointments } from "../pages/routes/RouteAppointments";
import type {
  DeepRoute,
  GetRouteDetailsResponse,
  VehiclePosition,
  WebsocketDebugResponse,
  WebsocketPrivateResponse,
  WebsocketSubscriptionResponse,
} from "../shared/api_schema";
import {
  Colors,
  useWebsocketPrivateSubscribeZ,
  useWebsocketSubscribeZ,
} from "../shared/frontend";
import { RegionOfInterestFitter } from "./RegionOfInterestFitter";
import { MapTiles } from "./map/MapTiles";
//import GeoJSON as GeoJSONTypes from "geojson";

const NavMarker: FC<{ position: VehiclePosition }> = ({ position }) => {
  const icon = divIcon({
    className: undefined,
    html: renderToString(
      <Navigation
        style={{
          transform: `rotate(${position.brg}deg)`,
          color: Colors.DARK_GREEN,
        }}
      />
    ),
    iconSize: [24, 24],
  });

  return (
    <Marker
      icon={icon}
      position={[parseFloat(position.lat), parseFloat(position.lon)]}
    />
  );
};

export const DispatchedRouteMap: FC<{
  route: DeepRoute;
}> = ({ route }) => {
  const { currentUser } = useAuth();

  const [plan, setPlan] = useState<LatLngTuple[]>();
  const [history, setHistory] = useState<LatLngTuple[]>(); // "Official" history as sent by the mobile client when arriving at stops
  const [recentHistory, setRecentHistory] = useState<LatLngTuple[]>(); // "Accumulated" history -- just stores the history of websocket positions since mounting the map
  const [debugMessage, setDebugMessage] = useState<WebsocketDebugResponse>();
  const [position, setPosition] = useState<VehiclePosition>();

  const { refetch: refetchDetails } = useQuery(
    ["route_details", route.id],
    async () => get<GetRouteDetailsResponse>(`/routes/${route.id}/details`),
    {
      // Save the plan and history returned to us from the details
      onSuccess: (response) => {
        setPlan(polyline.decode(response.plan ?? "", 5));
        setHistory(polyline.decode(response.history ?? "", 5));
      },
    }
  );

  // Refetch the route details when stops are added or removed (we'll have a new plan)
  const privateSubscriptionCallback = useCallback(
    (message: WebsocketPrivateResponse) => {
      switch (message.action) {
        case "route_stop_added":
        case "route_stop_removed":
          refetchDetails();
          break;
      }
    },
    [refetchDetails]
  );

  useWebsocketPrivateSubscribeZ(
    privateSubscriptionCallback,
    "DispatchedRouteMap"
  );

  // Subscribe to route events
  const subscriptionCallback = useCallback(
    (message: WebsocketSubscriptionResponse) => {
      switch (message.action) {
        case "position":
          setPosition(message);
          setRecentHistory((prevRecentHistory) => [
            ...(prevRecentHistory ?? []),
            [parseFloat(message.lat), parseFloat(message.lon)],
          ]);
          break;
        case "debug":
          setDebugMessage(message);
          break;
        case "route_details":
          refetchDetails();
      }
    },
    [refetchDetails]
  );

  useWebsocketSubscribeZ(
    "route",
    currentUser?.activeOrganization.liveTracking ? route.id : undefined,
    subscriptionCallback,
    "DispatchedRouteMap"
  );

  function debugGeometry() {
    if (!debugMessage) {
      return null;
    }

    // https://github.com/PaulLeCam/react-leaflet/issues/332
    return debugMessage.debug.features.map((f) => (
      <GeoJSON
        key={`${debugMessage.ts}-${f.properties?.name}`}
        data={f}
        style={{ color: f.properties?.stroke }}
      />
    ));
  }

  // Set region of interest for map bounds and Google Places autocomplete
  useEffect(
    () =>
      useRegionOfInterest.setState({
        coords: [
          route.start.coord,
          ...route.stops.map((s) => s.location.coord),
          route.finish.coord,
        ],
      }),
    [route]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        "@media print": {
          minWidth: "7.5in",
          minHeight: "10in",
          pageBreakBefore: "always",
        },
        zIndex: 0,
      }}
    >
      <MapContainer style={{ flex: 1 }} maxZoom={MAX_ZOOM}>
        <RegionOfInterestFitter />
        <MapTiles />
        <RouteAppointments route={route} />
        {debugGeometry()}
        {plan && <Polyline color={Colors.BLUE} positions={plan} />}
        {history && <Polyline color={Colors.DARK_GREEN} positions={history} />}
        {recentHistory && (
          <Polyline color={Colors.DARK_GREEN} positions={recentHistory} />
        )}
        {routeHasBegun(route) && !routeHasEnded(route) && position && (
          <NavMarker position={position} />
        )}
      </MapContainer>
    </Box>
  );
};
