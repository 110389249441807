import type { FC } from "react";
import { useEffect, useState } from "react";
import type { DistancePair } from "../shared/api_schema";
import { Colors, toDistance } from "../shared/frontend";

export const DistanceSwap: FC<{
  pair: DistancePair;
}> = ({ pair }) => {
  const [showActual, setShowActual] = useState<boolean>();
  useEffect(() => setShowActual(pair.actual != null), [pair]);
  return (
    <div
      style={{
        display: "inline-grid",
      }}
      onMouseOver={() => setShowActual(false)}
      onMouseOut={() => setShowActual(pair.actual != null)}
    >
      {pair.expected != null && (
        <pre
          style={{
            gridRow: 1,
            gridColumn: 1,
            visibility: showActual ? "hidden" : "visible",
          }}
        >
          {toDistance(pair.expected)}
        </pre>
      )}
      {pair.actual != null && (
        <pre
          style={{
            gridRow: 1,
            gridColumn: 1,
            visibility: showActual ? "visible" : "hidden",
            color:
              pair.actual! <= pair.expected! ? Colors.DARK_GREEN : Colors.ERROR,
          }}
        >
          {toDistance(pair.actual)}
        </pre>
      )}
    </div>
  );
};
