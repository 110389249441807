import { useQuery } from "react-query";
import { get } from "../../lib/amplify";
import type { ListIntegrationsResponse } from "../../shared/api_schema";

export const listIntegrationsKey = "integrations";

export function useListIntegrations() {
  return useQuery(listIntegrationsKey, async () =>
    get<ListIntegrationsResponse>("/integrations")
  );
}
