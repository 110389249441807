import type { SvgIconComponent } from "@mui/icons-material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Assessment from "@mui/icons-material/Assessment";
import BorderAll from "@mui/icons-material/BorderAll";
import Business from "@mui/icons-material/Business";
import Directions from "@mui/icons-material/Directions";
import FeedbackRounded from "@mui/icons-material/FeedbackRounded";
import Group from "@mui/icons-material/Group";
import ListAlt from "@mui/icons-material/ListAlt";
import LocalShipping from "@mui/icons-material/LocalShipping";
import PersonPinRounded from "@mui/icons-material/PersonPinRounded";
import PlaceIcon from "@mui/icons-material/Place";
import Send from "@mui/icons-material/Send";
import Today from "@mui/icons-material/Today";
import {
  Badge,
  BadgeProps,
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import type { FC } from "react";
import { NavLink, useLocation, useResolvedPath } from "react-router-dom";
import { gitTags } from "../config";
import { useAuth } from "../hooks/useAuth";
import { useMessageHelpers } from "../hooks/useMessageHelpers";
import { ProductTier, UserRole } from "../shared/api_schema";
import { ConnectionState, useWebsocketsZ } from "../shared/frontend";
import { Logo } from "./Logo";
import Version from "./Version";

export const Sidebar: FC = () => {
  const { currentUser } = useAuth();
  const { unreadMessageCount } = useMessageHelpers();
  const connectionState = useWebsocketsZ((state) => state.connectionState);

  function websocketBadgeColor(): BadgeProps["color"] {
    switch (connectionState) {
      case ConnectionState.CLOSED: // Shouldn't occur if you're seeing the sidebar
        return "error";
      case ConnectionState.CONNECTING:
        return "info"; // Started a request to connect
      case ConnectionState.WAITING: // Waiting to reconnect
        return "secondary";
      case ConnectionState.CONNECTED:
        return "success"; // Successfully connected
    }
  }

  return (
    <Box
      sx={{
        borderRight: "1px solid #999",
        background: "white",
        "@media print": {
          display: "none",
        },
        minWidth: 205,
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          width: "80%",
          margin: "20px auto 10px",
        }}
      >
        <Logo />
      </Box>
      <Box textAlign="center" padding={2} fontSize="small">
        <Badge color={websocketBadgeColor()} variant="dot">
          <Typography paddingX={1}>{currentUser!.email}</Typography>
        </Badge>
        <br />
        <span style={{ fontSize: 10 }}>
          {currentUser!.activeOrganization.name}
        </span>
      </Box>
      <Box>
        <Box>
          <List component="nav">
            <SidebarIcon name="Dashboard" path="/dashboard" icon={Today} />
            <SidebarIcon
              name="Appointments"
              path="/appointments"
              icon={ListAlt}
            />
            <SidebarIcon name="Stops" path="/stops" icon={PlaceIcon} />
            <SidebarIcon name="Routes" path="/routes" icon={Directions} />
            <SidebarIcon name="Shipments" path="/shipments" icon={Send} />
            <SidebarIcon
              name="Customers"
              path="/customers"
              icon={PersonPinRounded}
            />
            <SidebarIcon
              name="Vehicles"
              path="/vehicles"
              icon={LocalShipping}
            />
            <SidebarIcon name="Zones" path="/zones" icon={BorderAll} />
            {currentUser?.activeOrganization.productTier !==
              ProductTier.PERSONAL && (
              <SidebarIcon name="Users" path="/users" icon={Group} />
            )}
            {currentUser?.activeOrganization.role === UserRole.ADMIN && (
              <SidebarIcon
                name="Organization"
                path="/organization"
                icon={Business}
              />
            )}
            <SidebarIcon name="Reports" path="/reports" icon={Assessment} />
            <SidebarIcon
              name="Support"
              path="/support"
              icon={FeedbackRounded}
              badgeCount={unreadMessageCount}
            />
            <SidebarIcon name="Profile" path="/profile" icon={AccountCircle} />
          </List>
        </Box>
        {gitTags() && <Version />}
      </Box>
    </Box>
  );
};

const SidebarIcon: FC<{
  name: string;
  path: string;
  icon: SvgIconComponent;
  badgeCount?: number;
}> = (props) => {
  const IconComponent = props.icon;

  // Pulled from React Router's NavLink
  // They renamed activeClassName prop to just className, which interferes with Material UI's className prop
  // That means we can't properly determine the active state anymore unless we do it ourselves
  // https://github.com/remix-run/react-router/blob/65cac43bb8e25427b709def72e43b97129aa6726/packages/react-router-dom/index.tsx#L256
  const location = useLocation();
  const path = useResolvedPath(props.path);
  const locationPathname = location.pathname.toLowerCase();
  const toPathname = path.pathname.toLowerCase();
  const isActive = locationPathname === toPathname;

  return (
    <ListItemButton component={NavLink} to={path} selected={isActive}>
      <ListItemIcon>
        {props.badgeCount ? (
          <Badge
            badgeContent={props.badgeCount}
            color="secondary"
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <IconComponent />
          </Badge>
        ) : (
          <IconComponent />
        )}
      </ListItemIcon>
      <ListItemText primary={props.name} />
    </ListItemButton>
  );
};
