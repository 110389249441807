import AddCircle from "@mui/icons-material/AddCircle";
import { Button } from "@mui/material";
import { FC, useState } from "react";
import type { DeepRoute } from "../shared/api_schema";
import { RDialog } from "./RDialog";
import { RouteStopForm } from "./forms/RouteStopForm";

export const AddStop: FC<{
  route: DeepRoute;
}> = ({ route }) => {
  const [addStopOpen, setAddStopOpen] = useState(false);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setAddStopOpen(true);
        }}
        startIcon={<AddCircle />}
      >
        Add Stop
      </Button>
      <RDialog
        open={addStopOpen}
        title="Add Stop"
        closeCallback={() => setAddStopOpen(false)}
      >
        <RouteStopForm route={route} onSuccess={() => setAddStopOpen(false)} />
      </RDialog>
    </>
  );
};
