import { TextField } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  UseControllerProps,
} from "react-hook-form";

export function FormTimePicker<
  T extends FieldValues,
  N extends Path<T>
>(props: {
  name: N;
  label: string;
  control: Control<T>;
  errors: FieldErrors<T>;
  rules?: UseControllerProps<T, N>["rules"];
  disabled?: boolean;
}) {
  return (
    <Controller
      control={props.control}
      rules={props.rules}
      name={props.name}
      render={({ field }) => (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <TimePicker
            label={props.label}
            ampm={true}
            value={DateTime.fromISO(field.value)} // Value should be Clock24hrTime
            onChange={
              (date) =>
                field.onChange(
                  (date as DateTime)?.toLocaleString(DateTime.TIME_24_SIMPLE)
                ) // Respond back as Clock24hrTime
            }
            renderInput={(textFieldProps) => (
              <TextField
                {...textFieldProps}
                error={!!props.errors[props.name]}
                helperText={props.errors[props.name]?.message as string}
                fullWidth
              />
            )}
            disabled={props.disabled}
          />
        </LocalizationProvider>
      )}
    />
  );
}
