import type { GridColumns, GridFilterModel } from "@mui/x-data-grid";
import { DateTime } from "luxon";
import type { FC } from "react";
import { useState } from "react";
import { Page } from "../../components/Page";
import type { PaginatedCustomModel } from "../../components/PaginatedCustomDataGrid";
import { PaginatedCustomDataGrid } from "../../components/PaginatedCustomDataGrid";
import { RDialog } from "../../components/RDialog";
import { useAuth } from "../../hooks/useAuth";
import { Shipment as ApiShipment, ShipmentType } from "../../shared/api_schema";
import { toLocale } from "../../shared/frontend";
import { Shipment } from "./Shipment";

export function shipmentTypeString(type: ShipmentType) {
  switch (type) {
    case ShipmentType.DELIVERY:
      return "Delivery";
    case ShipmentType.PICKUP:
      return "Pickup";
  }
}

export const Shipments: FC = () => {
  const { currentUser } = useAuth();
  const [codeFilter, setCodeFilter] = useState("");
  const [shipmentId, setShipmentId] = useState<string>("");

  const columns: GridColumns<ApiShipment> = [
    {
      field: "updatedAt",
      flex: 1,
      headerName: "Last Update",
      filterable: false,
      valueFormatter: ({ value }) =>
        toLocale(value as string, currentUser!.timeZone, DateTime.DATETIME_MED),
    },
    {
      field: "code",
      flex: 1,
      headerName: "Code",
      renderCell: (params) => <pre>{params.value}</pre>,
    },
    {
      field: "type",
      flex: 1,
      headerName: "Type",
      filterable: false,
      valueFormatter: ({ value }) => shipmentTypeString(value),
    },
  ];

  function onFilterChange(filterModel: GridFilterModel) {
    if (!filterModel.items.length) {
      setCodeFilter("");
      return;
    }
    // console.debug("Filtering", filterModel);
    setCodeFilter(filterModel.items[0].value ?? "");
  }

  const model: PaginatedCustomModel = {
    key: codeFilter.length ? `shipments_${codeFilter}` : "shipments",
    countPath: codeFilter.length
      ? `/shipments?code=${codeFilter}&count=true`
      : "/shipments?count=true",
    dataPath: (skip, take) =>
      codeFilter.length
        ? `/shipments?code=${codeFilter}&skip=${skip}&take=${take}`
        : `/shipments?skip=${skip}&take=${take}`,
    responseKey: "shipments",
  };

  return (
    <Page>
      <PaginatedCustomDataGrid
        model={model}
        columns={columns}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        onRowClick={(params) => setShipmentId(params.row.id)}
      />
      {!!shipmentId && (
        <RDialog open={true} closeCallback={() => setShipmentId("")}>
          <Shipment
            shipmentId={shipmentId}
            onDeleted={() => setShipmentId("")}
          />
        </RDialog>
      )}
    </Page>
  );
};
