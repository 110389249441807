import { Autocomplete, TextField } from "@mui/material";
import type { FC } from "react";
import { useQuery } from "react-query";
import { get } from "../lib/amplify";
import type { Customer, ListCustomersResponse } from "../shared/api_schema";
import { LoadingScreen } from "./LoadingScreen";

export const CustomerPicker: FC<{
  value: Customer | null;
  onChange: (customer: Customer | null) => void;
  disabled?: boolean;
}> = (props) => {
  const customersQuery = useQuery("customers", async () =>
    get<ListCustomersResponse>("/customers").then((response) => {
      // Sort customers by name before returning the list
      return {
        customers: response.customers.sort((a, b) =>
          a.location.name.localeCompare(b.location.name)
        ),
      };
    })
  );

  if (customersQuery.isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Autocomplete
      options={customersQuery.data?.customers ?? []}
      getOptionLabel={(option) => option.location.name}
      renderInput={(autocompleteParams) => (
        <TextField {...autocompleteParams} label="Customer" />
      )}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      value={props.value}
      onChange={(_, v) => props.onChange(v as Customer)}
      disabled={props.disabled}
    />
  );
};
