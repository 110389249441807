import type { FC } from "react";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { useGoogleMaps } from "../../hooks/useGoogleMaps";

export const MapTiles: FC = () => {
  const maps = useGoogleMaps((state) => state.maps);
  if (!maps) {
    return null;
  }
  return <ReactLeafletGoogleLayer type="roadmap" useGoogMapsLoader={false} />;
};
