import type { FC} from "react";
import { useEffect } from "react";
import { useMap } from "react-leaflet";
import { fitBounds } from "../lib/map_helpers";
import type { Coord } from "../shared/api_schema";

export const BoundsFitter: FC<{ coords: Coord[] }> = ({ coords }) => {
  const map = useMap();
  useEffect(() => fitBounds(coords, map), [coords, map]);
  return null;
};
