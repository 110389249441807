import type { IdOnly } from "../id_only";
import type { Shipment } from "../shipments/shipment";
import type { Stop } from "../stops/stop";

export enum RequirementType {
  SCAN = "scan",
  PHOTO = "photo",
  SIGNATURE = "signature",
  ID = "id",
  DRIVER_CODE = "driver_code",
}

export type RequirementDetail = {
  receivedBy?: string;
};

export type Requirement = {
  id: string;
  type: RequirementType;
  stop: IdOnly | Stop;
  shipment: IdOnly | Shipment;
  completedAt: string | undefined;
  attachmentPath?: string;
  presignedUrl?: string;
  detail: RequirementDetail | null;
};
