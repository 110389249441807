import { Box, Grid, useTheme } from "@mui/material";
import type { FC, ReactNode } from "react";
import { Children } from "react";

export const RFormButtons: FC<{ children: ReactNode }> = ({ children }) => {
  const theme = useTheme();

  return (
    <Box
      mt={9}
      p={5}
      style={{
        backgroundColor: theme.palette.grey[100],
        borderTop: `1px solid ${theme.palette.primary.main}`,
        width: "100%",
      }}
    >
      <Grid container spacing={5} justifyContent="flex-end">
        {Children.map(children, (c) => c && <Grid item>{c}</Grid>)}
      </Grid>
    </Box>
  );
};
