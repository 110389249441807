// We do a mapping here because `local` is unfortunately a reserved word for loading env files
// However, we can keep using it ourselves, we just have to name the .env.development file grossly
export function appStage() {
  if (import.meta.env.MODE === "development") {
    return "local";
  } else {
    return import.meta.env.MODE;
  }
}

export function isLocal() {
  return appStage() === "local";
}

export function isDev() {
  return appStage() === "dev";
}

export function isStaging() {
  return appStage() === "staging";
}

export function isProd() {
  return appStage() === "prod";
}

export function gitTags() {
  return import.meta.env.VITE_GIT_TAGS;
}

type Config = {
  api: { NAME: string; URL: string };
  cognito: {
    USER_POOL_ID: string;
    IDENTITY_POOL_ID: string;
    APP_CLIENT_ID: string;
  };
  google: { API_KEY: string; MAPS_VERSION: string };
  websocket: { URL: string };
  s3: {
    BUCKET: string;
    REGION: string;
  };
};

const config: Config = {
  api: {
    NAME: import.meta.env.VITE_API_NAME!,
    URL: import.meta.env.VITE_API_URL!,
  },
  cognito: {
    USER_POOL_ID: import.meta.env.VITE_COGNITO_USER_POOL_ID!,
    IDENTITY_POOL_ID: import.meta.env.VITE_COGNITO_IDENTITY_POOL_ID!,
    APP_CLIENT_ID: import.meta.env.VITE_COGNITO_APP_CLIENT_ID!,
  },
  google: {
    API_KEY: import.meta.env.VITE_GOOGLE_API_KEY!,
    MAPS_VERSION: import.meta.env.VITE_GOOGLE_MAPS_VERSION!,
  },
  websocket: { URL: import.meta.env.VITE_WEBSOCKET_URL! },
  s3: {
    BUCKET: import.meta.env.VITE_S3_BUCKET!,
    REGION: import.meta.env.VITE_S3_REGION!,
  },
};

export default config;
