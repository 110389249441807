import { useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { get } from "../lib/amplify";
import type { InviteResponse } from "../shared/api_schema";
import { useAuth } from "./useAuth";
import { useFullQueryString, useQueryString } from "./useQueryString";

export function useInvitation() {
  const INVITATION_KEY = "pendingInvitation";
  const inviteIdQueryString = useQueryString().get("invite");
  const navigate = useNavigate();

  const invitationQuery = useQuery<InviteResponse>(
    `invite-${inviteIdQueryString}`,
    async () => {
      return get(`/users/invite/${inviteIdQueryString}`);
    },
    {
      enabled: !!inviteIdQueryString,
      retry: 1,
    }
  );

  const setPendingInvite = (value: string) =>
    localStorage.setItem(INVITATION_KEY, value);

  const getPendingInvite = () => localStorage.getItem(INVITATION_KEY);
  const clearPendingInvite = () => localStorage.removeItem(INVITATION_KEY);

  // Watch for invite ID in query string and set it in local storage
  useEffect(() => {
    inviteIdQueryString && setPendingInvite(inviteIdQueryString);
  }, [inviteIdQueryString]);

  function redirectToPendingInvite() {
    const invite = getPendingInvite();
    invite && navigate(`/users/invite/${invite}`);
  }

  return {
    inviteIdQueryString,
    getPendingInvite,
    invitationQuery,
    clearPendingInvite,
    redirectToPendingInvite,
  };
}

// Use on authenticated pages to redirect the user to
// their pending invite, if they have one
export function useInvitationAuthenticatedRedirect() {
  const navigate = useNavigate();
  const { getPendingInvite } = useInvitation();
  const pendingInviteId = getPendingInvite();
  const { currentUser } = useAuth();

  useEffect(() => {
    if (pendingInviteId && currentUser) {
      navigate(`/users/invite/${pendingInviteId}`);
    }
  }, [navigate, pendingInviteId, currentUser]);
}

// Use on unauthenticated pages to guide the user to sign up
// with their invite, if required
export function useInvitationSignUpRedirect() {
  const navigate = useNavigate();
  const fullQueryString = useFullQueryString();
  const { invitationQuery } = useInvitation();
  const invite = invitationQuery.data?.invite;

  useEffect(() => {
    // invite.userExists tells us if the email/user existed when the
    // invitation was sent out
    if (invite && !invite.userExists) {
      navigate(`/sign_up${fullQueryString}`);
    }
  }, [invite, navigate, fullQueryString]);
}
