import type { TextFieldProps } from "@mui/material";
import { TextField } from "@mui/material";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  UseControllerProps,
} from "react-hook-form";
import { errorObject } from "./form_helpers";

export function FormTextField<T extends FieldValues, N extends Path<T>>(
  props: {
    control: Control<T>;
    errors?: FieldErrors<T>;
    name: N;
    label: string;
    rules?: UseControllerProps<T, N>["rules"];
  } & TextFieldProps
) {
  return (
    <Controller
      control={props.control}
      name={props.name}
      rules={props.rules}
      render={({ field }) => (
        <TextField
          className={props.disabled ? "Mui-disabled" : props.className}
          label={props.label}
          type={props.type}
          multiline={props.multiline}
          error={!!errorObject(props.errors, props.name)}
          helperText={errorObject(props.errors, props.name)?.message as string}
          value={field.value}
          inputRef={field.ref}
          onChange={field.onChange}
          onBlur={field.onBlur}
          autoFocus={props.autoFocus}
          InputLabelProps={{
            ...(props.disabled && {
              className: "Mui-disabled",
            }),
          }}
          InputProps={{
            ...props.InputProps,
            ...(props.disabled && {
              readOnly: true,
              className: "Mui-disabled",
            }),
          }}
          fullWidth
          onKeyPress={props.onKeyPress} // Allows us to make Enter key run geocoding if in Address box on Location form
        />
      )}
    />
  );
}
