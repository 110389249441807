import * as Sentry from "@sentry/react";
import { Amplify, Storage } from "aws-amplify";
import { createRoot } from "react-dom/client";
import "reflect-metadata";
import App from "./App";
import config, { appStage, isLocal } from "./config";

import "@changey/react-leaflet-markercluster/dist/styles.min.css";
import "leaflet/dist/leaflet.css";
import "./index.css";

const amplifyConfig = {
  Auth: {
    region: "us-east-1",
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: config.api.NAME,
        endpoint: config.api.URL,
      },
    ],
  },
  Storage: {
    AWSS3: {
      bucket: config.s3.BUCKET,
      region: config.s3.REGION,
    },
  },
};

Amplify.configure(amplifyConfig);

// Stop Amplify from trying to separate into public/private/protected buckets
Storage.configure({
  customPrefix: {
    public: "",
    protected: "",
    private: "",
  },
});

Sentry.init({
  dsn: "https://34de2819d317435da5943f619231ae3e@o777026.ingest.sentry.io/5797642",
  environment: appStage(),
  enabled: !isLocal(),
});

const container = document.getElementById("root")!;
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
