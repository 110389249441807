import type { TextFieldProps } from "@mui/material";
import { Autocomplete, TextField } from "@mui/material";
import type { FC } from "react";
import { friendlyTzName, sortedTimezones } from "../shared/frontend";

export const TimezonePicker: FC<
  {
    value: string;
    onChange: (tz: string) => void;
    onBlur: () => void;
  } & TextFieldProps
> = ({ value, onChange, onBlur, error, helperText, disabled }) => {
  return (
    <Autocomplete
      disableClearable
      value={value!}
      onChange={(_, v) => onChange(v)}
      onBlur={onBlur}
      options={sortedTimezones}
      getOptionLabel={(option) => friendlyTzName(option)}
      renderInput={(autocompleteParams) => (
        <TextField
          {...autocompleteParams}
          label="Time zone"
          error={error}
          helperText={helperText}
        />
      )}
      disabled={disabled}
    />
  );
};
