import RedirectedFromIcon from "@mui/icons-material/AirlineStops";
import RouteEndIcon from "@mui/icons-material/SwipeLeftAlt";
import RouteStartIcon from "@mui/icons-material/SwipeRightAlt";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { Box, Chip, Typography } from "@mui/material";
import type { FC } from "react";
import {
  appointmentMarkerStyle,
  MARKER_SIZE,
} from "../../components/AppointmentMarker";
import { DurationSwap } from "../../components/DurationSwap";
import { LightTooltip } from "../../components/LightTooltip";
import { TimeSwap } from "../../components/TimeSwap";
import { Shipment, Waypoint } from "../../shared/api_schema";
import { Colors, fullName } from "../../shared/frontend";
import { RouteStopLine } from "./RouteStopLine";
import { WaypointShipments } from "./WaypointShipments";

const WaypointTiming: FC<{ waypoint: Waypoint }> = ({ waypoint }) => {
  return (
    <Box marginTop={2} sx={{ "@media print": { display: "none" } }}>
      {waypoint.arrive && (
        <RouteStopLine label="Arrive">
          <TimeSwap pair={waypoint.arrive} tz={waypoint.location.timeZone} />
        </RouteStopLine>
      )}
      {waypoint.type === "stop" && waypoint.wait?.expected ? (
        <RouteStopLine label="Wait">
          <DurationSwap pair={waypoint.wait} />
        </RouteStopLine>
      ) : null}
      {waypoint.type === "stop" && waypoint.service && (
        <RouteStopLine label="Service">
          <DurationSwap pair={waypoint.service} />
        </RouteStopLine>
      )}
      {waypoint.depart && (
        <RouteStopLine label="Depart">
          <TimeSwap pair={waypoint.depart} tz={waypoint.location.timeZone} />
        </RouteStopLine>
      )}
    </Box>
  );
};

export const WaypointNumber: FC<{ waypoint: Waypoint }> = ({ waypoint }) => {
  return (
    <div style={{ width: MARKER_SIZE, height: MARKER_SIZE }}>
      {waypoint.type === "stop" ? (
        <div style={appointmentMarkerStyle(waypoint)}>{waypoint.order}</div>
      ) : waypoint.type === "start" ? (
        <div style={appointmentMarkerStyle(waypoint)}>
          <RouteStartIcon />
        </div>
      ) : (
        <div style={appointmentMarkerStyle(waypoint)}>
          <RouteEndIcon />
        </div>
      )}
    </div>
  );
};

const CommentIndicator = ({ comment }: { comment: string }) => {
  return (
    <LightTooltip title={comment}>
      <TextSnippetIcon />
    </LightTooltip>
  );
};

export const WaypointBlock: FC<{
  waypoint: Waypoint;
  onShowStop: (stopId: string) => void;
  onShowShipment: (shipmentId: string) => void;
}> = ({ waypoint, onShowStop, onShowShipment }) => {
  let shipments: Shipment[] = [];

  if (waypoint.type === "stop") {
    if (waypoint.shipments) {
      shipments = waypoint.shipments as Shipment[];
    }
  }

  return (
    <Box
      left={0}
      top={0}
      marginBottom={3}
      position="relative"
      padding={3}
      boxShadow="0px 0px 5px rgba(0,0,0,.1)"
      sx={{
        backgroundColor: Colors.WHITE,
        cursor: waypoint.type === "stop" ? "pointer" : "initial",
      }}
      onClick={
        waypoint.type === "stop"
          ? () => {
              onShowStop(waypoint.id);
            }
          : undefined
      }
      data-id={waypoint.type === "stop" ? waypoint.id : undefined}
    >
      <Box display="flex">
        <Box display="flex" flexDirection="column" alignItems="center">
          <WaypointNumber waypoint={waypoint} />
          <Box mt={2}>
            {waypoint.type === "stop" && waypoint.comment && (
              <CommentIndicator comment={waypoint.comment} />
            )}
            {waypoint.type === "stop" && waypoint.redirectedFrom?.id && (
              <RedirectedFromIcon />
            )}
          </Box>
        </Box>
        <Box flex={1} paddingLeft={3}>
          <Box display="flex" alignItems="flex-start">
            <Typography flex={1} fontWeight="bold">
              {waypoint.location.name}
            </Typography>
            {waypoint.type === "stop" && waypoint.priority && (
              <Chip
                label={`Priority ${waypoint.priority}`}
                color="secondary"
                size="small"
              />
            )}
          </Box>
          {"contact" in waypoint && fullName(waypoint.contact) !== "" && (
            <Typography fontWeight="bold">
              {fullName(waypoint.contact)}
            </Typography>
          )}
          <Box fontWeight="bold" fontSize="0.8em" color="dimGray">
            {[waypoint.location.address, waypoint.location.locality]
              .filter((v) => v.trim())
              .join(", ")}
          </Box>
          <WaypointTiming waypoint={waypoint} />
          {shipments.length > 0 && (
            <WaypointShipments
              shipments={shipments}
              onShowShipment={onShowShipment}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
