import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  Switch,
} from "@mui/material";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { LoadingScreen } from "../../components/LoadingScreen";
import { RButton } from "../../components/RButton";
import { useAuth } from "../../hooks/useAuth";
import { useQueryString } from "../../hooks/useQueryString";
import { get, put } from "../../lib/amplify";
import {
  ProductTier,
  UpdateOrganizationRequest,
  UpdateOrganizationResponse,
  compareProductTiers,
} from "../../shared/api_schema";
import { DefaultPricesResponse } from "../../shared/api_schema/stripe";
import { Colors } from "../../shared/frontend";

export const PlanSwitcher: FC = () => {
  const queryString = useQueryString();
  const { currentUser } = useAuth();

  const activeOrganization = currentUser!.activeOrganization;

  if (queryString.has("productTier")) {
    activeOrganization.productTier = queryString.get(
      "productTier"
    ) as ProductTier;
  }
  if (queryString.has("liveTracking")) {
    activeOrganization.liveTracking =
      queryString.get("liveTracking") === "true";
  }
  if (queryString.has("knockr")) {
    activeOrganization.knockr = queryString.get("knockr") === "true";
  }

  // Reset selected tier on active org change
  useEffect(() => {
    setSelectedTier(undefined);
  }, [currentUser?.activeOrganization]);

  const [selectedTier, setSelectedTier] = useState<ProductTier>();
  const [liveTracking, setLiveTracking] = useState(
    activeOrganization.liveTracking
  );
  const [knockr, setKnockr] = useState(activeOrganization.knockr);

  const pricesQuery = useQuery("prices", async () =>
    get<DefaultPricesResponse>(`/prices`)
  );

  // Mutation to update the subscription
  const updateOrganization = useMutation(
    async (request: UpdateOrganizationRequest) =>
      put<UpdateOrganizationResponse>(`/organization`, request),
    {
      onSuccess: (data) => window.location.replace(data.redirectUrl),
    }
  );

  const targetTier = selectedTier ?? activeOrganization.productTier;

  // Explicitly setting live tracking/knockr only makes sense at business tier
  // Otherwise, its value is known for Personal/Enterprise (disabled/enabled respectively)
  const targetLiveTracking =
    targetTier === ProductTier.BUSINESS
      ? liveTracking
      : compareProductTiers(targetTier, ProductTier.ENTERPRISE) >= 0;

  const targetKnockr =
    targetTier === ProductTier.BUSINESS
      ? knockr
      : compareProductTiers(targetTier, ProductTier.ENTERPRISE) >= 0;

  const switchingProductTier = targetTier !== activeOrganization.productTier;

  const switchingLiveTracking =
    targetLiveTracking !== activeOrganization.liveTracking;

  const switchingKnockr = targetKnockr !== activeOrganization.knockr;

  const changePlanEnabled =
    switchingProductTier || switchingLiveTracking || switchingKnockr;

  if (pricesQuery.isFetching) {
    return <LoadingScreen />;
  }

  const prices = pricesQuery.data!;

  let weeklyCost = prices.productTier[targetTier].unit_amount;
  if (targetLiveTracking) {
    weeklyCost += prices.liveTracking.unit_amount;
  }
  if (targetKnockr) {
    weeklyCost += prices.knockr.unit_amount;
  }

  return (
    <Box display="flex" flexDirection="column" gap={4} alignItems="flex-start">
      <Box display="flex" gap={4}>
        <PlanCard
          title="Personal"
          active={targetTier === ProductTier.PERSONAL}
          onClick={() => setSelectedTier(ProductTier.PERSONAL)}
        >
          Try Routes for free
        </PlanCard>
        <PlanCard
          title="Business"
          active={targetTier === ProductTier.BUSINESS}
          onClick={() => setSelectedTier(ProductTier.BUSINESS)}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  disabled={targetTier !== ProductTier.BUSINESS}
                  checked={liveTracking}
                  onChange={(_, checked) => setLiveTracking(checked)}
                />
              }
              label="Live Tracking"
            />
            <FormControlLabel
              control={
                <Switch
                  disabled={targetTier !== ProductTier.BUSINESS}
                  checked={knockr}
                  onChange={(_, checked) => setKnockr(checked)}
                />
              }
              label="Knockr"
            />
          </FormGroup>
        </PlanCard>
        {activeOrganization.productTier === ProductTier.ENTERPRISE && (
          <PlanCard
            title="Enterprise"
            active={targetTier === ProductTier.ENTERPRISE}
            onClick={() => setSelectedTier(ProductTier.ENTERPRISE)}
          />
        )}
      </Box>
      {changePlanEnabled && (
        <List>
          <ListItem disableGutters>
            {targetTier} Plan:{" "}
            {`$${prices.productTier[targetTier].unit_amount / 100} per user`}
          </ListItem>
          <ListItem disableGutters>
            Live Tracking {targetLiveTracking ? "Enabled" : "Disabled"}:{" "}
            {targetLiveTracking
              ? `$${prices.liveTracking.unit_amount / 100} per user`
              : "$0 per user"}
          </ListItem>
          <ListItem disableGutters>
            Knockr {targetKnockr ? "Enabled" : "Disabled"}:{" "}
            {targetKnockr
              ? `$${prices.knockr.unit_amount / 100} per user`
              : "$0 per user"}
          </ListItem>
          <ListItem />
          <ListItem disableGutters>
            Weekly Cost: {`$${weeklyCost / 100} per user`}
          </ListItem>
        </List>
      )}
      <Box display="flex" gap={4}>
        <RButton
          disabled={!changePlanEnabled}
          loading={updateOrganization.isLoading}
          onClick={() => {
            updateOrganization.mutateAsync({
              productTier: targetTier,
              liveTracking,
              knockr,
            });
          }}
        >
          Change Plan
        </RButton>
        <RButton
          variant="outlined"
          href={import.meta.env.VITE_STRIPE_CUSTOMER_PORTAL_URL}
        >
          Billing Dashboard
        </RButton>
      </Box>
    </Box>
  );
};

const PlanCard: FC<
  PropsWithChildren & {
    title: string;
    active?: boolean;
    onClick?: () => void;
  }
> = (props) => {
  return (
    <Card
      onClick={props.onClick}
      sx={{
        width: 200,
        borderColor: props.active ? Colors.BRAND_GREEN : undefined,
        cursor: "pointer",
      }}
    >
      <CardHeader title={props.title}></CardHeader>
      <CardContent>
        <Box display="flex" flexDirection="column" gap={4}>
          {props.children}
        </Box>
      </CardContent>
    </Card>
  );
};
