import CheckCircle from "@mui/icons-material/CheckCircle";
import HourglassEmpty from "@mui/icons-material/HourglassEmpty";
import LocalShipping from "@mui/icons-material/LocalShipping";
import type { SvgIconProps } from "@mui/material";
import type { FC } from "react";
import { routeHasBegun, routeHasEnded } from "../../lib/map_helpers";
import type { Route } from "../../shared/api_schema";

export const RouteStateIcon: FC<{ route: Route }> = ({ route }) => {
  const iconProps: SvgIconProps = {
    fontSize: "large",
    color: "primary",
  };
  if (routeHasEnded(route)) {
    return <CheckCircle {...iconProps} />;
  } else if (!routeHasBegun(route)) {
    return <HourglassEmpty {...iconProps} />;
  } else {
    return <LocalShipping {...iconProps} />;
  }
};
