import type { ButtonProps } from "@mui/material";
import { Button, CircularProgress } from "@mui/material";
import type { FC, ReactNode } from "react";

export const RButton: FC<
  ButtonProps & {
    loading?: boolean;
    spinnerSize?: number;
    children: ReactNode;
  }
> = ({ loading = false, spinnerSize = 22, children, ...rest }) => {
  return (
    <Button {...rest} disabled={rest.disabled || loading}>
      <div style={{ position: "relative" }}>
        <div style={{ visibility: loading ? "hidden" : "visible" }}>
          {children}
        </div>
        {loading && (
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              lineHeight: 0,
            }}
          >
            <CircularProgress style={{ color: "white" }} size={spinnerSize} />
          </div>
        )}
      </div>
    </Button>
  );
};
