import { Box, Divider, Theme, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import { DateTime } from "luxon";
import type { FC } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { RButton } from "../../components/RButton";
import { RFormButtons } from "../../components/RFormButtons";
import { FormTextField } from "../../components/forms/FormTextField";
import { FormTimePicker } from "../../components/forms/FormTimePicker";
import { FormZonePicker } from "../../components/forms/FormZonePicker";
import {
  EMPTY_LOCATION,
  LocationForm,
} from "../../components/forms/LocationForm";
import { nullsToEmptyStrings } from "../../components/forms/form_helpers";
import { useAuth } from "../../hooks/useAuth";
import { useSnackbar } from "../../hooks/useSnackbar";
import { del, post, put } from "../../lib/amplify";
import type {
  Clock24hrTime,
  CreateVehicleRequest,
  IdOnly,
  Location,
  UpdateVehicleRequest,
  Vehicle,
  Zone,
} from "../../shared/api_schema";
import { toLocale } from "../../shared/frontend";

type VehicleFormTypes = {
  zoneId: string;
  name: string;
  licensePlate: string;
  open: Clock24hrTime;
  close: Clock24hrTime;
  home: Location;
  capacity: {
    ft3: string | undefined;
    lbs: string | undefined;
  };
  speedFactor: string;
};

export const VehicleForm: FC<{
  vehicle?: Vehicle;
  defaultZone?: Zone; // Onboarding hands over a suggested Zone for the vehicle
  defaultName?: string; // Default name for vehicle
  onSubmitSuccess: () => void;
}> = ({ vehicle, defaultZone, defaultName, onSubmitSuccess }) => {
  const { currentUser } = useAuth();
  const tzDetails = currentUser!.timeZoneDetails;

  const vehicleForm = useForm<VehicleFormTypes>({
    defaultValues: {
      zoneId: vehicle?.zone.id ?? defaultZone?.id ?? "",
      name: vehicle?.name ?? defaultName ?? "",
      licensePlate: vehicle?.licensePlate ?? "",
      open: vehicle?.open ?? "09:00",
      close: vehicle?.close ?? "17:00",
      home: nullsToEmptyStrings(vehicle?.home) ?? {
        ...EMPTY_LOCATION,
        locality: tzDetails.mainCities[0],
        country: tzDetails.countryName,
        timeZone: tzDetails.name,
      },
      capacity: {
        ft3: vehicle?.capacity.ft3?.toString() ?? "",
        lbs: vehicle?.capacity.lbs?.toString() ?? "",
      },
      speedFactor: vehicle?.speedFactor?.toString() ?? "1",
    },
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = vehicleForm;
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();

  const classes = makeStyles((theme: Theme) => {
    return {
      divider: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(3),
      },
    };
  })();

  const addVehicleMutation = useMutation(
    async (payload: CreateVehicleRequest) => post("/vehicles", payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("vehicles");
        snackbar.show("Vehicle added!");
        onSubmitSuccess?.();
      },
    }
  );

  const updateVehicleMutation = useMutation(
    async (vehicle: UpdateVehicleRequest & IdOnly) =>
      put(`/vehicles/${vehicle.id}`, vehicle),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("vehicles");
        snackbar.show("Vehicle updated!");
        onSubmitSuccess?.();
      },
    }
  );

  const deleteVehicleMutation = useMutation(
    async (vehicleId: string) => del(`/vehicles/${vehicleId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("vehicles");
        snackbar.show("Vehicle deleted!");
        onSubmitSuccess?.();
      },
    }
  );

  function formToVehicle(
    data: VehicleFormTypes
  ): CreateVehicleRequest | UpdateVehicleRequest {
    return {
      name: data.name,
      licensePlate: data.licensePlate,
      open: toLocale(data.open, tzDetails.name, DateTime.TIME_24_SIMPLE), // TimePicker always uses the *local* time zone
      close: toLocale(data.close, tzDetails.name, DateTime.TIME_24_SIMPLE), // TimePicker always uses the *local* time zone
      home: data.home,
      capacity: {
        ft3: data.capacity.ft3 ? parseInt(data.capacity.ft3) : undefined,
        lbs: data.capacity.lbs ? parseInt(data.capacity.lbs) : undefined,
      },
      speedFactor: parseFloat(data.speedFactor),
    };
  }

  const formSubmit = (data: VehicleFormTypes) => {
    if (vehicle) {
      console.log("formToVehicle(data)", formToVehicle(data));
      updateVehicleMutation.mutate({
        ...formToVehicle(data),
        id: vehicle.id!,
      });
    } else {
      addVehicleMutation.mutate({
        ...(formToVehicle(data) as CreateVehicleRequest),
        zone: { id: data.zoneId },
      });
    }
  };

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(formSubmit)}
      data-id={vehicle?.id}
    >
      <Grid container spacing={5}>
        <Grid item md={4}>
          <FormZonePicker
            name="zoneId"
            setValue={setValue}
            control={control}
            errors={errors}
            disabled={!!vehicle} // Too complicated to try to change zones at the moment
          />
        </Grid>
        <Grid item md={4}>
          <FormTextField
            control={control}
            errors={errors}
            name="name"
            label="Name"
            rules={{ required: "A name is required" }}
            autoFocus
          />
        </Grid>
        <Grid item md={4}>
          <FormTextField
            control={control}
            errors={errors}
            name="licensePlate"
            label="License Plate"
          />
        </Grid>
        <Grid item md={3}>
          <FormTimePicker
            control={control}
            name="open"
            label="Open"
            errors={errors}
          />
        </Grid>
        <Grid item md={3}>
          <FormTimePicker
            control={control}
            name="close"
            label="Close"
            errors={errors}
          />
        </Grid>
        <Grid item md={2}>
          <FormTextField
            control={control}
            errors={errors}
            name="capacity.ft3"
            label="Capacity (ft³)"
          />
        </Grid>
        <Grid item md={2}>
          <FormTextField
            control={control}
            errors={errors}
            name="capacity.lbs"
            label="Capacity (lbs)"
          />
        </Grid>
        <Grid item md={2}>
          <FormTextField
            control={control}
            errors={errors}
            name="speedFactor"
            label="Speed Factor"
            rules={{
              validate: {
                allowedRange: (v: string) => {
                  const speedFactorValue = parseFloat(v);
                  if (speedFactorValue < 0.01 || speedFactorValue > 5.0) {
                    return "Value must be between 0.01-5.00";
                  }
                },
              },
            }}
          />
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Box mb={4}>
        <Typography variant="h2">Home</Typography>
      </Box>
      <LocationForm locationKey="home" parentForm={vehicleForm} />
      <Grid container spacing={5}>
        <Grid item md={12}>
          <RFormButtons>
            {vehicle && (
              <RButton
                color="secondary"
                onClick={async () =>
                  deleteVehicleMutation.mutateAsync(vehicle.id)
                }
                loading={deleteVehicleMutation.isLoading}
              >
                Delete
              </RButton>
            )}
            <RButton
              loading={
                addVehicleMutation.isLoading || updateVehicleMutation.isLoading
              }
              type="submit"
            >
              Save
            </RButton>
          </RFormButtons>
        </Grid>
      </Grid>
    </form>
  );
};
