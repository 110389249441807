import type { CognitoUser } from "@aws-amplify/auth";
import type { RawTimeZone } from "@vvo/tzdb";
import { Auth, Hub } from "aws-amplify";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useQueryClient } from "react-query";
import config from "../config";
import { get, idToken } from "../lib/amplify";
import {
  getUserLocalStorage,
  setUserLocalStorage,
} from "../lib/user_local_storage";
import {
  OrganizationWithRole,
  ShowMeResponse,
  UserRole,
} from "../shared/api_schema";
import {
  NoOrganizationError,
  getRawTimeZone,
  useWebsocketsZ,
} from "../shared/frontend";

// Auth calls can return extra info not captured in CognitoUser
type CognitoUserEx = CognitoUser & {
  challengeName?: string;
  challengeParam?: {
    userAttributes: {
      email: string;
    };
  };
};

export type LoginCredentials = {
  email: string;
  password: string;
};

interface Props {
  currentUser: RoutesUser | undefined;
  token: string | undefined;
  unconfirmedUser: CognitoUserEx | undefined;
  isCheckingAuth: boolean;
  organizationSelectionRequired: boolean;
  login: (options: {
    credentials: LoginCredentials;
    requireOrganizationSelection?: boolean;
  }) => Promise<RoutesUser | CognitoUserEx>;
  logout: () => void;
  refetchCurrentUser: () => Promise<RoutesUser>; // Used when updating your own profile -- need to tell auth to re-grab the updated values
}

// "{} as Props" == hack to let TypeScript not require a default here
const AuthContext = createContext<Props>({} as Props);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const auth = useAuthProvider();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const ACTIVE_ORGANIZATION_KEY = "activeOrganizationId";

type RoutesUserDetails = Omit<
  RoutesUser,
  "_activeOrganization" | "hasActiveOrganization"
>;
export class RoutesUser {
  firstName: string;
  lastName: string;
  timeZone: string;
  organizations: OrganizationWithRole[];
  timeZoneDetails: RawTimeZone;
  id: string;
  email: string;
  private _activeOrganization: OrganizationWithRole | undefined;

  constructor(user: RoutesUserDetails) {
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.id = user.id;
    this.email = user.email;
    this.timeZone = user.timeZone;
    this.organizations = user.organizations;
    this.timeZoneDetails = user.timeZoneDetails;
    this._activeOrganization = user.activeOrganization;
  }

  hasActiveOrganization() {
    return !!this._activeOrganization;
  }

  get activeOrganization(): OrganizationWithRole {
    if (this._activeOrganization) {
      return this._activeOrganization;
    } else if (this.organizations.length === 1) {
      return this.organizations[0];
    } else {
      throw new NoOrganizationError("No active organization!");
    }
  }

  set activeOrganization(organization: OrganizationWithRole) {
    this._activeOrganization = organization;
    setUserLocalStorage(ACTIVE_ORGANIZATION_KEY, organization.id);
  }
}

function useAuthProvider(): Props {
  const queryClient = useQueryClient();
  const [currentUser, setCurrentUser] = useState<RoutesUser>();
  const [token, setToken] = useState<string>();
  const [unconfirmedUser, setUnconfirmedUser] = useState<CognitoUserEx>();
  const [organizationSelectionRequired, setOrganizationSelectionRequired] =
    useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = useState(false);
  const websocketErrorEvent = useWebsocketsZ((state) => state.errorEvent);

  const refetchCurrentUser = useCallback(async () => {
    // Check with Cognito before even trying /me
    const cognitoUser = await Auth.currentSession();

    // Gets app-specific user details that live in the app's db
    // (name, role and other properties not in Cognito)
    console.debug("Auth — Fetching /me");
    const me = (await get<ShowMeResponse>("/users/me")).user;

    // If we already have an organization ID stored in localstorage, use it to grab the
    // relevant organization off the response. If we fail to grab it, set to the first one returned.
    const activeOrganizationId = await getUserLocalStorage<string>(
      ACTIVE_ORGANIZATION_KEY
    );

    let activeOrganization = me.organizations.find(
      (o) => o.id === activeOrganizationId
    );

    if (!activeOrganization) {
      activeOrganization = me.organizations[0];

      if (activeOrganization) {
        // Drivers don't have dispatch access
        if (activeOrganization.role === UserRole.DRIVER) {
          throw Error("Access denied");
        }

        await setUserLocalStorage(
          ACTIVE_ORGANIZATION_KEY,
          activeOrganization.id
        );
      }
    }

    // Need to both set currentUser to this value,
    // but *also return it* (for `login` calls)
    const user = new RoutesUser({
      ...me,
      activeOrganization,
      timeZoneDetails: getRawTimeZone(me.timeZone),
    });

    setCurrentUser(user);
    setToken(cognitoUser.getIdToken().getJwtToken());

    return user;
  }, []);

  // On mount, try to fetch the current user
  useEffect(() => {
    async function initialFetch() {
      setIsCheckingAuth(true);
      try {
        await refetchCurrentUser();
        setIsCheckingAuth(false);
      } catch (err) {
        // Just means we aren't currently authed
        setIsCheckingAuth(false);
      }
    }

    initialFetch();
  }, [refetchCurrentUser]);

  // On mount, set up listeners for token changes
  useEffect(() => {
    return Hub.listen("auth", async (data) => {
      console.log("onAuthEvent", data.payload.event, data.payload);
      switch (data.payload.event) {
        case "signOut":
          useWebsocketsZ.getState().close();
          break;
        case "tokenRefresh":
          setToken(await idToken());
          break;
      }
    });
  }, []);

  // If the token changes, connect the WebSocket with it
  useEffect(() => {
    if (token) {
      useWebsocketsZ.getState().connect(config.websocket.URL, token);
    }
  }, [token]);

  // If our WebSocket reports an error, there are two possible reasons:
  // 1. The server is down, so the connection failed
  // 2. Our token is stale
  // Unfortunately we cannot disambiguate between the two (at least that I can tell), and this seems by design:
  // https://stackoverflow.com/questions/21762596/how-to-read-status-code-from-rejected-websocket-opening-handshake-with-javascrip
  // We will *not* receive the tokenRefresh Hub event from above just sitting around in the app
  // So the only way for us to hear about requiring a token refresh is from the WebSocket
  // We'll listen to the error stream and reset the token on any error
  useEffect(() => {
    if (!websocketErrorEvent) {
      return;
    }

    console.log("Requesting session refresh due to Websocket error");
    Auth.currentSession();
  }, [websocketErrorEvent]);

  async function login(options: {
    credentials: LoginCredentials;
    requireOrganizationSelection?: boolean;
  }): Promise<RoutesUser | CognitoUserEx> {
    setOrganizationSelectionRequired(
      options.requireOrganizationSelection || false
    );
    setIsCheckingAuth(true);

    try {
      const cognitoUser: CognitoUserEx = await Auth.signIn(
        options.credentials.email,
        options.credentials.password
      );

      // A "challenge", (such as requiring a password change) can be present here
      // When a challenge is received we get a `CognitoUser` that is not-quite-valid
      // We set this user to `unconfirmedUser` which can then be used elsewhere to
      // make calls to change the user's password
      if (cognitoUser.challengeName) {
        setUnconfirmedUser(cognitoUser);
        return cognitoUser;
      } else {
        const user = await refetchCurrentUser();
        setIsCheckingAuth(false);
        return user;
      }
    } catch (err) {
      setIsCheckingAuth(false);
      setOrganizationSelectionRequired(false);
      throw err;
    }
  }

  async function logout() {
    // Clear the invite in case the user chose to sign out while dealing with one
    await Auth.signOut();
    queryClient.clear();
    setCurrentUser(undefined);
  }

  return {
    currentUser,
    token,
    unconfirmedUser,
    isCheckingAuth,
    organizationSelectionRequired,
    login,
    logout,
    refetchCurrentUser,
  };
}

export const useAuth = () => useContext(AuthContext);
