import { useMutation, useQueryClient } from "react-query";
import { del } from "../../lib/amplify";
import { getStopQueryKey } from "../queries/useShowStop";

const delStopShipment = (params: { stopId: string; shipmentId: string }) =>
  del(`/stops/${params.stopId}/shipments/${params.shipmentId}`);

export default function useRemoveStopShipmentMutation() {
  const queryClient = useQueryClient();
  return useMutation(delStopShipment, {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(getStopQueryKey(variables.stopId));
    },
  });
}
