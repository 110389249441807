import { useQuery } from "react-query";
import { get } from "../../lib/amplify";
import { ZonesRequiringRouteResponse } from "../../shared/api_schema";

export function zonesRequiringRouteKey(date: string) {
  return ["zones_requiring_route", date];
}

export const useZonesRequiringRoute = (date: string) => {
  return useQuery(zonesRequiringRouteKey(date), async () =>
    get<ZonesRequiringRouteResponse>(`/zones/requiring_route?date=${date}`)
  );
};
