import AddCircle from "@mui/icons-material/AddCircle";
import { Box, Button } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid";
import { FC, useState } from "react";
import { PaginatedCustomDataGrid } from "../../components/PaginatedCustomDataGrid";
import { Customer, ScheduledAppointment, Zone } from "../../shared/api_schema";
import { dayOfWeekFullName, toDurationShort } from "../../shared/frontend";
import { ScheduledAppointmentDialog } from "../appointments/ScheduledAppointmentDialog";

export const ScheduledAppointments: FC<{ customer: Customer }> = ({
  customer,
}) => {
  const [creatingAppointment, setCreatingAppointment] = useState(false);
  const [editingAppointment, setEditingAppointment] =
    useState<ScheduledAppointment>();

  const columns: GridColumns<ScheduledAppointment> = [
    {
      field: "Zone",
      valueGetter: ({ row }) => (row.zone as Zone).name,
      flex: 2,
    },
    {
      field: "Day of Week",
      valueGetter: ({ row }) => dayOfWeekFullName(row.dayOfWeek),
      flex: 1,
    },
    {
      field: "open",
      headerName: "Open",
      flex: 1,
    },
    {
      field: "service",
      headerName: "Service",
      valueGetter: ({ row }) => toDurationShort(row.service),
      flex: 1,
    },
    {
      field: "close",
      headerName: "Close",
      flex: 1,
    },
  ];

  return (
    <>
      <Box display="flex" justifyContent="flex-end" mb={4}>
        <Button
          startIcon={<AddCircle />}
          onClick={() => setCreatingAppointment(true)}
        >
          Add Scheduled Appointment
        </Button>
      </Box>
      <PaginatedCustomDataGrid
        model={{
          key: ["customer_scheduled_appointments", customer.id],
          dataPath: (skip, take) =>
            `/scheduled_appointments?customer=${customer.id}&skip=${skip}&take=${take}`,
          countPath: `/scheduled_appointments?customer=${customer.id}&count=true`,
          responseKey: "scheduledAppointments",
        }}
        columns={columns}
        onRowClick={({ row }) => setEditingAppointment(row)}
      />
      {creatingAppointment && (
        <ScheduledAppointmentDialog
          customer={customer}
          onClose={() => setCreatingAppointment(false)}
        />
      )}
      {editingAppointment && (
        <ScheduledAppointmentDialog
          customer={customer}
          appointment={editingAppointment}
          onClose={() => setEditingAppointment(undefined)}
        />
      )}
    </>
  );
};
