import type { AlertColor } from "@mui/material";
import { Alert, Box, LinearProgress } from "@mui/material";
import type { ReactNode } from "react";
import { useInvitation } from "../../hooks/useInvitation";

export const InviteBanner = ({ message }: { message?: string }) => {
  const { invitationQuery } = useInvitation();
  const { data, isLoading, isError } = invitationQuery;
  const invite = data?.invite;

  if (invite) {
    return (
      <Banner>
        You have been invited to join{" "}
        <strong>{invite.organization.name}</strong>. {message}
      </Banner>
    );
  } else if (isLoading) {
    return <Banner loading={true} />;
  } else if (isError) {
    return <Banner severity="error">Invitation could not be found!</Banner>;
  } else {
    return null;
  }
};

const Banner = ({
  severity = "info",
  loading = false,
  children,
}: {
  severity?: AlertColor;
  loading?: boolean;
  children?: ReactNode;
}) => {
  return (
    <Box my={4}>
      {loading ? (
        <LinearProgress />
      ) : (
        <Alert severity={severity}>{children}</Alert>
      )}
    </Box>
  );
};
