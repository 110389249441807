import { Grid } from "@mui/material";
import type { FC } from "react";
import type { UseFormReturn } from "react-hook-form";
import type { Contact } from "../../shared/api_schema";
import { EMAIL_REGEX } from "../../shared/frontend";
import { FormTextField } from "./FormTextField";

export const EMPTY_CONTACT: Contact = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
};

export const ContactForm: FC<{
  parentForm: UseFormReturn<any>;
  contactKey?: string;
  disabled?: boolean;
}> = ({ parentForm, contactKey = "contact", disabled }) => {
  const {
    control,
    formState: { errors },
  } = parentForm;

  function key(name: string) {
    return `${contactKey}.${name}`;
  }

  return (
    <Grid container spacing={5}>
      <Grid item md={3}>
        <FormTextField
          label="First Name"
          name={key("firstName")}
          control={control}
          errors={errors}
          disabled={disabled}
        />
      </Grid>

      <Grid item md={3}>
        <FormTextField
          label="Last Name"
          name={key("lastName")}
          control={control}
          errors={errors}
          disabled={disabled}
        />
      </Grid>

      <Grid item md={3}>
        <FormTextField
          label="Email"
          name={key("email")}
          control={control}
          errors={errors}
          disabled={disabled}
          rules={{
            pattern: {
              value: EMAIL_REGEX,
              message: "Invalid email address",
            },
          }}
        />
      </Grid>

      <Grid item md={3}>
        {/* https://support.secureauth.com/hc/en-us/articles/360036402211-Regular-Expressions-for-ITU-E-123-and-E-164-phone-number-formats */}
        <FormTextField
          label="Phone"
          name={key("phone")}
          control={control}
          disabled={disabled}
          errors={errors}
          // rules={{
          //   pattern: {
          //     value:
          //       /^\+\d{1,3}\s\d{2,3}\s\d{2,3}\s\d{4}|^\+\d{1,3}\s\d{1,14}(\s\d{1,13})?|^\(\d{3}\)\s\d{3}\s\d{4}?/,
          //     message: "Invalid phone number",
          //   },
          // }}
        />
      </Grid>
    </Grid>
  );
};
