import { object, ObjectSchema, string } from "yup";
import { Coord, coordSchema } from "./coord";

export type Location = {
  name: string;
  address: string;
  locality: string;
  region: string;
  postalcode: string;
  country: string;
  timeZone: string;
  coord: Coord;
};

export const locationSchema: ObjectSchema<Location> = object({
  name: string().required(),
  address: string().required(),
  locality: string().required(),
  region: string().required(),
  postalcode: string().required(),
  country: string().required(),
  timeZone: string().required(),
  coord: coordSchema,
});
