/* eslint-disable */

import { Box } from "@mui/material";
import { LonLatDebug } from "./LonLatDebug";
import { PolylineDebug } from "./PolylineDebug";

export const Experiments = () => {
  return (
    <Box style={{ display: "flex", height: "100%" }}>
      <PolylineDebug />
      <LonLatDebug />
    </Box>
  );
};
