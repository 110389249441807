import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Theme,
  Typography,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { DateTime } from "luxon";
import { useAuth } from "../../hooks/useAuth";
import type {
  DeepShipmentV2,
  Stop,
  TrackingEvent,
} from "../../shared/api_schema";
import { toLocale, trackingEventFormatted } from "../../shared/frontend";
import { AddTrackingEventForm } from "./AddTrackingEventForm";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    timeline: {
      margin: 0,
      padding: 0,
    },
    capitalize: {
      textTransform: "capitalize",
    },
    noEvents: {
      padding: theme.spacing(6),
      fontSize: 18,
      textAlign: "center",
    },
  })
);

export const TrackingEventsTimeline = ({
  shipment,
  trackingEvents,
}: {
  shipment: DeepShipmentV2;
  trackingEvents: TrackingEvent[];
}) => {
  const { currentUser } = useAuth();
  const classes = useStyles();

  return (
    <Timeline className={classes.timeline}>
      <Box mt={4} mb={4}>
        <Accordion>
          <AccordionSummary
            style={{ backgroundColor: "rgba(0, 0, 0, .03)" }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Add Tracking Event</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AddTrackingEventForm shipment={shipment} />
          </AccordionDetails>
        </Accordion>
      </Box>
      {trackingEvents.map((t, index) => {
        const trackingEvent = t as TrackingEvent;
        const stopId = t.stop?.id;
        const stop = shipment.stops.find((s) => s.id === stopId);

        return (
          <TimelineItem key={t.id} style={{ minHeight: 0 }}>
            <TimelineOppositeContent>
              {toLocale(
                trackingEvent.occurredAt,
                currentUser!.timeZone,
                DateTime.DATETIME_MED
              )}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="primary" />
              {index < trackingEvents.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent className={classes.capitalize}>
              <Typography>{trackingEventFormatted(trackingEvent)}</Typography>
              {stop && (
                <Typography fontSize={12}>
                  {(stop as Stop).location.name}
                </Typography>
              )}
            </TimelineContent>
          </TimelineItem>
        );
      })}
      {!trackingEvents.length && (
        <Typography className={classes.noEvents}>
          No events have been recorded for this shipment.
        </Typography>
      )}
    </Timeline>
  );
};
