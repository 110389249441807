import { useMutation, useQueryClient } from "react-query";
import { put } from "../../lib/amplify";
import type { IdOnly, UpdateUserRequest } from "../../shared/api_schema";
import { getUsersQueryKey } from "../queries/useUsersQuery";

export function useUpdateUserMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (user: UpdateUserRequest & IdOnly) => put(`/users/${user.id}`, user),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getUsersQueryKey());
      },
    }
  );
}
