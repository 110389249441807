export function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="#49a641"
      viewBox="0 0 1532 406"
    >
      <path d="M238.63111,20.60277a60.27064,60.27064,0,0,0-85.13623.001L3,171.09911l25.87933,25.87842L179.374,46.48314a23.63022,23.63022,0,0,1,33.378-.001L352.56581,186.29565a23.63078,23.63078,0,0,1,0,33.37927L212.752,359.48852a23.6026,23.6026,0,0,1-33.37775,0l-89.2038-89.20459a2.83691,2.83691,0,0,1-.835-1.97388,1.9921,1.9921,0,0,1,.55658-1.49536c32.33045-32.33008,63.42231-45.51758,107.31727-45.51758h24.67627l-49.02167,49.022L196.75245,294.208l91.18518-91.18616-91.25586-91.25635-23.88843,23.889,49.0434,49.04334H197.20936c-53.224,0-94.30329,17.34485-133.19636,56.23743a38.35,38.35,0,0,0-11.27319,27.85315,39.234,39.234,0,0,0,11.55133,27.3739l89.20374,89.20447a60.19826,60.19826,0,0,0,85.13623,0L378.4452,245.55322a60.26957,60.26957,0,0,0,0-85.136Z" />
      <path d="M768.65878,125.27929q-21.31458-11.26062-49.38843-11.2605-28.088,0-49.38855,11.2605a79.35464,79.35464,0,0,0-32.93506,32.06018Q625.34747,178.125,625.3334,205.16161q0,27.05091,11.61334,47.83619a79.35426,79.35426,0,0,0,32.93506,32.0603q21.31467,11.26062,49.38855,11.2605,28.06677,0,49.38843-11.2605a79.524,79.524,0,0,0,32.9209-31.89087Q813.20011,232.551,813.193,205.16161q0-27.02965-11.61328-47.82214A79.23769,79.23769,0,0,0,768.65878,125.27929ZM751.669,242.25939q-11.959,14.20277-32.39868,14.20984-20.10828,0-32.06024-14.20984Q675.251,228.03558,675.244,205.16161q0-22.8598,11.96613-37.08374,11.95908-14.20275,32.06024-14.20972,20.44667,0,32.39868,14.20972,11.95917,14.224,11.96619,37.08374Q763.63522,228.04247,751.669,242.25939Z" />
      <path d="M956.33774,203.7788q0,24.61651-10.061,38.29712-10.05414,13.69482-28.41968,13.70191-17.67389,0-26.16162-12.30494-8.5091-12.31878-8.49488-36.57568V116.093H834.33408v96.70264q0,45.06335,19.75537,64.28967,19.74847,19.24036,50.94067,19.23328,18.03406,0,30.50806-6.23706a68.48178,68.48178,0,0,0,21.82972-17.32825l4.50134,21.49109h43.335V116.093H956.33774Z" />
      <path d="M1509.97424,199.79943q-19.24037-11.26044-55.287-15.42322-16.99677-1.73583-24.27087-3.11865a30.70133,30.70133,0,0,1-11.78272-4.5155q-4.50843-3.11151-4.50147-9.35559,0-8.65705,8.49488-13.85694,8.48786-5.20714,22.35181-5.207,14.9013,0,24.4403,6.42053,9.525,6.41346,10.5691,16.45349h46.43933q-1.73584-27.02965-24.08753-42.27661-22.35186-15.2611-58.744-15.60669-22.54248,0-39.86352,7.28125-17.33534,7.28119-26.85328,20.63025a51.34948,51.34948,0,0,0-9.53906,30.66321q0,17.33533,9.35559,27.728,9.35559,10.41395,24.96241,15.254,15.59967,4.847,39.158,7.97278,17.3355,2.07422,24.96228,3.64062c5.08008,1.04419,9.00293,2.70923,11.78271,5.02344,2.76587,2.31421,4.16284,5.78552,4.16284,10.3999q0,9.35541-9.017,14.379-9.017,5.0376-24.6095,5.0376-16.27716,0-26.684-7.28125-10.39288-7.28119-11.444-19.064h-46.43934q1.37586,28.42658,24.6095,44.88709,23.21979,16.44634,60.31055,16.45337,23.918,0,42.10718-7.09778,18.20307-7.112,28.41944-20.27747a48.3559,48.3559,0,0,0,10.23046-30.508Q1529.20739,211.06707,1509.97424,199.79943Z" />
      <path d="M558.69655,145.21813V116.093H510.183V294.24438h48.51355v-88.3772c0-29.80249,10.75263-49.55786,34.67072-49.55786h28.75824V116.093H602.37008C581.23183,116.093,568.06624,123.37426,558.69655,145.21813Z" />
      <path d="M1303.38439,124.58788q-20.61512-10.58331-47.64966-10.56909-26.69311,0-46.96289,11.25989a78.99516,78.99516,0,0,0-31.53882,31.89123q-11.2771,20.61677-11.27515,48.34449,0,27.38854,11.6145,48.00513a78.16371,78.16371,0,0,0,33.276,31.70752q21.66963,11.0907,51.47523,11.09131,22.85742,0,40.53955-8.14222a73.36029,73.36029,0,0,0,28.43628-22.35132,70.21515,70.21515,0,0,0,13.291-30.847H1296.1693c-2.17749,7.74536-6.26172,13.77881-12.53967,17.851q-9.85914,6.41272-24.77625,6.40613-18.71228,0-29.46228-11.09131-10.73786-11.0907-12.475-30.49341v-1.39795h129.28223a90.00254,90.00254,0,0,0,1.3816-16.637q-.33326-25.99146-11.94983-45.22559A78.7196,78.7196,0,0,0,1303.38439,124.58788Zm-85.426,59.78833q2.07256-15.60094,12.99609-24.79346,10.92573-9.16535,26.85279-9.17236,16.63989,0,27.55957,8.834,10.91967,8.826,13.34753,25.13183Z" />
      <path d="M1106.74266,66.31859h-48.17481V86.322c0,23.0127-6.60913,29.70654-29.707,29.70654H1024.952v40.41724h33.61585v77.38086c0,40.55567,25.99182,60.41773,66.20838,60.41773h28.75537V253.69006h-16.27576c-23.72522,0-30.51318-6.87537-30.51318-30.51319V156.44579h46.78894V116.02855h-46.78894Z" />
    </svg>
  );
}
