import { number, object, ObjectSchema } from "yup";

// May only want to specify one of these, so either can be undefined
export type Dimensions = {
  ft3?: number;
  lbs?: number;
};

export const dimensionsSchema: ObjectSchema<Dimensions> = object({
  ft3: number(),
  lbs: number(),
});
