import { DateTime, Duration } from "luxon";
import type {
  Location,
  Route,
  Shipment,
  ShipmentExceptionType,
  TrackingEvent,
  TrackingEventType,
  Zone,
} from "../api_schema";

export const routeTitle = (route: Omit<Route, "zone"> & { zone: Zone }) =>
  `${route.zone.name} - ${toLocale(
    route.begin.expected!,
    route.zone.timeZone,
    DateTime.DATE_MED
  )}`;

export const userRoleString = (role: string): string => {
  switch (role) {
    case "admin":
      return "Administrator";
    case "dispatcher":
      return "Dispatcher";
    case "driver":
      return "Driver";
    default:
      return role;
  }
};

export const trackingEventTypeString = (
  trackingEventType: TrackingEventType
) => {
  return titleize(trackingEventType.replace("_", " "));
};

export const exceptionTypeString = (
  shipmentExceptionType: ShipmentExceptionType
) => {
  return titleize(shipmentExceptionType.replace(/_/g, " "));
};

export const trackingEventFormatted = (trackingEvent: TrackingEvent) => {
  let str = trackingEventTypeString(trackingEvent.type);

  if (trackingEvent.subtype) {
    str += ` (${exceptionTypeString(trackingEvent.subtype)})`;
  }

  return str;
};

export const locationFormatted = (location: Location) => {
  return `${location.address}, ${location.locality}, ${location.region}, ${location.postalcode}, ${location.country}`;
};

// Converts to "title case" (ex. "arriving soon" -> "Arriving Soon")
export function titleize(text: string) {
  return text.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
}

// "12:34" -> DateTime
export const fromClock = (
  instant: DateTime,
  time: string,
  timeZone: string
): DateTime => {
  const [HH, mm] = time.split(":").map((v) => parseInt(v, 10));
  return instant
    .setZone(timeZone)
    .set({ hour: HH, minute: mm })
    .startOf("minute");
};

// "09:00", "America/Vancouver" -> "9:00 AM"
// "2021-12-09T11:00-08:00", "America/Vancouver" -> "11:00 AM"
// DateTime, "America/Vancouver" -> "12:34 PM"
// Overloaded so it works either from an ISO string or from a DateTime
// No timezone is required if all times can be treated as "local"
export function toLocale(
  datetime: string,
  timeZone: string,
  fmt?: Intl.DateTimeFormatOptions
): string;
export function toLocale(
  datetime: DateTime,
  timeZone: string,
  fmt?: Intl.DateTimeFormatOptions
): string;
export function toLocale(
  datetime: string | DateTime,
  timeZone: string,
  fmt: Intl.DateTimeFormatOptions = DateTime.TIME_24_SIMPLE
): string {
  if (typeof datetime === "string") {
    datetime = DateTime.fromISO(datetime, { zone: timeZone });
  }

  let localeString = datetime.setZone(timeZone).toLocaleString(fmt);

  // https://github.com/moment/luxon/issues/726
  // This works around a "bug" in Chrome around treating "00" as
  // "24". It comes out of Luxon's `toLocaleString()` but at lower
  // level results from bad ECMA spec around 12/24 time.
  if (fmt === DateTime.TIME_24_SIMPLE) {
    const timeSplit = localeString.split(":");
    if (timeSplit[0] === "24") {
      timeSplit[0] = "00";
      localeString = timeSplit.join(":");
    }
  }

  return localeString;
}

// "Ian", "Clarkson" -> "Ian Clarkson"
export const fullName = (user: {
  firstName: string | null;
  lastName: string | null;
}) => {
  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  } else if (user.lastName) {
    return user.lastName;
  } else if (user.firstName) {
    return user.firstName;
  } else {
    return "";
  }
};

export const fullNamesMatch = (
  user1: {
    firstName: string | null;
    lastName: string | null;
  },
  user2: {
    firstName: string | null;
    lastName: string | null;
  }
) => {
  return (
    user1.firstName === user2.firstName && user1.lastName === user2.lastName
  );
};

// 1380 -> "  :23" (if < an hour)
// 4980 -> "01:23" (if >= an hour)
export const toDuration = (seconds: number) => {
  if (seconds < 3600) {
    return `  ${Duration.fromObject({ seconds }).toFormat(":mm")}`;
  } else {
    return Duration.fromObject({ seconds }).toFormat("hh:mm");
  }
};

// 23 -> "23s"
// 123 -> "2m"
// 3720 -> "1h:2m"
export const toDurationShort = (seconds: number) => {
  if (seconds < 60) {
    return Duration.fromObject({ seconds }).toFormat("s'sec'");
  } else if (seconds < 3600) {
    return Duration.fromObject({ seconds }).toFormat("m'min'");
  } else {
    return Duration.fromObject({ seconds }).toFormat("h'hr'm'min'");
  }
};

// 1234 -> "1.2km"
export const toDistance = (distanceInMetres: number) => {
  if (distanceInMetres < 1000) {
    return `${distanceInMetres}m`;
  } else {
    return `${(distanceInMetres / 1000).toFixed(1)}km`;
  }
};

// 1 => "Mon"
export const dayOfWeekName = (dayOfWeek: number) =>
  DateTime.now().set({ weekday: dayOfWeek }).toFormat("ccc");

// 1 => "Monday"
export const dayOfWeekFullName = (dayOfWeek: number) =>
  DateTime.now().set({ weekday: dayOfWeek }).toFormat("cccc");

// Filter for "known" (not "adhoc") shipments
export const knownShipmentFilter = (shipment: Shipment) =>
  shipment.destination?.address || shipment.source?.address;

export type ShipmentGroup<T> = {
  groupUuid: string | null;
  shipments: T[];
};

// Bare commonality of Shipment | DeepShipment for use in grouping/sorting function
type MinShipment = { groupUuid?: string; code: string };

export function groupAndSortShipments<T>(shipments: Array<MinShipment>) {
  const grouped = shipments.reduce(
    (acc: ShipmentGroup<T>[], shipment: MinShipment) => {
      // Look for an existing group
      // Use == so that null and undefined are considered equal
      const existingGroup = acc.find((g) => g.groupUuid == shipment.groupUuid);

      if (existingGroup) {
        existingGroup.shipments.push(shipment as T);
      } else {
        acc.push({
          groupUuid: shipment.groupUuid ?? null,
          shipments: [shipment as T],
        });
      }

      return acc;
    },
    []
  );

  const groupedAndSorted = grouped.map((group) => {
    group.shipments.sort((a, b) => {
      return (a as MinShipment).code.localeCompare((b as MinShipment).code);
    });

    return group;
  });

  // Now sort by the groups themselves
  // (Multipieces, with groupUuids first with the single shipment group last)
  groupedAndSorted.sort((a: ShipmentGroup<T>) => {
    return a.groupUuid ? -1 : 1;
  });

  return groupedAndSorted;
}

// "09:00" -> "2023-03-22T09:00-07:00" (assumes local time!)
export function toTimePicker(dateTime: string) {
  return DateTime.fromISO(dateTime, { setZone: true })
    .setZone("local", { keepLocalTime: true })
    .toISO();
}

export function fromTimePicker(dateTime: string, targetTimeZone: string) {
  return DateTime.fromISO(dateTime)
    .setZone(targetTimeZone, { keepLocalTime: true })
    .toISO();
}
