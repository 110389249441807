import { Grid, Typography } from "@mui/material";
import type { AxiosError } from "axios";
import type { FC } from "react";
import type { UseQueryResult } from "react-query";

export const ErrorScreen: FC<{
  query: { error: UseQueryResult<unknown, AxiosError>["error"] };
}> = ({ query }) => {
  const message = (query.error?.response?.data as any).message;
  return (
    <Grid
      container
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      style={{ height: "100%" }}
    >
      <Typography variant="h1">
        ERROR: {query.error?.response?.status ?? "UNKNOWN"}
      </Typography>
      {message ? <Typography>{message}</Typography> : null}
    </Grid>
  );
};
