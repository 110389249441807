import { Button, Typography } from "@mui/material";
import { FC } from "react";
import { useQuery, useQueryClient } from "react-query";
import { LoadingScreen } from "../../components/LoadingScreen";
import { useAuth } from "../../hooks/useAuth";
import { get } from "../../lib/amplify";
import { ShowMeResponse } from "../../shared/api_schema";

export const ActiveOrganization: FC = () => {
  const { currentUser, refetchCurrentUser } = useAuth();
  const queryClient = useQueryClient();
  const meQuery = useQuery<ShowMeResponse>("me", async () => get("/users/me"));

  if (meQuery.isLoading) {
    return <LoadingScreen />;
  }

  return (
    <table style={{ margin: "1em 0", borderSpacing: 0 }}>
      <tbody>
        {meQuery.data!.user.organizations.map((o) => {
          const isActive = o.id === currentUser!.activeOrganization.id;
          return (
            <tr key={o.id}>
              <td style={{ padding: 0 }}>
                <Typography
                  variant="h4"
                  style={
                    isActive ? { fontWeight: "bold" } : { fontWeight: "normal" }
                  }
                >
                  {o.name}
                </Typography>
              </td>
              <td style={{ padding: "0 1em" }}>
                {isActive ? null : (
                  <Button
                    onClick={() => {
                      currentUser!.activeOrganization = o;
                      queryClient.invalidateQueries();
                      refetchCurrentUser();
                    }}
                  >
                    Switch
                  </Button>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
