import type { Dispatch, FC, ReactNode, SetStateAction } from "react";
import { createContext, useContext, useState } from "react";

type GlobalState = Record<string, [any, Dispatch<SetStateAction<any>>]>;
type GlobalStateAndDispatch = [
  GlobalState,
  Dispatch<SetStateAction<GlobalState>>
];
const GlobalContext = createContext<GlobalStateAndDispatch>([
  {},
  () => undefined,
]);

export const GlobalStateProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const globalStateAndDispatch = useState({});

  return (
    <GlobalContext.Provider value={globalStateAndDispatch}>
      {children}
    </GlobalContext.Provider>
  );
};

// If the key doesn't exist, add it to the global state with the initial value
// If the key exists, return the existing value from the global state
// Return a function to set this key in the global state
export function useGlobalState<T>(
  key: string,
  initial?: T
): [T, Dispatch<SetStateAction<T>>] {
  const [globalState, setGlobalState] = useContext(GlobalContext);

  // console.log("Global State", globalState);

  function updateKeyedState(v: SetStateAction<T>) {
    // console.log("Global State - Updating", key, "with", v);
    globalState[key] = [v, updateKeyedState];
    setGlobalState({ ...globalState });
  }

  if (!(key in globalState)) {
    // console.log("Global State - Adding", key, "with initial", initial);
    globalState[key] = [initial, updateKeyedState];
  }

  return globalState[key];
}
