import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";
import type {
  WebsocketPrivateResponse,
  WebsocketRequest,
  WebsocketResponse,
  WebsocketSubscribeRequest,
  WebsocketSubscriptionResponse,
  WebsocketUnsubscribeRequest,
} from "../../../api_schema";

const CONNECTION_FAILURE_TIMEOUT_MS = 5000; // How long until we've decided a connection attempt has failed (we'll abort and try again)
const RECONNECT_TIMEOUT_MS = 5000; // How long to wait before attempting a reconnection (so we don't spam connection attempts if it's unreachable)
const INTENTIONAL_CLOSE_CODE = 1000;

type SubscriptionCallback = (message: WebsocketSubscriptionResponse) => void;

export type Subscription = {
  subject: string;
  id: string;
  callback: SubscriptionCallback;
  name: string;
};

type PrivateSubscriptionCallback = (message: WebsocketPrivateResponse) => void;

export type PrivateSubscription = {
  callback: PrivateSubscriptionCallback;
  name: string;
};

export enum ConnectionState {
  CLOSED, // No user logged in
  CONNECTING, // Actively trying to connect, waiting for handshake to complete
  CONNECTED, // Connected
  WAITING, // Waiting to attempt a reconnection (after failure to connect or loss of connection)
}

// Return types of functions are undefined unless the connection state is CONNECTED
// That way, our consumers don't try to call something like `push` or `subscribe` *before*
// we're properly connected
type WebsocketContextType = {
  connectionState: ConnectionState;
  push: ((message: WebsocketRequest) => void) | undefined;
  subscribe:
    | ((
        subject: string,
        id: string,
        callback: SubscriptionCallback,
        name: string,
        meta?: boolean
      ) => Subscription)
    | undefined;
  unsubscribe: ((subscription: Subscription) => void) | undefined;
  privateSubscribe:
    | ((
        callback: PrivateSubscriptionCallback,
        name: string
      ) => PrivateSubscription)
    | undefined;
  privateUnsubscribe:
    | ((privateSubscription: PrivateSubscription) => void)
    | undefined;
};

export const WebsocketContext = createContext<WebsocketContextType>(
  {} as WebsocketContextType
);

type State = {
  ws: WebSocket | undefined;
  connectionState: ConnectionState;
};

type Action =
  | {
      type: "connecting";
      ws: WebSocket;
    }
  | {
      type: "connected";
      ws: WebSocket;
    }
  | {
      type: "waiting";
    }
  | {
      type: "closed";
    };

function discardWebsocket(ws: WebSocket | undefined) {
  if (!ws) {
    return;
  }
  ws.close();
  ws.onopen = null;
  ws.onmessage = null;
  ws.onerror = null;
  ws.onclose = null;
}

function reducer(prev: State, action: Action): State {
  switch (action.type) {
    case "connecting":
      return {
        ws: action.ws,
        connectionState: ConnectionState.CONNECTING,
      };
    case "connected":
      return {
        ws: action.ws,
        connectionState: ConnectionState.CONNECTED,
      };
    case "waiting":
      discardWebsocket(prev.ws);
      return {
        ws: undefined,
        connectionState: ConnectionState.WAITING,
      };
    case "closed":
      discardWebsocket(prev.ws);
      return {
        ws: undefined,
        connectionState: ConnectionState.CLOSED,
      };
  }
}

export const WebsocketProvider: FC<{
  url: string;
  token: string | undefined;
  children: ReactNode;
}> = ({ url, token, children }) => {
  const [{ ws, connectionState }, dispatch] = useReducer(reducer, {
    ws: undefined,
    connectionState: ConnectionState.CLOSED,
  });

  const subscriptions = useRef(new Array<Subscription>());
  const privateSubscriptions = useRef(new Array<PrivateSubscription>());

  function informSubscribers(message: WebsocketSubscriptionResponse) {
    subscriptions.current
      .filter(
        (subscription) =>
          subscription.subject === message.subject &&
          subscription.id === message.id
      )
      .forEach((listener) => listener.callback(message));
  }

  function informPrivateSubscribers(message: WebsocketPrivateResponse) {
    if (!privateSubscriptions.current.length) {
      console.warn(
        `Websocket — No private subscribers listening for ${JSON.stringify(
          message
        )}`
      );
    }
    privateSubscriptions.current.forEach((listener) =>
      listener.callback(message)
    );
  }

  const push = useCallback(
    (message: WebsocketRequest) => {
      if (!ws) {
        console.debug("[WS]", "Push -- Attempted. No Websocket!");
      } else if (ws.readyState !== WebSocket.OPEN) {
        console.debug("[WS]", "Push -- Attempted. Ready state not OPEN.");
      } else {
        // console.debug("useWebsockets.push", message.action);
        ws.send(JSON.stringify(message));
      }
    },
    [ws]
  );

  const subscribe = useCallback(
    (
      subject: string,
      id: string,
      callback: SubscriptionCallback,
      name: string,
      meta?: boolean
    ) => {
      console.debug("[WS]", "Subscribe", name, subject, id, meta);

      const req: WebsocketSubscribeRequest = {
        action: "subscribe",
        subject,
        id,
        meta,
      };

      push(req);

      const subscription = { subject, id, callback, name, meta };
      subscriptions.current.push(subscription);
      return subscription;
    },
    [push]
  );

  const unsubscribe = useCallback(
    (subscription: Subscription) => {
      console.debug("[WS]", "Unsubscribe", subscription.name);

      const req: WebsocketUnsubscribeRequest = {
        action: "unsubscribe",
        subject: subscription.subject,
        id: subscription.id,
      };

      push(req);

      subscriptions.current = subscriptions.current.filter(
        (s) => s !== subscription
      );
    },
    [push]
  );

  const privateSubscribe = useCallback(
    (callback: PrivateSubscriptionCallback, name: string) => {
      console.debug("[WS]", "PrivateSubscribe", name);

      const privateSubscription = { callback, name };
      privateSubscriptions.current.push(privateSubscription);
      return privateSubscription;
    },
    []
  );

  const privateUnsubscribe = useCallback(
    (privateSubscription: PrivateSubscription) => {
      console.debug("[WS]", "PrivateUnsubscribe", privateSubscription.name);

      privateSubscriptions.current = privateSubscriptions.current.filter(
        (s) => s !== privateSubscription
      );
    },
    []
  );

  const newWebsocket = useCallback((newUrl: string, newToken: string) => {
    console.debug("[WS]", "newWebsocket");

    const newWs = new WebSocket(`${newUrl}?token=${newToken}`);

    dispatch({ type: "connecting", ws: newWs });

    newWs.onopen = () => {
      console.debug("[WS]", "onopen");
      dispatch({ type: "connected", ws: newWs });
    };

    newWs.onerror = (ev) => {
      console.debug("[WS]", "onerror", ev);
    };

    newWs.onmessage = (ev) => {
      const message: WebsocketResponse = JSON.parse(ev.data);

      switch (message.type) {
        case "subscription":
          console.debug(
            "[WS]",
            "onmessage",
            message.type,
            message.action,
            message.subject,
            message.id
          );
          informSubscribers(message);
          break;
        case "private":
          console.debug("[WS]", "onmessage", message.type, message.action);
          informPrivateSubscribers(message);
          break;
      }
    };

    newWs.onclose = (ev) => {
      if (ev.code === INTENTIONAL_CLOSE_CODE) {
        console.debug(
          "[WS]",
          `ws.close ${ev.code} - intentional. not reconnecting`
        );
        dispatch({ type: "closed" });
      } else {
        console.debug(
          "[WS]",
          `ws.close ${ev.code} - unintentional. reconnecting...`
        );
        dispatch({ type: "waiting" });
      }
    };

    return () => discardWebsocket(newWs);
  }, []);

  // If we lose token (logout), close the websocket
  useEffect(() => {
    if (!token) {
      ws?.close(INTENTIONAL_CLOSE_CODE);
    }
  }, [token, ws]);

  // When the token changes, restart the websocket
  useEffect(() => {
    if (token) {
      newWebsocket(url, token);
    }
  }, [newWebsocket, url, token]);

  // When we start connecting,
  // set up a timer to restart if we don't connect in adequate time
  useEffect(() => {
    if (connectionState === ConnectionState.CONNECTING) {
      console.debug("[WS]", "Connecting - setting failure timeout");
      const failureTimeout = setTimeout(() => {
        console.debug("[WS]", "Failed to connect in time!");
        if (ws && ws.readyState !== WebSocket.OPEN) {
          dispatch({ type: "waiting" });
        }
      }, CONNECTION_FAILURE_TIMEOUT_MS);

      return () => {
        console.debug("[WS]", "Clearing failure timeout");
        clearTimeout(failureTimeout);
      };
    }
  }, [connectionState, ws]);

  // Once we're done waiting, try to reconnect again
  useEffect(() => {
    if (token && connectionState === ConnectionState.WAITING) {
      console.debug("[WS]", "Set reconnection timeout");

      const handle = setTimeout(() => {
        console.debug("[WS]", "Running reconnection");
        newWebsocket(url, token);
      }, RECONNECT_TIMEOUT_MS);

      return () => {
        console.debug("[WS]", "Clearing reconnection timeout");
        clearTimeout(handle);
      };
    }
  }, [token, connectionState, newWebsocket, url]);

  // What we pass down -- only changes if the callbacks change
  // If we're not properly connected, expose undefined values for the functions
  // That way, people who depend on us will have to wait until we're in a good state
  const value = useMemo<WebsocketContextType>(() => {
    if (connectionState === ConnectionState.CONNECTED) {
      return {
        connectionState,
        push,
        subscribe,
        unsubscribe,
        privateSubscribe,
        privateUnsubscribe,
      };
    } else {
      return {
        connectionState,
        push: undefined,
        subscribe: undefined,
        unsubscribe: undefined,
        privateSubscribe: undefined,
        privateUnsubscribe: undefined,
      };
    }
  }, [
    connectionState,
    push,
    subscribe,
    unsubscribe,
    privateSubscribe,
    privateUnsubscribe,
  ]);

  return (
    <WebsocketContext.Provider value={value}>
      {children}
    </WebsocketContext.Provider>
  );
};

export const useWebsockets = () => useContext(WebsocketContext);
