import { object, ObjectSchema, string } from "yup";

export type Coord = {
  lat: string;
  lon: string;
};

export const coordSchema: ObjectSchema<Coord> = object({
  lat: string().required(),
  lon: string().required(),
});
