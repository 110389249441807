import type { Theme} from "@mui/material";
import { Tooltip } from "@mui/material";

import withStyles from '@mui/styles/withStyles';

export const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);
