import { produce } from "immer";
import { create } from "zustand";
import { VehiclePosition } from "../shared/api_schema";

type State = {
  latestCoords: Record<string, VehiclePosition | undefined>;
  setLatestCoord: (routeId: string, latestCoord: VehiclePosition) => void;
};

export const useLatestCoords = create<State>((set) => ({
  latestCoords: {},
  setLatestCoord: (routeId: string, latestCoord: VehiclePosition) =>
    set((prevState) =>
      produce(prevState, (draft) => {
        draft.latestCoords[routeId] = latestCoord;
      })
    ),
}));
