import { Box, Card, CardHeader, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import type { FC } from "react";
import { LoadingScreen } from "../../components/LoadingScreen";
import { useListIntegrations } from "../../hooks/queries/useListIntegrations";
import { useSnackbar } from "../../hooks/useSnackbar";
import type {
  BasicIntegration,
  Integration,
  OAuthIntegration,
} from "../../shared/api_schema";
import { isOAuthIntegration } from "../../shared/api_schema";

const WebhooksForm: FC<{ integration: BasicIntegration }> = ({
  integration,
}) => {
  const snackbar = useSnackbar();

  // const {
  //   control,
  //   handleSubmit,
  //   formState: { errors },
  //   setValue,
  // } = useForm<UpdateIntegrationRequest>({
  //   defaultValues: {
  //     webhooks: {
  //       onTrackingEvent: integration.webhooks.onTrackingEvent ?? "",
  //       onRequestAddress: integration.webhooks.onRequestAddress ?? "",
  //       onRequestRedirect: integration.webhooks.onRequestRedirect ?? "",
  //     },
  //   },
  // });

  // const updateIntegrationQuery = useMutation(
  //   async (payload: UpdateIntegrationRequest) =>
  //     put(`/integrations/${integration.id}`, payload),
  //   {
  //     onSuccess: ({ integration }: UpdateIntegrationResponse) => {
  //       setValue(
  //         "webhooks.onTrackingEvent",
  //         integration.webhooks.onTrackingEvent ?? ""
  //       );
  //       setValue(
  //         "webhooks.onRequestAddress",
  //         integration.webhooks.onRequestAddress ?? ""
  //       );
  //       setValue(
  //         "webhooks.onRequestRedirect",
  //         integration.webhooks.onRequestRedirect ?? ""
  //       );
  //       snackbar.show("Integration updated!");
  //     },
  //     onError: () => snackbar.show("Integration failed to update!", "error"),
  //   }
  // );

  // const styles = makeStyles((theme: Theme) => {
  //   return {
  //     formField: {
  //       marginTop: theme.spacing(4),
  //     },
  //   };
  // })();

  // return (
  //   <>
  //     <Typography variant="h2">Webhooks</Typography>
  //     <form
  //       onSubmit={handleSubmit(async (data) =>
  //         updateIntegrationQuery.mutateAsync(emptyStringsToNulls(data))
  //       )}
  //     >
  //       <FormTextField
  //         control={control}
  //         errors={errors}
  //         name="webhooks.onTrackingEvent"
  //         label="Tracking Event"
  //         className={styles.formField}
  //       />
  //       <HelpOnTrackingEvent />
  //       <FormTextField
  //         control={control}
  //         errors={errors}
  //         name="webhooks.onRequestAddress"
  //         label="Address Request"
  //         className={styles.formField}
  //       />
  //       <HelpOnAddressRequest />
  //       <FormTextField
  //         control={control}
  //         errors={errors}
  //         name="webhooks.onRequestRedirect"
  //         label="Redirect Location Request"
  //         className={styles.formField}
  //       />
  //       <HelpOnRedirectRequest />
  //       <RButton
  //         type="submit"
  //         className={styles.formField}
  //         loading={updateIntegrationQuery.isLoading}
  //       >
  //         Save
  //       </RButton>
  //     </form>
  //   </>
  // );

  return null;
};

const OAuthIntegrationCard: FC<{ integration: OAuthIntegration }> = ({
  integration,
}) => {
  const classes = useStyles();
  return (
    <Card key={integration.id}>
      <CardHeader title={integration.name} className={classes.cardHeader} />
      {/* <CardContent className={classes.cardContent}>
        <Typography>Client ID: {integration.clientId}</Typography>
      </CardContent> */}
    </Card>
  );
};

const BasicIntegrationCard: FC<{ integration: BasicIntegration }> = ({
  integration,
}) => {
  const classes = useStyles();
  return (
    <Card key={integration.id}>
      <CardHeader title={integration.name} className={classes.cardHeader} />
      {/* <CardContent className={classes.cardContent}>
        <WebhooksForm integration={integration} />
      </CardContent> */}
    </Card>
  );
};

const IntegrationList: FC<{ integrations: Integration[] }> = ({
  integrations,
}) => {
  return (
    <>
      {integrations.map((integration) =>
        isOAuthIntegration(integration) ? (
          <OAuthIntegrationCard
            key={integration.id}
            integration={integration}
          />
        ) : (
          <BasicIntegrationCard
            key={integration.id}
            integration={{ ...integration }} // React Query data doesn't update as expected, so we copy the object to ensure useEffects fire
          />
        )
      )}
    </>
  );
};

export const Integrations: FC = () => {
  const integrations = useListIntegrations();

  if (integrations.isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Box
      my={4}
      gap={4}
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
    >
      {integrations.data?.integrations.length ? (
        <IntegrationList integrations={integrations.data.integrations} />
      ) : (
        <Typography>No integrations</Typography>
      )}
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  cardHeader: {},
  cardContent: { paddingTop: 0, paddingBottom: 16 },
}));
