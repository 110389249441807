import { makeStyles } from "@mui/styles";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { DispatchedRouteMap } from "../../components/DispatchedRouteMap";
import { ErrorScreen } from "../../components/ErrorScreen";
import { LoadingScreen } from "../../components/LoadingScreen";
import useShowRoute from "../../hooks/queries/useShowRoute";
import { useAuth } from "../../hooks/useAuth";
import { useLatestCoords } from "../../hooks/useLatestCoords";
import type {
  WebsocketPrivateResponse,
  WebsocketSubscriptionResponse,
} from "../../shared/api_schema";
import {
  useWebsocketPrivateSubscribeZ,
  useWebsocketSubscribeZ,
} from "../../shared/frontend";
import { RouteStopList } from "../routes/RouteStopList";

export const DispatchedRouteManager = () => {
  const { id } = useParams();
  const { currentUser } = useAuth();

  const { refetch: refetchRoute, ...routeQuery } = useShowRoute(id!);

  // Refetch the route if stops are added or removed
  const privateSubscriptionCallback = useCallback(
    (message: WebsocketPrivateResponse) => {
      switch (message.action) {
        case "route_stop_added":
        case "route_stop_removed":
          refetchRoute();
          break;
      }
    },
    [refetchRoute]
  );

  useWebsocketPrivateSubscribeZ(
    privateSubscriptionCallback,
    "DispatchedRouteManager"
  );

  // Refetch the route if route begins, ends, or stops are arrived or departed
  const subscriptionCallback = useCallback(
    (message: WebsocketSubscriptionResponse) => {
      switch (message.action) {
        case "position":
          useLatestCoords.getState().setLatestCoord(message.route.id, message);
          break;
        case "route_began":
        case "route_ended":
        case "route_stop_arrived":
        case "route_stop_departed":
          refetchRoute();
          break;
      }
    },
    [refetchRoute]
  );

  useWebsocketSubscribeZ(
    "route",
    currentUser?.activeOrganization.liveTracking
      ? routeQuery.data?.route.id
      : undefined,
    subscriptionCallback,
    "DispatchedRouteManager"
  );

  const styles = makeStyles({
    content: {
      height: "100vh",
      width: "100%",
      display: "flex",
      "@media print": {
        flexDirection: "column",
      },
    },
  })();

  if (routeQuery.isLoading) {
    return <LoadingScreen />;
  }

  if (routeQuery.isError) {
    return <ErrorScreen query={routeQuery} />;
  }

  const route = routeQuery.data!.route;

  return (
    <div className={styles.content}>
      <RouteStopList route={route} />
      <DispatchedRouteMap route={route} />
    </div>
  );
};
