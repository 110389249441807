import { Box, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { DateTime } from "luxon";
import type { FC } from "react";
import type { DeepRoute } from "../../shared/api_schema";
import { fullName, toLocale } from "../../shared/frontend";
import { RouteDropdownMenu } from "./RouteDropdownMenu";
import { RouteStateIcon } from "./RouteStateIcon";

export const RouteHeaderBlock: FC<{ route: DeepRoute }> = ({ route }) => {
  return (
    <Grid container wrap="nowrap">
      <Grid item style={{ flex: 1 }}>
        <Box display="flex">
          <Box mr={2} mt={2}>
            <RouteStateIcon route={route} />
          </Box>
          <Box>
            <Typography variant="h1">{route.zone.name}</Typography>
            <Typography variant="h2">
              {toLocale(
                route.begin.expected!,
                route.zone.timeZone,
                DateTime.DATE_MED
              )}
            </Typography>
            {route.driver && (
              <div
                style={{
                  color: grey[600],
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                {fullName(route.driver)}
              </div>
            )}
            <div style={{ color: grey[600], marginBottom: 10 }}>
              {route.vehicle.name}
            </div>
          </Box>
        </Box>
      </Grid>
      <Grid item>
        <RouteDropdownMenu route={route} />
      </Grid>
    </Grid>
  );
};
