import { Box, Dialog, DialogActions, DialogTitle } from "@mui/material";
import type { FC } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { RButton } from "../../components/RButton";
import { del } from "../../lib/amplify";
import type { DeepRoute } from "../../shared/api_schema";

export const DeleteRouteDialog: FC<{
  open: boolean;
  onClose: () => void;
  route: DeepRoute;
}> = ({ open, onClose, route }) => {
  const navigate = useNavigate();

  const deleteRouteMutation = useMutation(
    async () => await del(`/routes/${route.id}`),
    { onSuccess: () => navigate("/") }
  );

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!deleteRouteMutation.isLoading) {
          onClose();
        }
      }}
    >
      <DialogTitle>Delete Route?</DialogTitle>
      <Box m={2} mt={0}>
        <DialogActions>
          <RButton
            autoFocus
            onClick={onClose}
            disabled={deleteRouteMutation.isLoading}
          >
            Cancel
          </RButton>
          <RButton
            onClick={() => deleteRouteMutation.mutate()}
            color="secondary"
            loading={deleteRouteMutation.isLoading}
          >
            OK
          </RButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
