import AddCircle from "@mui/icons-material/AddCircle";
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import type { GridColumns } from "@mui/x-data-grid";
import type { FC } from "react";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Page } from "../../components/Page";
import type { PaginatedCustomModel } from "../../components/PaginatedCustomDataGrid";
import { PaginatedCustomDataGrid } from "../../components/PaginatedCustomDataGrid";
import { RDialog } from "../../components/RDialog";
import useUsersQuery, {
  getUsersQueryKey,
} from "../../hooks/queries/useUsersQuery";
import { useAuth } from "../../hooks/useAuth";
import { useSnackbar } from "../../hooks/useSnackbar";
import { del } from "../../lib/amplify";
import type { UserWithRole } from "../../shared/api_schema";
import { compareRole, UserRole } from "../../shared/api_schema";
import { userRoleString } from "../../shared/frontend";
import { InviteForm } from "./InviteForm";
import { UserForm } from "./UserForm";

const columns: GridColumns = [
  { field: "firstName", headerName: "First Name", flex: 1 },
  { field: "lastName", headerName: "Last Name", flex: 1 },
  {
    field: "role",
    headerName: "Role",
    flex: 1,
    valueFormatter: ({ value }) => userRoleString(value as string),
  },
  { field: "email", headerName: "Email", flex: 1 },
];

export const Users: FC = () => {
  const { currentUser } = useAuth();
  const usersQuery = useUsersQuery();

  const [addUserDialogOpen, setAddUserDialogOpen] = useState<boolean>(false);
  const [inviteUserDialogOpen, setInviteUserDialogOpen] =
    useState<boolean>(false);

  const [editingUser, setEditingUser] = useState<UserWithRole>();

  function handleInviteUser() {
    setEditingUser(undefined);
    setInviteUserDialogOpen(true);
  }

  const model: PaginatedCustomModel = {
    key: ["users", "other"], // Users that aren't me
    countPath: `/users?id~=${currentUser?.id}&count=true`,
    dataPath: (skip: number, take: number) =>
      `/users?id~=${currentUser?.id}&skip=${skip}&take=${take}`,
    responseKey: "users",
  };

  return (
    <Page>
      <Box marginY={4}>
        <Grid spacing={2} container justifyContent="flex-end">
          <Grid item>
            <ActionButtons inviteUserCallback={handleInviteUser} />
          </Grid>
        </Grid>
      </Box>

      <Box>
        <PaginatedCustomDataGrid
          model={model}
          columns={columns}
          onRowClick={(params) => {
            setEditingUser(params.row as UserWithRole);
            setAddUserDialogOpen(true);
          }}
        />
      </Box>

      <PendingInvites />

      <RDialog
        open={addUserDialogOpen}
        title={`${editingUser ? "Edit" : "Add"} User`}
        closeCallback={() => setAddUserDialogOpen(false)}
      >
        <UserForm
          user={editingUser}
          onSubmitSuccess={() => setAddUserDialogOpen(false)}
        />
      </RDialog>

      <RDialog
        open={inviteUserDialogOpen}
        title="Invite User"
        closeCallback={() => setInviteUserDialogOpen(false)}
      >
        <InviteForm onSubmitSuccess={() => setInviteUserDialogOpen(false)} />
      </RDialog>
    </Page>
  );
};

const PendingInvites = () => {
  const usersQuery = useUsersQuery();
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();

  const cancelInviteMutation = useMutation(
    async (inviteId: string) => del(`/users/invite/${inviteId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getUsersQueryKey());
        snackbar.show("Invitation has been cancelled");
      },
    }
  );

  function handleCancelInvite(inviteId: string) {
    cancelInviteMutation.mutate(inviteId);
  }

  const classes = makeStyles(() =>
    createStyles({
      th: {
        fontWeight: "bold",
      },
    })
  )();

  return (
    <Box marginY={10}>
      <Typography variant="h3">Pending Invites</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.th}>Email</TableCell>
              <TableCell className={classes.th}>Role</TableCell>
              <TableCell className={classes.th}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usersQuery.data &&
              usersQuery.data.invites.map((invite) => (
                <TableRow key={invite.email}>
                  <TableCell width="20%">{invite.email}</TableCell>
                  <TableCell width="60%">
                    {userRoleString(invite.role)}
                  </TableCell>
                  <TableCell width="20%">
                    <Button
                      variant="text"
                      onClick={() => handleCancelInvite(invite.id)}
                    >
                      Cancel Invite
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            {usersQuery.data && !usersQuery.data.invites.length && (
              <TableRow>
                <TableCell width="100%">No pending invites</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const ActionButtons = ({
  inviteUserCallback,
}: {
  inviteUserCallback: () => void;
}) => {
  return (
    <Box>
      <Button
        onClick={inviteUserCallback}
        variant="contained"
        color="primary"
        startIcon={<AddCircle />}
        style={{ marginRight: 16 }}
      >
        Invite User
      </Button>
    </Box>
  );
};

export function availableRoles(src: UserRole): UserRole[] {
  return [UserRole.ADMIN, UserRole.DISPATCHER, UserRole.DRIVER].filter(
    (dst) => compareRole(src, dst) >= 0
  );
}
