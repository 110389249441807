import polyline from "@mapbox/polyline";
import type { Feature, MultiPoint } from "geojson";
import type L from "leaflet";
import { MAX_ZOOM } from "../pages/dashboard/OverviewMap";
import type {
  Coord,
  DeepRoute,
  Route,
  Stop,
  Waypoint,
} from "../shared/api_schema";
import { Colors } from "../shared/frontend";

export function emptyMultiPointFeature(): Feature<MultiPoint> {
  return {
    type: "Feature",
    geometry: { type: "MultiPoint", coordinates: [] },
    properties: {},
  };
}

export function toMultiPointFeature(
  polylineString: string,
  precision: number
): Feature<MultiPoint> {
  const lineString = polyline.toGeoJSON(polylineString, precision);
  return {
    ...emptyMultiPointFeature(),
    geometry: { type: "MultiPoint", coordinates: lineString.coordinates },
  };
}

export enum FadeType {
  FORWARD,
  REVERSE,
}

export function lineStyle(
  color: { r: number; g: number; b: number },
  fade?: FadeType
) /*: LayerProps*/ {
  return {
    type: "line",
    paint: {
      "line-width": 3,
      ...(!fade && {
        "line-color": ["rgba", color.r, color.g, color.b, 1.0],
      }),
      ...(fade === FadeType.FORWARD && {
        "line-gradient": [
          "interpolate",
          ["linear"],
          ["line-progress"],
          0,
          ["rgba", color.r, color.g, color.b, 0.3],
          0.75,
          ["rgba", color.r, color.g, color.b, 0.3],
          1,
          ["rgba", color.r, color.g, color.b, 1],
        ],
      }),
      ...(fade === FadeType.REVERSE && {
        "line-gradient": [
          "interpolate",
          ["linear"],
          ["line-progress"],
          0,
          ["rgba", color.r, color.g, color.b, 1],
          0.25,
          ["rgba", color.r, color.g, color.b, 0.3],
          1,
          ["rgba", color.r, color.g, color.b, 0.3],
        ],
      }),
    },
    layout: {
      "line-join": "round",
    },
  };
}

export function pointStyle(color: {
  r: number;
  g: number;
  b: number;
}) /*: LayerProps*/ {
  return {
    type: "circle",
    paint: {
      "circle-radius": 5,
      "circle-color": ["rgb", color.r, color.g, color.b],
    },
  };
}

export function isStopArrived(stop: Stop): boolean {
  return !!stop.arrive.actual;
}

export function isWaypointArrived(waypoint: Waypoint): boolean {
  return !!waypoint.arrive?.actual;
}

export function isStopDeparted(stop: Stop): boolean {
  return !!stop.depart.actual;
}

export function isWaypointDeparted(waypoint: Waypoint): boolean {
  return !!waypoint.depart?.actual;
}

export function getWaypoints(route: DeepRoute): Waypoint[] {
  return [
    {
      type: "start",
      order: 0,
      location: route.start,
      arrive: undefined,
      depart: route.begin,
    },
    ...route.stops.map((stop) => {
      const waypoint: Waypoint = {
        type: "stop",
        ...stop,
      };
      return waypoint;
    }),
    {
      type: "finish",
      order: route.stops.length + 1,
      location: route.finish,
      arrive: route.end,
      depart: undefined,
    },
  ];
}

export function waypointColor(waypoint: Waypoint) {
  if (isWaypointDeparted(waypoint)) {
    return Colors.DARK_GREEN;
  } else if (waypoint.type === "finish" && isWaypointArrived(waypoint)) {
    return Colors.DARK_GREEN;
  } else if (isWaypointArrived(waypoint)) {
    return Colors.BRAND_GREEN;
  } else {
    return "black";
  }
}

export function getBounds(coords: Coord[]) {
  if (!coords.length) return undefined;

  const { north, south } = coords.reduce(
    (p, c) => ({
      north: Math.max(p.north, parseFloat(c.lat)),
      south: Math.min(p.south, parseFloat(c.lat)),
    }),
    { north: -Number.MAX_VALUE, south: +Number.MAX_VALUE }
  );

  const { east, west } = coords.reduce(
    (p, c) => ({
      east: Math.max(p.east, parseFloat(c.lon)),
      west: Math.min(p.west, parseFloat(c.lon)),
    }),
    { east: -Number.MAX_VALUE, west: +Number.MAX_VALUE }
  );

  return {
    east,
    north,
    south,
    west,
  };
}

export function fitBounds(coords: Coord[], map: L.Map) {
  const bounds = getBounds(coords);

  if (!bounds) {
    map.fitWorld();
    return;
  }

  // Points are sitting on top of each other (or there's only 1)
  // Center on it with a max zoom
  if (bounds.east === bounds.west || bounds.south === bounds.north) {
    map.setView([bounds.north, bounds.east], MAX_ZOOM);
  }
  // Actual bounds to fit to
  else {
    map.fitBounds(
      [
        [bounds.south, bounds.west],
        [bounds.north, bounds.east],
      ],
      {
        padding: [50, 50],
        maxZoom: MAX_ZOOM,
      }
    );
  }
}

export function routeHasBegun(route: Route): boolean {
  return !!route.begin.actual;
}

export function routeHasEnded(route: Route): boolean {
  return !!route.end.actual;
}
