import type { FC, ReactElement } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Layout } from "../components/Layout";
import { useAuth } from "../hooks/useAuth";
import { Onboarding } from "../pages/onboarding/Onboarding";

export const PrivateRoute: FC<{ layout?: boolean; element: ReactElement }> = ({
  layout = true,
  element,
}) => {
  const location = useLocation();
  const { currentUser, organizationSelectionRequired } = useAuth();
  if (currentUser?.hasActiveOrganization() && !organizationSelectionRequired) {
    return layout ? <Layout>{element}</Layout> : element;
  } else if (currentUser) {
    return <Onboarding />;
  } else {
    return <Navigate to="/" state={{ from: location }} />;
  }
};
