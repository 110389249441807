import { Button } from "@mui/material";
import type { MouseEvent } from "react";
import { useAuth } from "../hooks/useAuth";

export const SignOutButton = () => {
  const { logout } = useAuth();

  const handleSignOutClick = (event: MouseEvent) => {
    event.preventDefault();
    logout();
  };

  return (
    <Button color="primary" onClick={handleSignOutClick}>
      Sign Out
    </Button>
  );
};
