import { Grid, MenuItem } from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { FormSelect } from "../../components/forms/FormSelect";
import { FormTextField } from "../../components/forms/FormTextField";
import { RButton } from "../../components/RButton";
import { RFormButtons } from "../../components/RFormButtons";
import { getUsersQueryKey } from "../../hooks/queries/useUsersQuery";
import { useAuth } from "../../hooks/useAuth";
import { useSnackbar } from "../../hooks/useSnackbar";
import { post } from "../../lib/amplify";
import type { InviteRequest, ListUsersResponse } from "../../shared/api_schema";
import { UserRole } from "../../shared/api_schema";
import { EMAIL_REGEX, userRoleString } from "../../shared/frontend";
import { availableRoles } from "./Users";

export const InviteForm = ({
  onSubmitSuccess,
}: {
  onSubmitSuccess: () => void;
}) => {
  type InviteFormTypes = {
    email: string;
    role: UserRole;
  };

  const { currentUser } = useAuth();
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();

  const inviteMutation = useMutation(
    async (payload: InviteRequest) => post("/users/invite", payload),
    {
      onMutate: async (payload: InviteRequest) => {
        const previousUsers = queryClient.getQueryData<ListUsersResponse>(
          getUsersQueryKey()
        );

        if (previousUsers && currentUser!.activeOrganization) {
          queryClient.setQueryData<ListUsersResponse>(getUsersQueryKey(), {
            ...previousUsers,
            invites: [
              ...previousUsers.invites,
              {
                id: "x",
                email: payload.email,
                role: payload.role,
                organization: currentUser!.activeOrganization,
              },
            ],
          });
        }

        return { previousUsers };
      },
      onError: (_err, _variables, context) => {
        if (context?.previousUsers) {
          queryClient.setQueryData<ListUsersResponse>(
            getUsersQueryKey(),
            context.previousUsers
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(getUsersQueryKey());
      },
    }
  );

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<InviteFormTypes>({ defaultValues: { role: UserRole.DRIVER } });

  const formSubmit = async ({ email, role }: InviteFormTypes) => {
    try {
      await inviteMutation.mutate({ email, role });
      snackbar.show("User has been invited!");
      onSubmitSuccess();
    } catch (error) {
      snackbar.show("There was an error inviting this user", "error");
    }
  };

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(formSubmit)}
      style={{ margin: 2 }} // Required to eliminate scrollbar
    >
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <FormSelect
            control={control}
            errors={errors}
            name="role"
            label="Role"
            rules={{ required: "A role is required" }}
          >
            {availableRoles(
              currentUser!.activeOrganization.role as UserRole
            ).map((r) => (
              <MenuItem key={r} value={r}>
                {userRoleString(r)}
              </MenuItem>
            ))}
          </FormSelect>
        </Grid>

        <Grid item xs={6}>
          <FormTextField
            control={control}
            errors={errors}
            name="email"
            label="Email"
            rules={{
              required: "An email address is required",
              pattern: {
                value: EMAIL_REGEX,
                message: "The email address is invalid",
              },
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <RFormButtons>
            <RButton type="submit">Invite</RButton>
          </RFormButtons>
        </Grid>
      </Grid>
    </form>
  );
};
