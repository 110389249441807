import { Box, Grid, TextField } from "@mui/material";
import type { FC } from "react";
import { useEffect, useMemo, useRef } from "react";
import type { UseFormReturn } from "react-hook-form";
import { MapContainer, Marker } from "react-leaflet";
import { useGoogleMaps } from "../../hooks/useGoogleMaps";
import { useRegionOfInterest } from "../../hooks/useRegionOfInterest";
import { getBounds } from "../../lib/map_helpers";
import { MAX_ZOOM } from "../../pages/dashboard/OverviewMap";
import type { Location } from "../../shared/api_schema";
import { Colors } from "../../shared/frontend";
import { getTimeZoneCA } from "../../shared/lib";
import { BoundsFitter } from "../BoundsFitter";
import { MapTiles } from "../map/MapTiles";
import { errorObject } from "./form_helpers";

export const EMPTY_LOCATION: Location = {
  name: "",
  address: "",
  locality: "",
  region: "",
  postalcode: "",
  country: "",
  timeZone: "",
  coord: {
    lat: "",
    lon: "",
  },
};

export const LocationForm: FC<{
  parentForm: UseFormReturn<any>; // Parent form that's driving this subform
  locationKey?: string; // Form key to look for -- defaults to "location"
  disabled?: boolean; // If the parent form is using an existing customer, disable the entire form (but still show location)
}> = ({ locationKey = "location", ...props }) => {
  const places = useGoogleMaps((state) => state.places);
  const inputRef = useRef<HTMLInputElement>(null);
  const autoCompleteRef = useRef<google.maps.places.Autocomplete>();

  const location: Location = props.parentForm.watch(locationKey);

  const latKey = `${locationKey}.coord.lat`;
  const lonKey = `${locationKey}.coord.lon`;

  const locationError =
    !!errorObject(props.parentForm.formState.errors, latKey) ||
    !!errorObject(props.parentForm.formState.errors, lonKey);

  // Validate that we have a lat and lon present
  useEffect(() => {
    props.parentForm.register(latKey, { required: true });
    props.parentForm.register(lonKey, { required: true });
    return () => {
      props.parentForm.unregister(latKey);
      props.parentForm.unregister(lonKey);
    };
  }, [props.parentForm, latKey, lonKey]);

  // Listen to changes in ROI to update Google Places
  const coords = useRegionOfInterest((state) => state.coords);

  // Hook into Google Places Autocomplete
  useEffect(() => {
    if (!places || !inputRef.current) {
      return;
    }

    const bounds = getBounds(coords);
    console.log("Starting Autocomplete with bounds", bounds);

    autoCompleteRef.current = new places.Autocomplete(inputRef.current, {
      componentRestrictions: { country: "CA" },
      fields: ["address_components", "geometry", "name"],
      bounds,
    });

    const listener = autoCompleteRef.current.addListener(
      "place_changed",
      () => {
        const placeResult = autoCompleteRef.current!.getPlace();
        console.log(placeResult);

        const components = placeResult.address_components;

        // If you hit Enter without selecting a search option after typing
        if (!components) {
          return;
        }

        const unit = components.find((c) =>
          c.types.includes("subpremise")
        )?.short_name;
        const street_number = components.find((c) =>
          c.types.includes("street_number")
        )?.short_name;
        const street = components.find((c) =>
          c.types.includes("route")
        )?.short_name;
        const locality =
          components.find((c) => c.types.includes("locality"))?.short_name ??
          components.find((c) =>
            c.types.includes("administrative_area_level_3")
          )!.short_name;
        const region = components.find((c) =>
          c.types.includes("administrative_area_level_1")
        )!.short_name;
        const country = components.find((c) =>
          c.types.includes("country")
        )!.short_name;
        const postalcode = components.find((c) =>
          c.types.includes("postal_code")
        )!.short_name;
        const address = [street_number, street].filter((v) => v).join(" ");

        if (country !== "CA") {
          throw Error(
            "Unable to automatically determine timezone outside of Canada!"
          );
        }

        const newLocation: Location = {
          name: placeResult.name!,
          address: unit ? `${unit}-${address}` : address,
          locality,
          region,
          country,
          postalcode,
          timeZone: getTimeZoneCA(region),
          coord: {
            lat: placeResult.geometry!.location!.lat().toString(),
            lon: placeResult.geometry!.location!.lng().toString(),
          },
        };

        props.parentForm.setValue(locationKey, newLocation);
      }
    );
    return () => google.maps.event.removeListener(listener);
  }, [places, locationKey, props.parentForm, coords]);

  // When un-disabling (clearing a selected Customer)
  // reset the textfield input to blank for a new search
  useEffect(() => {
    if (!props.disabled) {
      inputRef.current!.value = "";
      inputRef.current!.focus();
    }
  }, [props.disabled]);

  const locationField = useMemo(() => {
    // When disabled, set the textfield to the location name (assuming one is provided)
    if (props.disabled) {
      return (
        <TextField label="Location" fullWidth value={location.name} disabled />
      );
    }
    // When enabled, we have a full location search (inputRef connects it all)
    else {
      return (
        <TextField
          label="Location Search"
          inputRef={inputRef}
          fullWidth
          autoFocus
          helperText={locationError ? "Please select a location" : undefined}
          error={locationError}
        />
      );
    }
  }, [props.disabled, location.name, locationError]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {locationField}
          </Grid>
          {/* <Grid item xs={12}>
            <TextField
              label="Name"
              value={location?.name ?? ""}
              disabled
              fullWidth
            />
          </Grid> */}
          <Grid item xs={12}>
            <TextField
              label="Address"
              value={location?.address ?? ""}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Locality"
              value={location?.locality ?? ""}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Region"
              value={location?.region ?? ""}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Postal Code"
              value={location?.postalcode ?? ""}
              disabled
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Box
          sx={{
            border: "1px solid",
            borderColor: Colors.GREY_SMOKE,
            borderRadius: "4px",
            padding: "1px",
            height: "100%",
          }}
        >
          <MapContainer maxZoom={MAX_ZOOM} style={{ height: "100%" }}>
            <MapTiles />
            {location.coord.lat && location.coord.lon && (
              <>
                <BoundsFitter coords={[location.coord]} />
                <Marker
                  position={{
                    lat: parseFloat(location.coord.lat),
                    lng: parseFloat(location.coord.lon),
                  }}
                />
              </>
            )}
          </MapContainer>
        </Box>
      </Grid>
    </Grid>
  );
};
