import {
  Box,
  Button,
  Divider,
  SxProps,
  TextFieldProps,
  Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingScreen } from "../../components/LoadingScreen";
import { TimeSwap } from "../../components/TimeSwap";
import { useRelevantRoutes } from "../../hooks/queries/useRelevantRoutes";
import { useZonesRequiringRoute } from "../../hooks/queries/useZonesRequiringRoute";
import { useDateContext } from "../../hooks/useDateContext";
import {
  RelevantRoutesResponse,
  Route,
  User,
  ZonesRequiringRouteResponse,
} from "../../shared/api_schema";
import { Colors, fullName, pluralize } from "../../shared/frontend";
import { OverviewMap } from "./OverviewMap";

// A "date picker" that's just an icon
const IconDatePicker: FC<{ displayDate: string } & TextFieldProps> = ({
  displayDate,
  inputRef,
  inputProps,
  InputProps,
}) => {
  return (
    <Box display="flex" alignItems="center" gap={4}>
      <div style={{ width: 0, overflow: "hidden" }}>
        <input ref={inputRef} {...inputProps} />
      </div>
      <div style={{ marginLeft: -32 }}>{InputProps?.endAdornment}</div>
      <Typography variant="h2">{displayDate}</Typography>
    </Box>
  );
};

function Sidebar(props: {
  zonesRequiringRoute: ZonesRequiringRouteResponse;
  relevantRoutes: RelevantRoutesResponse;
  setHighlightedRoute: (route: Route | undefined) => void;
}) {
  const navigate = useNavigate();

  function highlightRoute(
    target: HTMLDivElement,
    route: Route,
    highlight: boolean
  ) {
    target.style.backgroundColor = highlight ? Colors.GOLD_88 : "white";
    props.setHighlightedRoute(highlight ? route : undefined);
  }

  const { dateContext, setDateContext, isPast } = useDateContext();

  const BUTTON_STYLES: SxProps = {
    flexShrink: 0,
    marginLeft: 4,
    width: 90,
  };

  return (
    <Box
      width={350}
      zIndex={1}
      sx={{ overflowY: "auto", boxShadow: "10px 0px 5px rgba(0,0,0,.1)" }}
    >
      <Box padding={4} paddingBottom={0}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DatePicker
            value={DateTime.fromISO(dateContext)}
            onChange={(value: DateTime | null) =>
              value
                ? setDateContext(value.toISODate())
                : DateTime.now().toISODate()
            }
            renderInput={(props) => (
              <IconDatePicker
                displayDate={DateTime.fromISO(dateContext).toFormat(
                  "EEEE MMMM d"
                )}
                {...props}
              />
            )}
            disableMaskedInput
          />
        </LocalizationProvider>
      </Box>
      {props.zonesRequiringRoute.zones.map((z) => {
        const totalAppointments =
          z.appointments.length + z.scheduledAppointments.length;

        const routesRequiringDispatch = props.relevantRoutes.routes.filter(
          (r) => r.zone.id === z.id && !r.driver?.id
        );
        const dispatchedRoutes = props.relevantRoutes.routes.filter(
          (r) => r.zone.id === z.id && r.driver?.id
        );

        return (
          <Box key={z.id}>
            <Box paddingX={4} paddingTop={8} paddingBottom={4}>
              <Typography fontWeight="bold">{z.name}</Typography>
            </Box>
            <Divider />
            <Box>
              {/* Build new route */}
              <Box
                display="flex"
                alignItems="center"
                paddingX={4}
                paddingY={3}
                // onMouseEnter={(ev) => highlight(ev.currentTarget, true)}
                // onMouseLeave={(ev) => highlight(ev.currentTarget, false)}
              >
                <Box flexGrow={1}>
                  <Typography>
                    {`${totalAppointments} ${pluralize(
                      "Appointment",
                      totalAppointments
                    )}`}
                  </Typography>
                </Box>
                <Button
                  onClick={() =>
                    navigate(`/zones/${z.id}/routes/${dateContext}`)
                  }
                  sx={BUTTON_STYLES}
                  disabled={isPast()}
                >
                  Build
                </Button>
              </Box>

              {/* Routes requiring dispatch */}
              {routesRequiringDispatch.map((r) => (
                <Box
                  key={r.id}
                  display="flex"
                  alignItems="center"
                  paddingX={4}
                  paddingY={3}
                  onMouseEnter={(ev) =>
                    highlightRoute(ev.currentTarget, r, true)
                  }
                  onMouseLeave={(ev) =>
                    highlightRoute(ev.currentTarget, r, false)
                  }
                >
                  <Tooltip
                    title={
                      <Typography variant="body2">
                        {r.stops.length} {pluralize("stop", r.stops.length)}
                      </Typography>
                    }
                    placement="top-start"
                    enterDelay={0}
                    arrow
                  >
                    <Box flexGrow={1}>
                      <Typography>{r.vehicle.name}</Typography>
                      <Box sx={{ opacity: 0.6 }}>
                        <TimeSwap pair={r.begin} tz={r.start.timeZone} /> -{" "}
                        <TimeSwap pair={r.end} tz={r.start.timeZone} />
                      </Box>
                    </Box>
                  </Tooltip>
                  <Button
                    onClick={() => navigate(`/routes/${r.id}/dispatch`)}
                    sx={BUTTON_STYLES}
                    disabled={isPast()}
                  >
                    Dispatch
                  </Button>
                </Box>
              ))}

              {/* Dispatched Routes */}
              {dispatchedRoutes.map((r) => (
                <Box
                  key={r.id}
                  display="flex"
                  alignItems="center"
                  paddingX={4}
                  paddingY={3}
                  onMouseEnter={(ev) =>
                    highlightRoute(ev.currentTarget, r, true)
                  }
                  onMouseLeave={(ev) =>
                    highlightRoute(ev.currentTarget, r, false)
                  }
                >
                  <Tooltip
                    title={
                      <>
                        <Typography variant="body2">
                          {fullName(r.driver as User)}
                        </Typography>
                        <Typography variant="body2">
                          {r.stops.length} {pluralize("stop", r.stops.length)}
                        </Typography>
                      </>
                    }
                    placement="top-start"
                    enterDelay={0}
                    arrow
                  >
                    <Box flexGrow={1}>
                      <Typography>{r.vehicle.name}</Typography>
                      <Box sx={{ opacity: 0.6 }}>
                        <TimeSwap pair={r.begin} tz={r.start.timeZone} /> -{" "}
                        <TimeSwap pair={r.end} tz={r.start.timeZone} />
                      </Box>
                    </Box>
                  </Tooltip>
                  <Button
                    onClick={() => navigate(`/routes/${r.id}`)}
                    sx={BUTTON_STYLES}
                  >
                    {isPast() ? "View" : "Manage"}
                  </Button>
                </Box>
              ))}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}

export function Dashboard() {
  const dateContext = useDateContext((state) => state.dateContext);
  const zonesRequiringRoute = useZonesRequiringRoute(dateContext);
  const relevantRoutes = useRelevantRoutes(dateContext);

  const isLoading = zonesRequiringRoute.isLoading || relevantRoutes.isLoading;

  const [highlightedRoute, setHighlightedRoute] = useState<Route>();

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Box sx={{ height: "100vh", display: "flex" }}>
      <Sidebar
        zonesRequiringRoute={zonesRequiringRoute.data!}
        relevantRoutes={relevantRoutes.data!}
        setHighlightedRoute={setHighlightedRoute}
      />
      <OverviewMap
        zonesRequiringRoute={zonesRequiringRoute.data!}
        relevantRoutes={relevantRoutes.data!}
        highlightedRoute={highlightedRoute}
      />
    </Box>
  );
}
