import { Alert, Box, CircularProgress, Grid, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import axios from "axios";
import { DateTime } from "luxon";
import { FC, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { create } from "zustand";
import { BrandedCenteredBox } from "../../components/BrandedCenteredBox";
import { RButton } from "../../components/RButton";
import { FormTextField } from "../../components/forms/FormTextField";
import { MobileLink } from "../../components/forms/MobileLink";
import config from "../../config";
import { useAuth } from "../../hooks/useAuth";
import { useInvitation } from "../../hooks/useInvitation";
import { useOAuth } from "../../hooks/useOAuth";
import { useFullQueryString, useQueryString } from "../../hooks/useQueryString";
import { get, post } from "../../lib/amplify";
import type {
  AccountDefaultParams,
  ConfirmRequest,
  ValidateResponse,
} from "../../shared/api_schema";
import { PASSWORD_REGEX } from "../../shared/frontend";
import { InviteBanner } from "./InviteBanner";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      maxWidth: 500,
      padding: theme.spacing(6),
      marginTop: theme.spacing(12),
      border: "1px solid black",
    },
    formRow: {
      padding: theme.spacing(2),
    },
  })
);

type SignUpWizardStore = {
  accountDefaults?: AccountDefaultParams;
  setAccountDefaults: (accountDefaults: AccountDefaultParams) => void;
};

export const useSignUpWizardStore = create<SignUpWizardStore>((set) => ({
  accountDefaults: undefined,
  setAccountDefaults: (accountDefaults) =>
    set({ ...accountDefaults, accountDefaults }),
}));

export const ConfirmEmail: FC = () => {
  const classes = useStyles();
  const { login, organizationSelectionRequired } = useAuth();
  const oauth = useOAuth();
  const navigate = useNavigate();
  const [confirmError, setConfirmError] = useState<string>();
  const queryParams = useQueryString();
  const fullQueryString = useFullQueryString();
  const token = queryParams.get("token")!;
  const { inviteIdQueryString } = useInvitation();
  const { setAccountDefaults } = useSignUpWizardStore();

  // Pre-validate the token to retrieve the relevant email address and account defaults
  // Note: account defaults would have been passed to the sign up page as query params
  const validateQuery = useQuery<ValidateResponse>("validate", async () =>
    get(`/validate/${token}`)
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
    setValue,
  } = useForm<ConfirmRequest>({
    defaultValues: {
      token,
      clientId: config.cognito.APP_CLIENT_ID,
      firstName: "",
      lastName: "",
      password: "",
      timeZone: DateTime.now().zoneName,
    },
  });

  useEffect(() => {
    if (validateQuery.data) {
      // Any account defaults that had been provided with the sign up will be stored with the
      // confirmation token and then retrieved here
      const accountDefaults = validateQuery.data.accountDefaults;

      if (accountDefaults) {
        // If we find account defaults, put them in the store to use for through the onboarding screens
        // Set the first/last name defaults directly into the form here if we have those
        setAccountDefaults(accountDefaults);
        if (accountDefaults?.firstName) {
          setValue("firstName", accountDefaults?.firstName);
        }
        if (accountDefaults?.lastName) {
          setValue("lastName", accountDefaults?.lastName);
        }
      }
    }
  }, [validateQuery.data, setAccountDefaults, setValue]);

  const confirmQuery = useMutation(async (data: ConfirmRequest) =>
    post("/confirm", data)
  );

  const formSubmit = async (data: ConfirmRequest) => {
    try {
      await confirmQuery.mutateAsync(data);

      await login({
        credentials: {
          email: validateQuery.data!.email,
          password: getValues("password"),
        },
        requireOrganizationSelection: false,
      });

      if (oauth.isOAuthFlow) {
        oauth.setCreds({
          email: validateQuery.data!.email,
          password: getValues("password"),
        });
        navigate(`/onboarding${fullQueryString}`);
      } else {
        navigate(`/${fullQueryString}`);
      }
    } catch (error) {
      console.error(error);
      const baseError = "There was an issue with the sign up process. ";
      if (axios.isAxiosError(error)) {
        setConfirmError(baseError + (error.response?.data as any).message);
      } else {
        setConfirmError(baseError + "Please try again.");
      }
    }
  };

  // Deep-link into mobile if they're confirming on a mobile device
  if (isMobile) {
    return <MobileLink fullPage path={`confirm?${queryParams.toString()}`} />;
  }

  return (
    <BrandedCenteredBox>
      {inviteIdQueryString ? (
        <InviteBanner message="Please sign up to continue." />
      ) : (
        <Box my={4}>
          <Alert severity={confirmError ? "error" : "info"}>
            {confirmError ??
              "Thank you for confirming your email address! Please complete the form to finish the sign up process."}
          </Alert>
        </Box>
      )}

      {validateQuery.isLoading && (
        <Box my={4} textAlign={"center"}>
          <CircularProgress />
        </Box>
      )}

      <form noValidate autoComplete="off" onSubmit={handleSubmit(formSubmit)}>
        <Grid container>
          <Grid item className={classes.formRow} xs={6}>
            <FormTextField
              control={control}
              errors={errors}
              name="firstName"
              label="First Name"
              disabled={
                organizationSelectionRequired || validateQuery.isLoading
              }
              rules={{ required: "A first name is required" }}
            />
          </Grid>
          <Grid item className={classes.formRow} xs={6}>
            <FormTextField
              control={control}
              errors={errors}
              name="lastName"
              label="Last Name"
              disabled={
                organizationSelectionRequired || validateQuery.isLoading
              }
              rules={{ required: "A last name is required" }}
            />
          </Grid>
          <Grid item className={classes.formRow} xs={12}>
            <FormTextField
              control={control}
              errors={errors}
              name="password"
              label="Password"
              type="password"
              disabled={
                organizationSelectionRequired || validateQuery.isLoading
              }
              rules={{
                required: "A password is required",
                pattern: {
                  value: PASSWORD_REGEX,
                  message:
                    "Password must be at least 8 characters and contain a lowercase letter, uppercase letter, number, and special character.",
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box mt={6}>
              <RButton
                color="primary"
                fullWidth
                type="submit"
                disabled={
                  organizationSelectionRequired || validateQuery.isLoading
                }
                loading={isSubmitting}
              >
                Sign Up
              </RButton>
            </Box>
          </Grid>
        </Grid>
      </form>
    </BrandedCenteredBox>
  );
};
