import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  UseControllerProps,
} from "react-hook-form";
import { TimezonePicker } from "../TimezonePicker";
import { errorObject } from "./form_helpers";

export function FormTimezonePicker<
  T extends FieldValues,
  N extends Path<T>
>(props: {
  name: N;
  control: Control<T>;
  errors: FieldErrors<T>;
  rules?: UseControllerProps<T, N>["rules"];
  disabled?: boolean;
}) {
  return (
    <Controller
      control={props.control}
      name={props.name}
      rules={props.rules}
      render={({ field }) => {
        return (
          <TimezonePicker
            error={!!errorObject(props.errors, props.name)}
            helperText={
              errorObject(props.errors, props.name)?.message as string
            }
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            disabled={props.disabled}
          />
        );
      }}
    />
  );
}
