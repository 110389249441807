import { useQuery } from "react-query";
import { get } from "../../lib/amplify";
import type { ShowStopResponse } from "../../shared/api_schema";

export function getStopQueryKey(stopId?: string) {
  return ["stop", stopId];
}

export function useShowStop(stopId: string | undefined) {
  return useQuery(
    getStopQueryKey(stopId),
    async () => get<ShowStopResponse>(`/stops/${stopId}`),
    {
      enabled: !!stopId,
    }
  );
}
