import axios from "axios";
import { useQuery } from "react-query";
import config from "../../config";
import type { OauthAppDetailsResponse } from "../../shared/api_schema";

async function fetchOAuthAppDetails(
  clientId: string | null
): Promise<OauthAppDetailsResponse> {
  const { data } = await axios.get<OauthAppDetailsResponse>(
    `${config.api.URL}/oauth/app/${clientId}`
  );
  return data;
}

export function useOauthAppDetailsQuery(clientId: string | null) {
  return useQuery<OauthAppDetailsResponse>(
    "key",
    async () => fetchOAuthAppDetails(clientId),
    { enabled: !!clientId }
  );
}
