import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { get } from "../../lib/amplify";
import type { ListUsersResponse } from "../../shared/api_schema";
import { fullName } from "../../shared/frontend";

export const DriverPicker: FC<{
  onChange: (driverId: string) => void;
  initialDriverId?: string;
}> = ({ onChange, initialDriverId }) => {
  const [selectedDriverId, setSelectedDriverId] = useState(
    initialDriverId ?? ""
  );

  const driversQuery = useQuery(
    "users",
    async () => get<ListUsersResponse>("/users"),
    {
      onSuccess: (data) => {
        if (!selectedDriverId && data.users.length) {
          setSelectedDriverId(data.users[0].id);
        }
      },
    }
  );

  // Tell parent when our value changes
  useEffect(() => onChange(selectedDriverId), [onChange, selectedDriverId]);

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>Driver</InputLabel>
      <Select
        value={selectedDriverId}
        onChange={(ev) => setSelectedDriverId(ev.target.value)}
        label="Driver"
      >
        {driversQuery.data ? (
          driversQuery.data.users.map((d) => (
            <MenuItem key={d.id} value={d.id}>
              {fullName(d)}
            </MenuItem>
          ))
        ) : (
          <MenuItem>
            <CircularProgress size={20} />
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};
