import type { ReactNode } from "react";
import { Component } from "react";
import { isPaymentRequiredError, PaymentRequired } from "./PaymentRequired";

interface Props {
  children: ReactNode;
}

interface State {
  error: Error | undefined;
}

// This ErrorBoundary is important to authentication functions
// If a "NoActiveOrganization" error is thrown this boundary will catch it and re-render the
// tree from React Router down
// This ensures a render pass happens through router and <PrivateRoute /> allowing the user
// to be placed where they are needed (authenticated, unauthenticated, authenticated + no org)
class RouterErrorBoundary extends Component<Props, State> {
  public state: State = {
    error: undefined,
  };

  public static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  public render() {
    if (isPaymentRequiredError(this.state.error)) {
      return <PaymentRequired />;
    }

    // All errors caught by this boundary will currently re-render the component tree
    // An alternative would to do this for certain errors (ex. "NoActiveOrganization")
    // and render a <ErrorPage /> for others
    return this.props.children;
  }
}

export default RouterErrorBoundary;
