import { Box, Button, Typography } from "@mui/material";
import type { ReactNode } from "react";
import { useRef } from "react";

export const MobileLink = ({
  path,
  message,
  buttonText,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  autoFollow = false,
  fullPage = false,
}: {
  path: string;
  message?: string;
  buttonText?: string;
  autoFollow?: boolean;
  fullPage?: boolean;
}) => {
  function handleButtonClick() {
    window.location.href = `routes://${path}`;
  }

  const buttonRef = useRef<HTMLButtonElement>(null);

  // It seems that newer versions of Chrome on Android won't allow us to auto redirect to a custom
  // URI scheme like "routes://". It is said that Chrome tries to make sure this was the result of
  // a user action, like clicking a link. At some point setting window.location.href, or programtically
  // clicking the link for the user (show below) worked, but doesn't seem to now.

  // We can loop back to this an assess the state of this mobile browser security at some point.
  // For now the user will have to click a link.

  // useEffect(() => {
  //   if (autoFollow && buttonRef.current) {
  //     buttonRef.current.click();
  //   }
  // }, [autoFollow]);

  return (
    <FullPage condition={fullPage}>
      <Box marginBottom={4}>
        <Typography>
          {message ? message : "Continue in mobile app to proceed"}
        </Typography>
      </Box>
      <Button onClick={handleButtonClick} ref={buttonRef}>
        {buttonText ? buttonText : "Go to the Routes.io app"}
      </Button>
    </FullPage>
  );
};

const FullPage = ({
  condition,
  children,
}: {
  condition: boolean;
  children: ReactNode;
}) => {
  return condition ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      height="80vh"
    >
      <Box>{children}</Box>
    </Box>
  ) : (
    <>{children}</>
  );
};
