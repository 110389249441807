import { Snackbar } from "@mui/material";
import type { AlertColor } from "@mui/material/Alert";
import Alert from "@mui/material/Alert";
import type { FC, ReactNode } from "react";
import { createContext, useContext, useState } from "react";

export interface SnackbarProps {
  show: (message: string, severity?: AlertColor) => void;
}

const SnackbarContext = createContext<SnackbarProps>({} as SnackbarProps);

export const SnackbarProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [severity, setSeverity] = useState<AlertColor>("success");
  const [value] = useState<SnackbarProps>({
    show: (message: string, severity: AlertColor = "success") => {
      setMessage(message);
      setSeverity(severity);
      setOpen(true);
    },
  });

  return (
    <SnackbarContext.Provider value={value}>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={severity}>{message}</Alert>
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  );
};

export function useSnackbar() {
  return useContext(SnackbarContext);
}

export function errorSnackbar(err: any, snackbar: SnackbarProps) {
  console.error(err);
  if (err.response?.data?.message) {
    snackbar.show(err.response.data.message, "error");
  } else {
    snackbar.show("Something went wrong", "error");
  }
}
