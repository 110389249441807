import { useEffect } from "react";
import { useMap } from "react-leaflet";
import { useRegionOfInterest } from "../hooks/useRegionOfInterest";
import { fitBounds } from "../lib/map_helpers";

export function RegionOfInterestFitter() {
  const map = useMap();
  const coords = useRegionOfInterest((state) => state.coords);
  useEffect(() => fitBounds(coords, map), [coords, map]);
  return null;
}
