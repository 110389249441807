import LocalShipping from "@mui/icons-material/LocalShipping";
import { divIcon } from "leaflet";
import { renderToString } from "react-dom/server";
import { Colors } from "../../shared/frontend";

export const VehicleIcon = divIcon({
  iconSize: [24, 24],
  html: renderToString(<LocalShipping style={{ color: Colors.BLUE }} />),
  className: undefined,
});
