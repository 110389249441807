import {
  Alert,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import pluralize from "pluralize";
import { FC, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { RButton } from "../../components/RButton";
import { RDialog } from "../../components/RDialog";
import { RFormButtons } from "../../components/RFormButtons";
import { errorSnackbar, useSnackbar } from "../../hooks/useSnackbar";
import { post, put } from "../../lib/amplify";
import type {
  AddRouteStopResponse,
  EstimateRoutesResponse,
  OptimizationOptions,
  RemoveRouteStopResponse,
  Stop,
  UpdateRouteRequest,
  UpdateRouteResponse,
} from "../../shared/api_schema";
import { toDistance, toDurationShort, toLocale } from "../../shared/frontend";
import { routeDuration, routeTotalService } from "./RouteStopList";

function UnassignedStopDetails({
  unassignedStops,
}: {
  unassignedStops: Array<Stop>;
}) {
  return (
    <Box bgcolor="rgb(255, 244, 229)" padding={4}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>Open</TableCell>
            <TableCell>Close</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {unassignedStops.map((unassignedStop) => (
            <TableRow key={unassignedStop.id}>
              <TableCell>{unassignedStop.location.name}</TableCell>
              <TableCell>{unassignedStop.location.address}</TableCell>
              <TableCell>
                {toLocale(
                  unassignedStop.open,
                  unassignedStop.location.timeZone
                )}
              </TableCell>
              <TableCell>
                {toLocale(
                  unassignedStop.close,
                  unassignedStop.location.timeZone
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

const OptionLine: FC<{ text: string }> = ({ text }) => {
  return (
    <Typography sx={{ fontSize: "smaller", fontWeight: "bold", marginLeft: 2 }}>
      {text}
    </Typography>
  );
};

const RouteOptions: FC<{ options: OptimizationOptions }> = ({ options }) => {
  return (
    <Box sx={{ marginY: 4 }}>
      <Typography>Options</Typography>
      {options.vehicleOpenOverride && (
        <OptionLine text={`Vehicle Open: ${options.vehicleOpenOverride}`} />
      )}
      {options.vehicleCloseOverride && (
        <OptionLine text={`Vehicle Close: ${options.vehicleCloseOverride}`} />
      )}
      {options.serviceTimeOverride && (
        <OptionLine text={`Service Time: ${options.serviceTimeOverride}s`} />
      )}
      {options.forceAllVehicles && <OptionLine text="Force All Vehicles" />}
      {options.deliveriesBeforePickups && (
        <OptionLine text="Deliveries Before Pickups" />
      )}
    </Box>
  );
};

export const EstimatedRoutesDialog: FC<{
  estimatedRoutes:
    | EstimateRoutesResponse
    | AddRouteStopResponse
    | RemoveRouteStopResponse;
  skippedStops?: Array<Stop>;
  deletedStops?: Array<Stop>;
  onClose: () => void;
  onConfirm: () => void;
}> = ({ estimatedRoutes, skippedStops, deletedStops, onClose, onConfirm }) => {
  const { routes, unassignedStops } = estimatedRoutes;

  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const [viewDetails, setViewDetails] = useState(false);

  // Used for the initial route optimization/creation
  const createRoutesMutation = useMutation(
    async (estimatedRoutes: EstimateRoutesResponse) =>
      Promise.all(estimatedRoutes.routes.map((r) => post("/routes", r))),
    {
      onSuccess: () => onConfirm(),
      onError: (err: any) => errorSnackbar(err, snackbar),
    }
  );

  // Used when adding/removing stops to an existing route
  const updateRouteMutation = useMutation(
    async (
      estimatedRoutes:
        | EstimateRoutesResponse
        | AddRouteStopResponse
        | RemoveRouteStopResponse
    ) => {
      const route = estimatedRoutes.routes[0];
      return put<UpdateRouteResponse, UpdateRouteRequest>(
        `/routes/${route.id}`,
        {
          route,
          ...("deletedStops" in estimatedRoutes && {
            deletedStops: estimatedRoutes.deletedStops,
          }),
        }
      );
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(["route", response.route.id]);
        queryClient.invalidateQueries(["route_details", response.route.id]);
        queryClient.invalidateQueries([
          "zone_appointments",
          response.route.zone.id,
        ]);
        onConfirm();
      },
      onError: (err: any) => errorSnackbar(err, snackbar),
    }
  );

  function handleViewDetails() {
    setViewDetails((s) => !s);
  }

  function handleClose() {
    setViewDetails(false);
    onClose();
  }

  if (routes === undefined) {
    return null;
  }

  return (
    <RDialog open={true} title="Route Estimate" closeCallback={handleClose}>
      {routes.length === 0 && (
        <Alert severity="error">
          <Typography variant="body2">
            No routes created during optimization!
          </Typography>
        </Alert>
      )}

      {routes.length > 0 && (
        <Alert severity="success">
          <Typography variant="body2">
            {`${routes.length} ${pluralize(
              "routes",
              routes.length
            )} optimized!`}
          </Typography>
        </Alert>
      )}

      {skippedStops !== undefined && skippedStops.length > 0 && (
        <Alert severity="info">
          <Typography variant="body2">
            {`${skippedStops.length} ${pluralize(
              "stop",
              skippedStops.length
            )} will be skipped`}
          </Typography>
        </Alert>
      )}

      {deletedStops !== undefined && deletedStops.length > 0 && (
        <Alert severity="error">
          <Typography variant="body2">
            {`${deletedStops.length} ${pluralize(
              "stop",
              deletedStops.length
            )} will be deleted`}
          </Typography>
        </Alert>
      )}

      {unassignedStops.length > 0 && (
        <Alert
          severity="warning"
          action={
            <Button
              variant="text"
              size="small"
              color="inherit"
              onClick={handleViewDetails}
            >
              {viewDetails ? "Hide Details" : "View Details"}
            </Button>
          }
        >
          <Typography variant="body2">
            {`${unassignedStops.length} unassigned ${pluralize(
              "stop",
              unassignedStops.length
            )}`}
          </Typography>
        </Alert>
      )}

      {viewDetails && (
        <UnassignedStopDetails unassignedStops={unassignedStops} />
      )}

      {routes.length > 0 && routes[0].options && (
        <RouteOptions options={routes[0].options} />
      )}

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Vehicle</TableCell>
            <TableCell>Stops</TableCell>
            <TableCell>Distance</TableCell>
            <TableCell>Driving</TableCell>
            <TableCell>Service</TableCell>
            <TableCell>Total</TableCell>
            <TableCell>Begin</TableCell>
            <TableCell>End</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {routes.map((route) => {
            return (
              <TableRow key={route.vehicle.id}>
                <TableCell>{route.vehicle.name}</TableCell>
                <TableCell>{route.stops.length}</TableCell>
                <TableCell>{toDistance(route.distance.expected!)}</TableCell>
                <TableCell>
                  {toDurationShort(
                    routeDuration(route, "expected")! - routeTotalService(route)
                  )}
                </TableCell>
                <TableCell>
                  {toDurationShort(routeTotalService(route))}
                </TableCell>
                <TableCell>
                  {toDurationShort(routeDuration(route, "expected")!)}
                </TableCell>
                <TableCell>
                  {toLocale(route.begin.expected!, route.zone.timeZone)}
                </TableCell>
                <TableCell>
                  {toLocale(route.end.expected!, route.zone.timeZone)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <RFormButtons>
        {routes.length > 0 ? (
          <RButton
            onClick={() => {
              // If there's just one Route and it already has an ID, do an update
              if (routes.length === 1 && routes[0].id) {
                updateRouteMutation.mutate(estimatedRoutes);
              }
              // Otherwise, it must be a create
              else {
                createRoutesMutation.mutate(estimatedRoutes);
              }
            }}
            loading={
              createRoutesMutation.isLoading || updateRouteMutation.isLoading
            }
          >
            Confirm
          </RButton>
        ) : (
          <RButton onClick={onClose}>Close</RButton>
        )}
      </RFormButtons>
    </RDialog>
  );
};
