import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import type { FC } from "react";
import { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorScreen } from "../../components/ErrorScreen";
import { LoadingScreen } from "../../components/LoadingScreen";
import { Page } from "../../components/Page";
import { RButton } from "../../components/RButton";
import useShowRoute from "../../hooks/queries/useShowRoute";
import { post } from "../../lib/amplify";
import type { DispatchRouteRequest } from "../../shared/api_schema";
import { routeTitle } from "../../shared/frontend";
import { DriverPicker } from "./DriverPicker";
import { RouteDisplay } from "./RouteDisplay";

const useStyles = makeStyles(() => ({
  borderBottom: {
    borderBottom: "1px solid #999",
  },
}));

export const RouteDispatcher: FC = () => {
  const { id } = useParams();

  const [selectedDriverId, setSelectedDriverId] = useState("");

  const routeQuery = useShowRoute(id!);

  const navigate = useNavigate();

  const classes = useStyles();

  const dispatchRouteMutation = useMutation(
    async (driverId: string) => {
      const request: DispatchRouteRequest = {
        driver: { id: driverId },
      };
      await post(`/routes/${id}/dispatch`, request);
    },
    { onSuccess: () => navigate("/") }
  );

  if (routeQuery.isLoading) {
    return <LoadingScreen />;
  }

  if (routeQuery.isError) {
    return <ErrorScreen query={routeQuery} />;
  }

  const route = routeQuery.data!.route;

  return (
    <Box display="flex" flexDirection="column" height="100vh">
      <Box className={classes.borderBottom}>
        <Page title="Dispatch" subTitle={routeTitle(route)}>
          <Box my={4}>
            <Grid container alignItems="center" spacing={5}>
              <Grid item md={3}>
                <DriverPicker onChange={setSelectedDriverId} />
              </Grid>
              <Grid item>
                <RButton
                  onClick={() => dispatchRouteMutation.mutate(selectedDriverId)}
                  loading={dispatchRouteMutation.isLoading}
                  size="large"
                  color="primary"
                  type="submit"
                >
                  Dispatch
                </RButton>
              </Grid>
            </Grid>
          </Box>
        </Page>
      </Box>
      <RouteDisplay route={route} />
    </Box>
  );
};
