// A full ISO time

import type { DateTime } from "luxon";

// "2021-10-02T14:23:15-07:00"
export type AbsoluteTime = string;

// A 24hr clock time
// "18:00"
export type Clock24hrTime = string;

export function is24HrTime(time: string): boolean {
  const [HH, mm] = hrsAndMins(time);
  return HH >= 0 && HH < 24 && mm >= 0 && mm < 60;
}

type HrsAndMins = [number, number];

export const hrsAndMins = (time: string): HrsAndMins => {
  const [HH, mm] = time.split(":");
  return [parseInt(HH), parseInt(mm)];
};

// Given a clock time, a starting instant, and a timezone, return the first DateTime of that clock time >= the starting instant
// "13:00", 2020-03-09T09:00 -> 2020-03-09T13:00
export const clockAfter = (
  time: string,
  instant: DateTime,
  tz: string
): DateTime => {
  const [HH, mm] = hrsAndMins(time);

  // Manually setting the hour and minute *could* set us backward
  // If so, add a day
  const after = instant
    .setZone(tz)
    .set({ hour: HH, minute: mm })
    .startOf("minute");
  if (after >= instant) {
    return after;
  } else {
    return after.plus({ days: 1 });
  }
};

// Same as above, but with a pair
// Makes sure that we wrap properly past midnight
// open: "09:00", close: "00:00", 2020-03-09T00:00 -> [2020-03-09T09:00, 2020-03-10T00:00]
export const clockAfterPair = (
  open: string,
  close: string,
  instant: DateTime,
  tz: string
): [DateTime, DateTime] => {
  const afterOpen = clockAfter(open, instant, tz);
  const afterClose = clockAfter(close, instant, tz);
  return [
    afterOpen,
    afterOpen < afterClose ? afterClose : afterClose.plus({ days: 1 }),
  ];
};
