import { DateTime } from "luxon";
import { create } from "zustand";

export const useDateContext = create<{
  dateContext: string;
  setDateContext: (dateContext: string) => void;
  isPast: () => boolean;
}>((set, get) => ({
  dateContext: DateTime.now().toISODate(),
  setDateContext: (dateContext: string) => set({ dateContext }),
  isPast: () => DateTime.now().toISODate() > get().dateContext,
}));
