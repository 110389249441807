import {
  Button,
  Link,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import type { GridColumns } from "@mui/x-data-grid";
import axios from "axios";
import { DateTime } from "luxon";
import { FC, useState } from "react";
import { MapContainer, Marker } from "react-leaflet";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { Page } from "../../components/Page";
import { PaginatedDataGrid } from "../../components/PaginatedDataGrid";
import { RButton } from "../../components/RButton";
import { RDialog } from "../../components/RDialog";
import { MapTiles } from "../../components/map/MapTiles";
import { useAuth } from "../../hooks/useAuth";
import { useMessageHelpers } from "../../hooks/useMessageHelpers";
import { useSnackbar } from "../../hooks/useSnackbar";
import { Message, ProductTier, User } from "../../shared/api_schema";
import { MAX_ZOOM } from "../dashboard/OverviewMap";

export const Support = () => {
  const { currentUser } = useAuth();
  const { setMessageRead, isMessageRead } = useMessageHelpers();
  const [viewingMessage, setViewingMessage] = useState<Message>();
  const [supportRequest, setSupportRequest] = useState("");
  const snackbar = useSnackbar();

  const submitSupportRequest = useMutation(async (body: string) =>
    axios.post(
      import.meta.env.VITE_SLACK_SUPPORT_WEBHOOK,
      {
        text: `
*User*: ${currentUser?.firstName} ${currentUser?.lastName}
*Email*: ${currentUser?.email}
*User ID*: ${currentUser?.id}

*Organization*: ${currentUser?.activeOrganization.name}
*Organization ID*: ${currentUser?.activeOrganization.id}
*Role*: ${currentUser?.activeOrganization.role}

*Message*: ${body}
`,
      },
      {
        headers: {
          "Content-Type": null, // CORS issue if we set this to JSON as you're supposed to
        },
      }
    )
  );

  const columns: GridColumns = [
    {
      field: "read",
      headerName: "—",
      align: "center",
      headerAlign: "center",
      width: 70,
      valueGetter: (params) =>
        isMessageRead(params.row as Message) === false ? "●" : "",
    },
    {
      field: "sender",
      headerName: "From",
      flex: 1,
      valueGetter: (params) => senderFullName(params.row.sender),
    },
    // {
    //   field: "subject",
    //   headerName: "Subject",
    //   flex: 1,
    // },
    {
      field: "message",
      headerName: "Message",
      flex: 3,
    },
    {
      field: "createdAt",
      headerName: "Sent",
      flex: 1,
      valueGetter: (params) =>
        DateTime.fromISO(params.row.createdAt).toRelative(),
    },
  ];

  function senderFullName(sender: User) {
    return `${sender.firstName} ${sender.lastName}`;
  }

  return (
    <Page title="Support">
      <Box display="flex" flexDirection="column" gap={4}>
        {currentUser?.activeOrganization.productTier !==
          ProductTier.PERSONAL && (
          <>
            <Typography variant="h4">Driver Feedback</Typography>
            <PaginatedDataGrid
              model="messages"
              columns={columns}
              onRowClick={(params) => {
                const message = params.row as Message;
                setViewingMessage(message);
                setMessageRead(message);
              }}
            />
          </>
        )}
        <Typography variant="h4">Contact Us</Typography>
        <Typography>
          Please email{" "}
          <Link href="mailto:support@routes.io">support@routes.io</Link> or
          submit the form below if you have any questions or concerns and we
          will respond as quickly as possible.
        </Typography>
        <TextField
          value={supportRequest}
          onChange={(ev) => setSupportRequest(ev.target.value)}
          placeholder="Type your support request here..."
          multiline
          rows={5}
        ></TextField>
        <RButton
          onClick={() =>
            submitSupportRequest.mutateAsync(supportRequest, {
              onSuccess: () => {
                snackbar.show(
                  "Your support request has been submitted!",
                  "success"
                );
                setSupportRequest("");
              },
              onError: () =>
                snackbar.show(
                  "There was an error submitting your support request.",
                  "error"
                ),
            })
          }
          loading={submitSupportRequest.isLoading}
          disabled={!supportRequest}
        >
          Submit
        </RButton>
      </Box>

      {viewingMessage && (
        <RDialog
          open={true}
          title={`${viewingMessage.subject} - ${senderFullName(
            viewingMessage.sender
          )}`}
          closeCallback={() => setViewingMessage(undefined)}
          maxWidth="sm"
        >
          <Box display="flex" flexDirection="column" gap={4}>
            <Box>{viewingMessage.message}</Box>
            {viewingMessage.properties && (
              <MessageProperties properties={viewingMessage.properties} />
            )}
          </Box>
        </RDialog>
      )}
    </Page>
  );
};

const MessageProperties: FC<{ properties: Record<string, any> }> = ({
  properties,
}) => {
  const navigate = useNavigate();

  return (
    <Box display="flex" flexDirection="column">
      <Table>
        {"currentRoute" in properties && (
          <>
            <TableRow>
              <TableCell>
                <Typography fontWeight="bold">Zone</Typography>
              </TableCell>
              <TableCell>
                <Typography>{properties.currentRoute.zoneName}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography fontWeight="bold">Route</Typography>
              </TableCell>
              <TableCell>
                <Button
                  onClick={() =>
                    navigate(`/routes/${properties.currentRoute.id}`)
                  }
                >
                  View Route
                </Button>
              </TableCell>
            </TableRow>
          </>
        )}
        {"currentStop" in properties && (
          <TableRow>
            <TableCell>
              <Typography fontWeight="bold">Stop</Typography>
            </TableCell>
            <TableCell>
              <Typography>{properties.currentStop.locationName}</Typography>
            </TableCell>
          </TableRow>
        )}
      </Table>
      {"coord" in properties && (
        <MapContainer
          style={{ width: "100%", height: 300 }}
          center={{
            lat: properties.coord.lat,
            lng: properties.coord.lon,
          }}
          zoom={MAX_ZOOM}
          maxZoom={MAX_ZOOM}
        >
          <MapTiles />
          <Marker
            position={{
              lat: properties.coord.lat,
              lng: properties.coord.lon,
            }}
          />
        </MapContainer>
      )}
    </Box>
  );
};
