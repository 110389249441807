import { Link, Typography } from "@mui/material";
import type { FC } from "react";
import { Page } from "../components/Page";

export const NotFound: FC = () => {
  return (
    <Page title="Oops!" subTitle="Not Found">
      <Typography variant="body1">
        We weren&apos;t able to find what you were looking for.
      </Typography>
      <Typography variant="body1">
        Please <Link href="/">click here to return home.</Link>
      </Typography>
    </Page>
  );
};
