import {
  Box,
  CircularProgress,
  Step,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
} from "@mui/material";
import { FC, useState } from "react";
import { RButton } from "../../components/RButton";
import { RDialog } from "../../components/RDialog";
import { RFormButtons } from "../../components/RFormButtons";
import { AppointmentForm } from "../../components/forms/AppointmentForm";
import { useShowStop } from "../../hooks/queries/useShowStop";
import { Appointment, Customer, Stop, Zone } from "../../shared/api_schema";
import { StopShipments } from "../shipments/StopShipments";

// Used to control which tab you're in (when _editing_), and also what stepper section you're in (when _creating_)
enum Section {
  APPOINTMENT,
  SHIPMENTS,
}

const SECTIONS = [Section.APPOINTMENT, Section.SHIPMENTS];

function sectionName(section: Section) {
  switch (section) {
    case Section.APPOINTMENT:
      return "Appointment";
    case Section.SHIPMENTS:
      return "Shipments";
  }
}

// So for only allows *creation and deletion* (no editing)
export const AppointmentDialog: FC<{
  title: string;
  appointment?: Appointment; // An appointment we're *editing/deleting*
  zone?: Zone; // Without a Zone, we're doing it from the No-Customer Appointments section. With a Zone, we're doing it from Route Builder.
  customer?: Customer; // With a Customer, we're doing it from the Customer Appointments section
  onClose: (removedId: string | undefined) => void; // Callback to tell parent to close us -- removed is defined if we deleted in
  onAdd?: (added: Appointment) => void; // Callback to tell parent what got added
  onUpdate?: (updated: Appointment) => void; // Callback to tell parent what got updated
}> = (props) => {
  const [stopId, setStopId] = useState<string | undefined>(
    props.appointment?.id
  );
  const stopQuery = useShowStop(stopId);
  const [added, setAdded] = useState<Stop>();
  const [activeSection, setSection] = useState<Section>(Section.APPOINTMENT);

  function EditAppointmentTabs() {
    return (
      <Box mb={4}>
        <Tabs value={activeSection} onChange={(_, idx) => setSection(idx)}>
          <Tab label="Appointment" />
          <Tab label="Shipments" />
        </Tabs>
      </Box>
    );
  }

  function CreateAppointmentStepper() {
    return (
      <Box mb={4}>
        <Stepper
          activeStep={activeSection}
          style={{ width: "30em", margin: "auto" }}
        >
          {SECTIONS.map((s) => (
            <Step
              key={s}
              active={s === activeSection}
              completed={
                s === Section.APPOINTMENT && activeSection > Section.APPOINTMENT
              }
            >
              <StepLabel>{sectionName(s)}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    );
  }

  function onFinish() {
    props.onClose(undefined);
    if (added && props.onAdd) {
      props.onAdd(added);
    }
  }

  return (
    <RDialog
      open={true}
      title={props.title}
      closeCallback={() => props.onClose(undefined)}
    >
      {props.appointment ? (
        <EditAppointmentTabs />
      ) : (
        <CreateAppointmentStepper />
      )}
      {activeSection === Section.APPOINTMENT && (
        <AppointmentForm
          appointment={props.appointment}
          customer={props.customer}
          zone={props.zone}
          hideDeleteButton={!props.appointment}
          onAdd={(added) => {
            setAdded(added);
            setStopId(added.id);
            setSection(Section.SHIPMENTS);
          }}
          onUpdate={(updated) => {
            props?.onUpdate?.(updated);
            setStopId(updated.id);
            props.onClose(undefined);
          }}
          onDelete={(stopId) => props.onClose(stopId)}
        />
      )}
      {activeSection === Section.SHIPMENTS && stopQuery.isLoading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={20}
        >
          <CircularProgress />
        </Box>
      )}
      {activeSection === Section.SHIPMENTS && !stopQuery.isLoading && (
        <>
          <StopShipments
            stop={stopQuery.data!.stop}
            onShipmentAdded={() => {
              stopQuery.refetch();
            }}
            onShipmentRemoved={() => {
              stopQuery.refetch();
            }}
          />
          <RFormButtons>
            <RButton onClick={onFinish}>Finish</RButton>
          </RFormButtons>
        </>
      )}
    </RDialog>
  );
};
