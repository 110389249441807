import axios from "axios";
import { useCallback } from "react";
import { create } from "zustand";
import { post } from "../lib/amplify";
import type {
  OauthAuthorizationRequest,
  OauthAuthorizationResponse,
} from "../shared/api_schema";
import { useOauthAppDetailsQuery } from "./queries/useOauthAppDetailsQuery";
import { LoginCredentials } from "./useAuth";
import { useQueryString } from "./useQueryString";

type OAuthStore = {
  creds: LoginCredentials | null;
  setCreds: (creds: LoginCredentials) => void;
};

const useOAuthStore = create<OAuthStore>((set) => ({
  creds: null,
  setCreds: (creds) => set({ creds }),
}));

export function useOAuth() {
  const queryString = useQueryString();
  const clientId = queryString.get("client_id");
  const redirectUri = queryString.get("redirect_uri");
  const responseType = queryString.get("response_type");
  const state = queryString.get("state");
  const appDetails = useOauthAppDetailsQuery(clientId);
  const oauthStore = useOAuthStore();

  const isOAuthFlow = clientId && redirectUri && responseType && state;

  const authorizeAndRedirect = useCallback(
    async (email: string, password: string, organizationId: string) => {
      if (!isOAuthFlow) {
        return;
      }

      try {
        const response = await post<
          OauthAuthorizationResponse,
          OauthAuthorizationRequest
        >("/oauth/authorize", {
          email,
          password,
          response_type: "code",
          redirect_uri: redirectUri!,
          client_id: clientId!,
          state: state!,
          organization_id: organizationId,
        });

        const fullRedirect = `${response.redirect_uri}?code=${response.authorization_code}&client_id=${response.client_id}&state=${state}&organization_id=${organizationId}`;
        window.location.replace(fullRedirect);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          switch (error.response?.status) {
            case 404:
              throw new Error(
                "There was a problem finding the app connection details"
              );

            // Integration has already been connected
            case 422:
              throw new Error((error.response.data as any).message);

            default:
              throw new Error("An unknown error has occurred");
          }
        } else {
          throw error;
        }
      }
    },
    [isOAuthFlow, clientId, redirectUri, state]
  );

  return {
    isOAuthFlow,
    clientId,
    redirectUri,
    responseType,
    state,
    appDetails,
    authorizeAndRedirect,
    setCreds: oauthStore.setCreds,
    creds: oauthStore.creds,
  };
}
