import type { Theme } from "@mui/material";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingScreen } from "../components/LoadingScreen";
import { SignOutButton } from "../components/SignOutButton";
import { useAuth } from "../hooks/useAuth";
import { useInvitation } from "../hooks/useInvitation";
import { useSnackbar } from "../hooks/useSnackbar";
import { get, post } from "../lib/amplify";
import type { InviteResponse } from "../shared/api_schema";

export const InviteAcceptance = ({ id }: { id?: string }) => {
  const { id: idFromPath } = useParams();
  const { clearPendingInvite } = useInvitation();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const { currentUser, refetchCurrentUser } = useAuth();
  const [loading, setLoading] = useState(true);

  const inviteId = id ?? idFromPath;

  const inviteAcceptMutation = useMutation(
    async (inviteSecret: string) => post(`/users/invite/${inviteSecret}`, {}),
    {
      onSuccess: () => clearPendingInvite(),
    }
  );

  const invitationQuery = useQuery<InviteResponse>(
    `invite-${inviteId}`,
    async () => get(`/users/invite/${inviteId}`),
    {
      retry: false,
      onError: (error) => clearPendingInvite(),
    }
  );
  const invite = invitationQuery.data?.invite;

  function handleAccept(inviteSecret: string) {
    inviteAcceptMutation.mutate(inviteSecret);
  }

  const [userNotSignedUp, setUserNotSignedUp] = useState(false);

  useEffect(() => {
    async function parseError() {
      const res = (inviteAcceptMutation.error as any).response;
      if (res && res.status === 401) {
        setUserNotSignedUp(true);
      }
    }

    if (inviteAcceptMutation.error) {
      parseError();
    }
  }, [inviteAcceptMutation.error]);

  const { mutateAsync } = inviteAcceptMutation;

  // Clear invitation and move user if this invite isn't for this user
  useEffect(() => {
    if (invite && currentUser) {
      if (invite.email !== currentUser.email) {
        clearPendingInvite();
        snackbar.show("Invitation incorrect or has expired", "error");
        navigate("/");
      }
    }
  }, [invite, currentUser, clearPendingInvite, snackbar, navigate]);

  const autoAcceptIfRequired = useCallback(async () => {
    // If we have an invite and this isn't the user's first organization
    // then we can auto accept the invite as this is likely why they signed up
    if (invite && currentUser && !currentUser.hasActiveOrganization()) {
      // Accept the invite
      await mutateAsync(invite.id);

      // Immediately set this organization as the user's active org
      // This is mostly to prevent any timing issues where the user maybe not
      // have an active org for a moment and get redirected
      currentUser.activeOrganization = {
        ...invite.organization,
        role: invite.role,
      };

      // Refetch the user (and their orgs) to ensure the new org membership take hold
      await refetchCurrentUser();
      navigate("/");
    } else {
      setLoading(false);
    }
  }, [navigate, mutateAsync, invite, currentUser, refetchCurrentUser]);

  useEffect(() => {
    autoAcceptIfRequired();
  }, [autoAcceptIfRequired]);

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      paper: {
        width: 500,
        padding: theme.spacing(6),
        marginTop: theme.spacing(12),
        border: "1px solid black",
      },
    })
  )();

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Box position="absolute" top="30px" right="30px">
        <SignOutButton />
      </Box>
      <Paper className={classes.paper}>
        {invitationQuery.isLoading || loading ? (
          <LoadingScreen />
        ) : inviteAcceptMutation.isSuccess ? (
          <InvitationSuccess />
        ) : invitationQuery.error ? (
          <InvitationNotFound />
        ) : userNotSignedUp ? (
          <Typography>
            You have not signed up yet. Please sign up with the email{" "}
            {invite?.email} and then accept this invite.
          </Typography>
        ) : invite ? (
          <Invitation
            invite={invite}
            onAccept={handleAccept}
            onReject={() => {
              snackbar.show("Invitation has been denied");
              clearPendingInvite();
              navigate("/");
            }}
          />
        ) : (
          <LoadingScreen />
        )}
      </Paper>
    </Grid>
  );
};

const Invitation = ({
  invite,
  onAccept,
  onReject,
}: {
  invite: InviteResponse["invite"];
  onAccept: (inviteSecret: string) => void;
  onReject: () => void;
}) => {
  const classes = makeStyles((theme: Theme) =>
    createStyles({
      buttons: {
        display: "flex",
        justifyContent: "center",
        paddingTop: 20,
        "& > *": {
          margin: theme.spacing(1),
        },
      },
    })
  )();

  return (
    <Box>
      <Typography align="center">
        You have been invited to join the organization{" "}
        {invite.organization.name}.
      </Typography>
      <Box className={classes.buttons}>
        <Button onClick={() => onAccept(invite.id)}>Accept</Button>
        <Button onClick={() => onReject()}>Reject</Button>
      </Box>
    </Box>
  );
};

const InvitationNotFound = () => {
  return <Typography align="center">Invitation was not found.</Typography>;
};

const InvitationSuccess = () => {
  return (
    <Typography align="center">
      You have accepted the invitation and will now be able to work within this
      organization in the Routes.io app.
    </Typography>
  );
};
