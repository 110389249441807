import type { Theme } from "@mui/material";
import { Alert, Box, Grid, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { BrandedCenteredBox } from "../../components/BrandedCenteredBox";
import { LoadingScreen } from "../../components/LoadingScreen";
import { RButton } from "../../components/RButton";
import { FormTextField } from "../../components/forms/FormTextField";
import { useInvitation } from "../../hooks/useInvitation";
import { useOAuth } from "../../hooks/useOAuth";
import { useQueryString } from "../../hooks/useQueryString";
import { post } from "../../lib/amplify";
import type {
  AccountDefaultParams,
  Location,
  SignUpRequest,
} from "../../shared/api_schema";
import { EMAIL_REGEX } from "../../shared/frontend";
import { InviteBanner } from "./InviteBanner";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      maxWidth: 500,
      padding: theme.spacing(6),
      marginTop: theme.spacing(12),
      border: "1px solid black",
    },
    formRow: {
      padding: theme.spacing(2),
    },
  })
);

export const SignUp = () => {
  const classes = useStyles();
  const oauth = useOAuth();
  const { invitationQuery } = useInvitation();
  const [signUpError, setSignUpError] = useState<string>();
  const queryParams = useQueryString();
  const presetEmail = queryParams.get("email");

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<SignUpRequest>({
    defaultValues: {
      email: "",
    },
  });

  const signUpCall = useMutation(async (data: SignUpRequest) =>
    post("/sign_up", data)
  );

  useEffect(() => {
    if (invitationQuery.data) {
      setValue("email", invitationQuery.data.invite.email);
    }
  }, [invitationQuery.data, setValue]);

  useEffect(() => {
    if (presetEmail) {
      setValue("email", presetEmail);
    }
  }, [presetEmail, setValue]);

  function querystringToLocation() {
    let valid = true;

    const params = [
      "name",
      "address",
      "locality",
      "region",
      "postalcode",
      "country",
      "timeZone",
      "lat",
      "lon",
    ];

    params.forEach((v) => {
      console.log(`location[${v}]`, queryParams.get(`location[${v}]`));
      if (!queryParams.get(`location[${v}]`)) {
        console.log(`location[${v}] is missing!`);
        valid = false;
      }
    });

    if (valid) {
      const location: Location = {
        name: queryParams.get("location[name]")!,
        address: queryParams.get("location[address]")!,
        locality: queryParams.get("location[locality]")!,
        region: queryParams.get("location[region]")!,
        postalcode: queryParams.get("location[postalcode]")!,
        country: queryParams.get("location[country]")!,
        timeZone: queryParams.get("location[timeZone]")!,
        coord: {
          lat: queryParams.get("location[lat]")!,
          lon: queryParams.get("location[lon]")!,
        },
      };
      return location;
    }
  }

  function queryStringToUserDetails() {
    const firstName = queryParams.get("firstName") ?? undefined;
    const lastName = queryParams.get("lastName") ?? undefined;
    return { firstName, lastName };
  }

  function accountDefaultsFromQuerystring() {
    const organizationName = queryParams.get("organizationName") ?? undefined;
    const homeLocation = querystringToLocation();
    const { firstName, lastName } = queryStringToUserDetails();

    if (organizationName || homeLocation) {
      const accountDefaults: AccountDefaultParams = {
        organizationName,
        homeLocation,
        firstName,
        lastName,
      };
      return accountDefaults;
    }
  }

  function formSubmit(data: SignUpRequest) {
    if (oauth.isOAuthFlow) {
      data.oauth = {
        client_id: oauth.clientId!,
        redirect_uri: oauth.redirectUri!,
        response_type: oauth.responseType!,
        state: oauth.state!,
      };
    }

    data.accountDefaults = accountDefaultsFromQuerystring();

    signUpCall.mutate(data, {
      onError: (error) => {
        const baseError = "There was an issue with the sign up process. ";
        console.error(error);
        if (axios.isAxiosError(error)) {
          setSignUpError(baseError + (error.response?.data as any).message);
        } else {
          setSignUpError(baseError + "Please try again.");
        }
      },
    });
  }

  if (oauth.isOAuthFlow && oauth.appDetails.isLoading) {
    return (
      <BrandedCenteredBox>
        <Box textAlign={"center"}>
          <LoadingScreen />
          <Typography m={4}>Confirming connection details...</Typography>
        </Box>
      </BrandedCenteredBox>
    );
  }

  return (
    <BrandedCenteredBox>
      <InviteBanner message="Please sign up to continue." />

      {oauth.isOAuthFlow && oauth.appDetails.data && (
        <Box my={4}>
          <Alert severity="info">
            Sign up here to connect your Routes and{" "}
            <strong>{oauth.appDetails.data.app.name}</strong> accounts
          </Alert>
        </Box>
      )}

      {signUpError && (
        <Box my={4}>
          <Alert severity="error">{signUpError}</Alert>
        </Box>
      )}

      {signUpCall.isSuccess ? (
        <UserSignUpSuccessfulMessage />
      ) : (
        <form noValidate autoComplete="off" onSubmit={handleSubmit(formSubmit)}>
          <Grid container>
            <Grid item className={classes.formRow} xs={12}>
              <FormTextField
                control={control}
                errors={errors}
                name="email"
                label="Email"
                rules={{
                  required: "An email is required",
                  pattern: {
                    value: EMAIL_REGEX,
                    message: "Please provide a valid email address.",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Box mt={6}>
                <RButton
                  color="primary"
                  fullWidth
                  type="submit"
                  loading={signUpCall.isLoading}
                >
                  Sign Up
                </RButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </BrandedCenteredBox>
  );
};

const UserSignUpSuccessfulMessage = () => {
  return (
    <Box>
      <Typography variant="h2" gutterBottom>
        Verify Email Address
      </Typography>
      <Typography variant="body1" gutterBottom>
        Check your email for a link to complete the sign up process.
      </Typography>
    </Box>
  );
};
