import { Box, Divider, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import { DateTime } from "luxon";
import { FC, useState } from "react";
import { AddAppointment } from "../../components/AddAppointment";
import { AddStop } from "../../components/AddStop";
import { DistanceSwap } from "../../components/DistanceSwap";
import { DurationSwap } from "../../components/DurationSwap";
import { RDialog } from "../../components/RDialog";
import useShowRoute from "../../hooks/queries/useShowRoute";
import { getWaypoints, routeHasEnded } from "../../lib/map_helpers";
import { DeepRoute, Stop, TimePair } from "../../shared/api_schema";
import { toDurationShort } from "../../shared/frontend";
import { Shipment } from "../shipments/Shipment";
import { RouteHeaderBlock } from "./RouteHeaderBlock";
import { RouteStop } from "./RouteStop";
import { WaypointBlock } from "./WaypointBlock";

export function routeDuration(
  route: { begin: TimePair; end: TimePair },
  expectedOrActual: "expected" | "actual"
): number | undefined {
  if (
    route.begin[expectedOrActual] === undefined ||
    route.end[expectedOrActual] === undefined
  )
    return;
  return (
    DateTime.fromISO(route.end[expectedOrActual]!).toSeconds() -
    DateTime.fromISO(route.begin[expectedOrActual]!).toSeconds()
  );
}

export function routeTotalService(route: { stops: Array<Stop> }): number {
  return route.stops.reduce((prev, curr) => prev + curr.service.expected!, 0);
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    routeStopList: {
      zIndex: 1,
      width: "368px",
      height: "100%",
      padding: 20,
      boxShadow: "10px 0px 5px rgba(0,0,0,.1)",
      overflowY: "auto",
      "@media print": {
        overflowY: "visible",
        width: "100%",
      },
    },
    durationLabel: {
      fontWeight: "bold",
      marginRight: 4,
    },
    headerDivider: {
      marginBottom: "20px",
    },
    subHeader: {
      paddingBottom: theme.spacing(5),
    },
    numberSwap: {
      display: "inline",
      paddingLeft: theme.spacing(1),
      fontSize: "0.9em",
      margin: 0,
    },
  })
);

export const RouteStopList: FC<{
  route: DeepRoute;
}> = ({ route }) => {
  const styles = useStyles();

  const [showStopId, setShowStopId] = useState<string>();
  const [showShipmentId, setShowShipmentId] = useState<string>();

  // Grab hold of an instance of this so we can invalidate the stop list/route
  const routeQuery = useShowRoute(route.id);

  return (
    <div className={styles.routeStopList}>
      <RouteHeaderBlock route={route} />
      <Divider className={styles.headerDivider} />
      <Box className={styles.subHeader}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {!routeHasEnded(route) && <AddStop route={route} />}
          {!routeHasEnded(route) && <AddAppointment route={route} />}
        </Box>
        {routeHasEnded(route) && (
          <Box display="flex" alignItems="baseline">
            <span className={styles.durationLabel}>Duration:</span>
            <pre className={styles.numberSwap}>
              <DurationSwap
                method={toDurationShort}
                pair={{
                  expected: routeDuration(route, "expected"),
                  actual: routeDuration(route, "actual"),
                }}
              />
            </pre>
            <span
              style={{
                fontWeight: "bold",
                marginLeft: 8,
              }}
            >
              Distance:
            </span>
            <pre className={styles.numberSwap}>
              <DistanceSwap pair={route.distance} />
            </pre>
          </Box>
        )}
      </Box>
      {getWaypoints(route).map((stop) => (
        <WaypointBlock
          key={stop.order}
          waypoint={stop}
          onShowStop={setShowStopId}
          onShowShipment={setShowShipmentId}
        />
      ))}
      {showStopId && (
        <RouteStop
          route={route}
          stopId={showStopId}
          onClose={() => setShowStopId(undefined)}
          onShipmentAdded={() => {
            // Want the waypoints in the sidebar to show new shipments when we add them
            routeQuery.refetch();
          }}
          onShipmentRemoved={() => {
            // Want the waypoints in the sidebar to show new shipments when we add them
            routeQuery.refetch();
          }}
          onStopUpdated={() => {
            routeQuery.refetch();
          }}
        />
      )}
      {showShipmentId && (
        <RDialog open={true} closeCallback={() => setShowShipmentId(undefined)}>
          <Shipment shipmentId={showShipmentId} allowDelete={false} />
        </RDialog>
      )}
    </div>
  );
};
