import { divIcon } from "leaflet";
import type { CSSProperties, FC } from "react";
import { useMemo } from "react";
import { renderToString } from "react-dom/server";
import { Marker, Tooltip } from "react-leaflet";
import { waypointColor } from "../lib/map_helpers";
import { WaypointNumber } from "../pages/routes/WaypointBlock";
import type { Waypoint } from "../shared/api_schema";

export const MARKER_SIZE = 30;

// There's a memory leak if you use makeStyles with a dynamic value, so I'm just inlining the style
export function appointmentMarkerStyle(waypoint: Waypoint): CSSProperties {
  return {
    background: waypointColor(waypoint),
    color: "white",
    height: "100%",
    borderRadius: "50%",
    border: "2px solid white",
    fontWeight: "bold",
    fontSize: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  };
}

export const AppointmentMarker: FC<{
  waypoint: Waypoint;
}> = ({ waypoint }) => {
  const iconInner = useMemo(
    () => renderToString(<WaypointNumber waypoint={waypoint} />),
    [waypoint]
  );

  const icon = useMemo(
    () =>
      divIcon({
        iconSize: [MARKER_SIZE, MARKER_SIZE],
        html: iconInner,
        className: undefined,
      }),
    [iconInner]
  );

  return (
    <Marker
      icon={icon}
      position={[
        parseFloat(waypoint.location.coord.lat),
        parseFloat(waypoint.location.coord.lon),
      ]}
    >
      <Tooltip>{waypoint.location.name}</Tooltip>
    </Marker>
  );
};
