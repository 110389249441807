import type { Theme } from "@mui/material";
import { Alert, Box, Grid, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Auth } from "aws-amplify";
import type { Dispatch, SetStateAction } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { BrandedCenteredBox } from "../components/BrandedCenteredBox";
import { FormTextField } from "../components/forms/FormTextField";
import { RButton } from "../components/RButton";

export const ForgotPassword = () => {
  const [email, setEmail] = useState<string>();
  const [alertError, setAlertError] = useState<string>();
  const [alertSuccess, setAlertSuccess] = useState<string>();

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      paper: {
        width: 500,
        padding: theme.spacing(6),
        marginTop: theme.spacing(12),
        border: "1px solid black",
      },
      blurb: {
        textAlign: "left",
        paddingBottom: 8,
      },
      choiceButton: {
        margin: "8px 0",
      },
    })
  )();

  function handleForgotFormSuccess(email: string) {
    setEmail(email);
    setAlertSuccess(
      "A verification code has been sent to your email. Please check email to continue."
    );
  }

  return (
    <BrandedCenteredBox>
      {alertSuccess && (
        <Box my={4}>
          <Alert severity="success">{alertSuccess}</Alert>
        </Box>
      )}

      {alertError && (
        <Box my={4}>
          <Alert severity="error">{alertError}</Alert>
        </Box>
      )}

      <Box pt={6}>
        <Typography className={classes.blurb}>
          Enter your email to begin password reset process
        </Typography>

        {email ? (
          <VerificationCodeForm email={email} onError={setAlertError} />
        ) : (
          <ForgotPasswordForm
            onSuccess={handleForgotFormSuccess}
            onError={setAlertError}
          />
        )}
      </Box>
    </BrandedCenteredBox>
  );
};

const ForgotPasswordForm = ({
  onSuccess,
  onError,
}: {
  onSuccess: (email: string) => void;
  onError: Dispatch<SetStateAction<string | undefined>>;
}) => {
  interface ForgotPasswordForm {
    email: string;
  }

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ForgotPasswordForm>();

  async function formSubmit(data: ForgotPasswordForm) {
    try {
      await Auth.forgotPassword(data.email);
      onSuccess(data.email);
    } catch (error: any) {
      onError(error.message);
    }
  }

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit(formSubmit)}>
      <Grid item xs={12}>
        <FormTextField
          control={control}
          errors={errors}
          name="email"
          label="Email"
          rules={{ required: "An email is required" }}
        />
      </Grid>
      <Grid item xs={12}>
        <Box mt={6}>
          <RButton
            loading={isSubmitting}
            color="primary"
            fullWidth
            type="submit"
          >
            Reset Password
          </RButton>
        </Box>
      </Grid>
    </form>
  );
};

const VerificationCodeForm = ({
  email,
  onError,
}: {
  email: string;
  onError: Dispatch<SetStateAction<string | undefined>>;
}) => {
  const navigate = useNavigate();
  interface VerificationCodeForm {
    email: string;
    code: string;
    password: string;
  }

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<VerificationCodeForm>({ defaultValues: { email } });

  async function formSubmit(data: VerificationCodeForm) {
    try {
      const trimmedCode = data.code.trim();
      await Auth.forgotPasswordSubmit(data.email, trimmedCode, data.password);
      navigate("/", {
        state: { message: "Password changed. Please sign in." },
      });
    } catch (error: any) {
      onError(error.message);
    }
  }

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit(formSubmit)}>
      <Grid item xs={12}>
        <Box mt={4}>
          <FormTextField
            control={control}
            errors={errors}
            name="email"
            label="Email"
            rules={{ required: "An email is required" }}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mt={4}>
          <FormTextField
            control={control}
            errors={errors}
            name="code"
            label="Verification Code"
            rules={{ required: "A verification code is required" }}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mt={4}>
          <FormTextField
            control={control}
            errors={errors}
            name="password"
            type="password"
            label="New Password"
            rules={{ required: "Please enter a password" }}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mt={6}>
          <RButton
            loading={isSubmitting}
            color="primary"
            fullWidth
            type="submit"
          >
            Reset Password
          </RButton>
        </Box>
      </Grid>
    </form>
  );
};
