import { Box, Grid, Typography } from "@mui/material";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { errorSnackbar, useSnackbar } from "../../hooks/useSnackbar";
import { post } from "../../lib/amplify";
import { EstimatedRoutesDialog } from "../../pages/routes/EstimatedRoutesDialog";
import {
  AbsoluteTime,
  AddRouteStopRequest,
  AddRouteStopResponse,
  Appointment,
  Contact,
  Customer,
  EstimateRoutesResponse,
  Location,
  Route,
} from "../../shared/api_schema";
import { RButton } from "../RButton";
import { RFormButtons } from "../RFormButtons";
import { ContactForm } from "./ContactForm";
import { FormDateTimePicker, toTimePicker } from "./FormDateTimePicker";
import { FormDurationPicker } from "./FormDurationPicker";
import { FormTextField } from "./FormTextField";
import { LocationForm } from "./LocationForm";

type AddAppointmentFormTypes = {
  zoneId: string;
  customer: Customer | null; // Optional Customer info instead of setting Location info
  open: AbsoluteTime;
  close: AbsoluteTime;
  location: Location;
  contact: Contact;
  service: number;
  comment: string;
};

// Based on AppointmentForm
// It's trimmed down, because it's all read-only/disabled
// and is only used for adding Appointments ("Unassigned Stops") to Routes
export const AddAppointmentForm: FC<{
  appointment: Appointment;
  route: Route;
  onSuccess?: () => void;
}> = (props) => {
  const [estimatedRoutes, setEstimatedRoutes] =
    useState<EstimateRoutesResponse>();

  const addAppointmentForm = useForm<AddAppointmentFormTypes>({
    defaultValues: {
      zoneId: props.appointment.zone.id,
      customer: props.appointment.customer,
      open: toTimePicker(props.appointment.open),
      close: toTimePicker(props.appointment.close),
      service: props.appointment.service.expected,
      location: props.appointment.location,
      contact: props.appointment.contact,
      comment: props.appointment.comment ?? "",
    },
  });

  const snackbar = useSnackbar();

  const addRouteStopMutation = useMutation(
    async (payload: AddRouteStopRequest) =>
      post<AddRouteStopResponse>("/routes/stops", payload),
    {
      onSuccess: setEstimatedRoutes,
      onError: (err: any) => errorSnackbar(err, snackbar),
    }
  );

  async function formSubmit() {
    // Only submit a Customer if we have one
    if (!props.appointment.customer) {
      delete props.appointment.customer;
    }

    return addRouteStopMutation.mutateAsync({
      id: props.appointment.id,
      route: { id: props.route.id },
    });
  }

  return (
    <>
      <form
        noValidate
        autoComplete="off"
        onSubmit={addAppointmentForm.handleSubmit(formSubmit)}
      >
        <LocationForm parentForm={addAppointmentForm} disabled />
        <Box mt={4}>
          <Grid container spacing={4}>
            <Grid item md={4}>
              <FormDateTimePicker
                name="open"
                label="Earliest Arrival"
                control={addAppointmentForm.control}
                disabled
              />
            </Grid>
            <Grid item md={4}>
              <FormDateTimePicker
                name="close"
                label="Latest Arrival"
                control={addAppointmentForm.control}
                disabled
              />
            </Grid>
            <Grid item md={4}>
              <FormDurationPicker
                name="service"
                control={addAppointmentForm.control}
                disabled
              />
            </Grid>
            <Grid item md={12}>
              <FormTextField
                label="Driver Note"
                name="comment"
                multiline
                maxRows={4}
                control={addAppointmentForm.control}
                disabled
              />
            </Grid>
            <Grid item md={12}>
              <Box my={4} sx={{ marginTop: 0 }}>
                <Typography variant="h2">Contact</Typography>
              </Box>
              <ContactForm parentForm={addAppointmentForm} disabled />
            </Grid>
          </Grid>
        </Box>
        <RFormButtons>
          <RButton type="submit" loading={addRouteStopMutation.isLoading}>
            Add
          </RButton>
        </RFormButtons>
      </form>
      {estimatedRoutes && (
        <EstimatedRoutesDialog
          estimatedRoutes={estimatedRoutes}
          onClose={() => setEstimatedRoutes(undefined)}
          onConfirm={() => {
            setEstimatedRoutes(undefined);
            props.onSuccess?.();
          }}
        />
      )}
    </>
  );
};
