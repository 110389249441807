import type { FC } from "react";
import { BrowserRouter, Route, Routes as RouterRoutes } from "react-router-dom";
import { ChangePassword } from "../pages/ChangePassword";
import { ForgotPassword } from "../pages/ForgotPassword";
import { InviteAcceptance } from "../pages/InviteAcceptance";
import { NotFound } from "../pages/NotFound";
import { Appointments } from "../pages/appointments/Appointments";
import { Customers } from "../pages/customers/Customers";
import { Dashboard } from "../pages/dashboard/Dashboard";
import { Experiments } from "../pages/experiments/Experiments";
import { Onboarding } from "../pages/onboarding/Onboarding";
import { Organization } from "../pages/organization/Organization";
import { Profile } from "../pages/profile/Profile";
import { Reports } from "../pages/reports/Reports";
import { DispatchedRouteManager } from "../pages/routes/DispatchedRouteManager";
import { RouteBuilder } from "../pages/routes/RouteBuilder";
import { RouteDispatcher } from "../pages/routes/RouteDispatcher";
import { Routes } from "../pages/routes/Routes";
import { Shipments } from "../pages/shipments/Shipments";
import { Stops } from "../pages/stops/Stops";
import { Support } from "../pages/support/Support";
import { ConfirmEmail } from "../pages/users/ConfirmEmail";
import { SignIn } from "../pages/users/SignIn";
import { SignUp } from "../pages/users/SignUp";
import { Users } from "../pages/users/Users";
import { Vehicles } from "../pages/vehicles/Vehicles";
import { Zones } from "../pages/zones/Zones";
import { PrivateRoute } from "../router/PrivateRoute";

export const AppRouter: FC = () => {
  return (
    <BrowserRouter>
      <RouterRoutes>
        {/* Public Routes */}
        <Route path="/" element={<SignIn />} />
        <Route path="/sign_up" element={<SignUp />} />
        <Route path="/confirm" element={<ConfirmEmail />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/change_password" element={<ChangePassword />} />

        {/* Private Routes */}
        <Route
          path="/onboarding"
          element={<PrivateRoute layout={false} element={<Onboarding />} />}
        />
        <Route
          path="/dashboard"
          element={<PrivateRoute element={<Dashboard />} />}
        />
        <Route
          path="/profile"
          element={<PrivateRoute element={<Profile />} />}
        />
        <Route
          path="/zones/:id/routes/:date"
          element={<PrivateRoute element={<RouteBuilder />} />}
        />
        <Route path="/routes" element={<PrivateRoute element={<Routes />} />} />
        <Route
          path="/routes/:id/dispatch"
          element={<PrivateRoute element={<RouteDispatcher />} />}
        />
        <Route
          path="/routes/:id"
          element={<PrivateRoute element={<DispatchedRouteManager />} />}
        />
        <Route
          path="/shipments"
          element={<PrivateRoute element={<Shipments />} />}
        />
        <Route path="/zones" element={<PrivateRoute element={<Zones />} />} />
        <Route
          path="/vehicles"
          element={<PrivateRoute element={<Vehicles />} />}
        />
        <Route
          path="/customers"
          element={<PrivateRoute element={<Customers />} />}
        />
        <Route
          path="/appointments"
          element={<PrivateRoute element={<Appointments />} />}
        />
        <Route path="/stops" element={<PrivateRoute element={<Stops />} />} />
        <Route path="/users" element={<PrivateRoute element={<Users />} />} />
        <Route
          path="/support"
          element={<PrivateRoute element={<Support />} />}
        />
        <Route
          path="/organization"
          element={<PrivateRoute element={<Organization />} />}
        />
        <Route
          path="/users/invite/:id"
          element={<PrivateRoute element={<InviteAcceptance />} />}
        />
        <Route
          path="/experiments"
          element={<PrivateRoute element={<Experiments />} />}
        />
        <Route
          path="/reports"
          element={<PrivateRoute element={<Reports />} />}
        />
        <Route path="*" element={<NotFound />} />
      </RouterRoutes>
    </BrowserRouter>
  );
};
