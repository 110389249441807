import type { FC } from "react";
import { Page } from "../../components/Page";
import { AppointmentsList } from "./AppointmentsList";

export const Appointments: FC = () => {
  return (
    <Page>
      <AppointmentsList />
    </Page>
  );
};
