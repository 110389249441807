import { Storage } from "aws-amplify";
import type { FC } from "react";
import { useQuery } from "react-query";
import { LoadingScreen } from "./LoadingScreen";

export const S3Image: FC<{ s3Key: string }> = ({ s3Key }) => {
  const presignedUrlQuery = useQuery(["s3Image", s3Key], async () =>
    Storage.get(s3Key)
  );

  if (presignedUrlQuery.isLoading) {
    return <LoadingScreen />;
  }

  const presignedUrl = presignedUrlQuery.data;
  return (
    <a href={presignedUrl} target="_blank" rel="noreferrer">
      <img src={presignedUrl} width="100%" style={{ display: "block" }} />
    </a>
  );
};
