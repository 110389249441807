import { Box, List } from "@mui/material";
import { ReactNode } from "react";
import { Shipment } from "../../shared/api_schema";
import { Colors, groupAndSortShipments } from "../../shared/frontend";
import { WaypointShipment } from "./WaypointShipment";

const styles = {
  shipmentList: {
    listStyleType: "none",
    padding: 0,
    marginTop: 4,
    marginLeft: -2,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
};

export function WaypointShipments({
  shipments,
  onShowShipment,
}: {
  shipments: Shipment[];
  onShowShipment: (shipmentId: string) => void;
}) {
  const shipmentGroups = groupAndSortShipments<Shipment>(shipments);

  if (!shipmentGroups.length) {
    return null;
  }

  return (
    <List sx={styles.shipmentList}>
      {shipmentGroups.map((shipmentGroup) => {
        const waypointShipments = shipmentGroup.shipments.map((shipment) => {
          return (
            <WaypointShipment
              key={shipment.id}
              shipment={shipment}
              onShowShipment={onShowShipment}
            />
          );
        });

        if (shipmentGroup.groupUuid) {
          return (
            <MultipieceBox key={shipmentGroup.groupUuid}>
              {waypointShipments}
            </MultipieceBox>
          );
        } else {
          return waypointShipments;
        }
      })}
    </List>
  );
}

function MultipieceBox({ children }: { children: ReactNode }) {
  return (
    <Box
      sx={{
        backgroundColor: Colors.OFF_WHITE,
        border: `1px solid ${Colors.GREY_CLOUD}`,
        margin: 1,
        padding: 1,
        paddingRight: 2,
      }}
    >
      {children}
    </Box>
  );
}
