import { object, ObjectSchema, string } from "yup";
import { is24HrTime } from "../time";
import type { Zone } from "./zone";

export type CreateZoneRequest = Omit<Zone, "id">;

export const createZoneSchema: ObjectSchema<CreateZoneRequest> = object({
  name: string().required("Name is required"),
  timeZone: string().required("Time zone is required"),
  open: string()
    .required("Open time is required")
    .test("24hr-open-time", "Open time is not valid", is24HrTime),
});
