import type { Theme } from "@mui/material";
import { Alert, Box, Button, FormControl, Grid } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Auth } from "aws-amplify";
import type { FC } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { BrandedCenteredBox } from "../components/BrandedCenteredBox";
import { FormTextField } from "../components/forms/FormTextField";
import { useAuth } from "../hooks/useAuth";
import { useSnackbar } from "../hooks/useSnackbar";

interface FormData {
  oldPassword?: string;
  password: string;
  passwordConfirmation: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "column",
    },
    formControl: {
      maxWidth: "300px",
      marginBottom: theme.spacing(2),
    },
    alert: {
      marginBottom: 20,
    },
  })
);

export const ChangePassword: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const { unconfirmedUser, currentUser } = useAuth();
  const [generalError, setGeneralError] = useState<string>();

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
  } = useForm<FormData>({
    defaultValues: {
      oldPassword: "",
      password: "",
      passwordConfirmation: "",
    },
  });

  // This same view is used for both a _required_ password change
  // and a voluntary one
  const isRequiredChange = !!unconfirmedUser;
  const isVoluntaryChange = !!currentUser;

  const formSubmit = async (data: FormData) => {
    try {
      if (unconfirmedUser?.challengeParam) {
        await Auth.completeNewPassword(unconfirmedUser, data.password, {
          email: unconfirmedUser.challengeParam.userAttributes.email,
        });
        navigate("/", {
          state: { message: "Password changed. Please sign in." },
        });
      } else if (currentUser && data.oldPassword) {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(cognitoUser, data.oldPassword, data.password);
        snackbar.show("Password changed.");
        navigate("/profile");
      }
    } catch (error: any) {
      if (error.code === "NotAuthorizedException") {
        setError("oldPassword", {
          type: "authentication",
          message: "Old password is incorrect",
        });
      } else if (error.code === "InvalidPasswordException") {
        setError("password", {
          type: "compliance",
          message: error.message,
        });
      } else {
        setGeneralError(error.message);
      }
    }
  };

  return (
    <BrandedCenteredBox>
      {generalError && (
        <Alert severity="error" className={classes.alert}>
          {generalError}
        </Alert>
      )}

      {isRequiredChange && (
        <h3>Please change your password before proceeding.</h3>
      )}

      <form
        className={classes.form}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(formSubmit)}
      >
        <Box>
          <Grid container spacing={5}>
            {isVoluntaryChange && (
              <Grid item md={12}>
                <FormTextField
                  control={control}
                  errors={errors}
                  name="oldPassword"
                  type="password"
                  label="Old Password"
                  rules={{
                    required: "Your old password is required",
                    minLength: {
                      value: 2,
                      message: "Passwords must be greater than 1 character",
                    },
                  }}
                />
              </Grid>
            )}
            <Grid item md={12}>
              <FormTextField
                control={control}
                errors={errors}
                name="password"
                type="password"
                label="Password"
                rules={{
                  required: "A password is required",
                  minLength: {
                    value: 2,
                    message: "Passwords must be greater than 1 character",
                  },
                }}
              />
            </Grid>
            <Grid item md={12}>
              <FormTextField
                control={control}
                errors={errors}
                name="passwordConfirmation"
                type="password"
                label="Confirm Password"
                rules={{
                  required: "A password confirmation is required",
                  minLength: {
                    value: 2,
                    message: "Passwords must be greater than 1 character",
                  },
                  validate: {
                    matches: (v) =>
                      v === getValues("password")
                        ? undefined
                        : "Passwords must match",
                  },
                }}
              />
            </Grid>
            <Grid item md={12}>
              <FormControl className={classes.formControl}>
                <Button variant="contained" color="primary" type="submit">
                  Change Password
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </form>
    </BrandedCenteredBox>
  );
};
