import { Box, Grid, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useDateContext } from "../../hooks/useDateContext";
import { errorSnackbar, useSnackbar } from "../../hooks/useSnackbar";
import { del, post, put } from "../../lib/amplify";
import {
  AbsoluteTime,
  Appointment,
  Contact,
  CreateAppointmentRequest,
  CreateAppointmentResponse,
  Customer,
  Location,
  UpdateAppointmentRequest,
  UpdateAppointmentResponse,
  Zone,
} from "../../shared/api_schema";
import { CustomerPicker } from "../CustomerPicker";
import { RButton } from "../RButton";
import { RFormButtons } from "../RFormButtons";
import { ContactForm, EMPTY_CONTACT } from "./ContactForm";
import {
  FormDateTimePicker,
  fromTimePicker,
  toTimePicker,
} from "./FormDateTimePicker";
import { FormDurationPicker } from "./FormDurationPicker";
import { FormTextField } from "./FormTextField";
import { FormZonePicker } from "./FormZonePicker";
import { EMPTY_LOCATION, LocationForm } from "./LocationForm";
import { nullsToEmptyStrings } from "./form_helpers";

type AppointmentFormTypes = {
  zoneId: string;
  customer: Customer | null; // Optional Customer info instead of setting Location info
  open: AbsoluteTime;
  close: AbsoluteTime;
  location: Location;
  contact: Contact;
  service: number;
  comment: string;
};

export function useAppointmentForm() {
  const queryClient = useQueryClient();

  async function createAppointment(data: AppointmentFormTypes) {
    const common = {
      zone: { id: data.zoneId },
      service: { expected: data.service },
      open: data.open,
      close: data.close,
      comment: data.comment,
    };

    let payload: CreateAppointmentRequest;
    if (data.customer) {
      payload = {
        ...common,
        customer: data.customer,
      };
    } else {
      payload = {
        ...common,
        location: data.location,
        contact: data.contact,
      };
    }

    return await post<CreateAppointmentResponse>("/appointments", payload);
  }

  async function updateAppointment(stopId: string, data: AppointmentFormTypes) {
    const common = {
      zone: { id: data.zoneId },
      service: { expected: data.service },
      comment: data.comment,
      open: data.open,
      close: data.close,
    };

    let payload: UpdateAppointmentRequest;
    if (data.customer) {
      payload = {
        ...common,
        customer: data.customer,
      };
    } else {
      payload = {
        ...common,
        contact: data.contact,
        location: data.location,
        customer: null,
      };
    }

    return await put<UpdateAppointmentResponse>(
      `/appointments/${stopId}`,
      payload
    );
  }

  const deleteAppointmentMutation = useMutation(
    async (appointmentId: string) => del(`/appointments/${appointmentId}`),
    {
      onSuccess: (_response, _appointmentId) => {
        queryClient.invalidateQueries("customer_appointments");
        queryClient.invalidateQueries("appointments");
      },
    }
  );

  return { createAppointment, updateAppointment, deleteAppointmentMutation };
}

export const AppointmentForm: FC<{
  appointment?: Appointment;
  zone?: Zone;
  customer?: Customer;
  hideDeleteButton?: boolean;
  onAdd?: (added: Appointment) => void;
  onUpdate?: (updated: Appointment) => void;
  onDelete?: (id: string) => void;
}> = (props) => {
  const snackbar = useSnackbar();
  const { dateContext } = useDateContext();
  const queryClient = useQueryClient();
  const { createAppointment, updateAppointment, deleteAppointmentMutation } =
    useAppointmentForm();

  const [disabled, setDisabled] = useState(
    !!props.appointment?.customer || !!props.customer
  );
  const [isDeleting, setIsDeleting] = useState(false);
  const [stopId, setStopId] = useState<string | undefined>(
    props.appointment?.id
  );

  function defaultLocation() {
    if (props.appointment) {
      if (props.appointment.customer) {
        return (props.appointment.customer as Customer).location;
      } else {
        return props.appointment.location;
      }
    } else if (props.customer) {
      return props.customer.location;
    } else {
      return EMPTY_LOCATION;
    }
  }

  function defaultContact() {
    return props.appointment?.contact
      ? nullsToEmptyStrings(props.appointment?.contact)
      : EMPTY_CONTACT;
  }

  const appointmentForm = useForm<AppointmentFormTypes>({
    defaultValues: {
      zoneId: props.appointment?.zone.id ?? props.zone?.id ?? "",
      customer: props.appointment?.customer ?? props.customer ?? null,
      open: toTimePicker(props.appointment?.open ?? `${dateContext}T09:00`),
      close: toTimePicker(props.appointment?.close ?? `${dateContext}T17:00`),
      service: props.appointment?.service.expected ?? 600,
      location: defaultLocation(),
      contact: defaultContact(),
      comment: props.appointment?.comment ?? "",
    },
  });

  async function formSubmit(data: AppointmentFormTypes) {
    let response: CreateAppointmentResponse | UpdateAppointmentResponse;

    const open = fromTimePicker(data.open, data.location.timeZone);
    const close = fromTimePicker(data.close, data.location.timeZone);

    try {
      if (stopId) {
        response = await updateAppointment(stopId, { ...data, open, close });
        queryClient.invalidateQueries(["stop", stopId]);
        props.onUpdate?.(response.appointment);
      } else {
        response = await createAppointment({ ...data, open, close });
        setStopId(response.appointment.id);
        // For creating Appointments in the Route Builder
        props.onAdd?.(response.appointment);
      }

      // For creating Appointments in the Appointments section
      queryClient.invalidateQueries("customer_appointments");
      queryClient.invalidateQueries("appointments");
    } catch (err) {
      errorSnackbar(err, snackbar);
    }
  }

  async function handleDelete() {
    setIsDeleting(true);
    await deleteAppointmentMutation.mutateAsync(props.appointment!.id);
    setIsDeleting(false);
    props.onDelete?.(props.appointment!.id);
  }

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={appointmentForm.handleSubmit(formSubmit)}
    >
      <Box mb={4}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <CustomerPicker
              value={appointmentForm.getValues("customer")}
              disabled={!!props.customer}
              onChange={(customer) => {
                if (customer) {
                  setDisabled(true);
                  appointmentForm.setValue("customer", customer);
                  appointmentForm.setValue(
                    "contact",
                    nullsToEmptyStrings(customer.contact)
                  );
                  appointmentForm.setValue(
                    "location",
                    nullsToEmptyStrings(customer.location)
                  );
                } else {
                  appointmentForm.reset();
                  setDisabled(false);
                  appointmentForm.setValue("customer", null);
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormZonePicker
              name="zoneId"
              setValue={appointmentForm.setValue}
              control={appointmentForm.control}
              errors={appointmentForm.formState.errors}
              disabled={!!props.zone}
            />
          </Grid>
        </Grid>
      </Box>
      <LocationForm parentForm={appointmentForm} disabled={disabled} />
      <Box mt={4}>
        <Grid container spacing={4}>
          <Grid item md={4}>
            <FormDateTimePicker
              name="open"
              label="Earliest Arrival"
              control={appointmentForm.control}
              errors={appointmentForm.formState.errors}
              rules={{
                required: "An earliest arrival is required",
              }}
            />
          </Grid>
          <Grid item md={4}>
            <FormDateTimePicker
              name="close"
              label="Latest Arrival"
              control={appointmentForm.control}
              errors={appointmentForm.formState.errors}
              rules={{
                required: "A latest arrival is required",
                validate: {
                  afterOpen: (v, formValues) =>
                    DateTime.fromISO(v) <= DateTime.fromISO(formValues.open)
                      ? "Latest Arrival must be after Earliest Arrival"
                      : undefined,
                  endsInFuture: (v) =>
                    DateTime.fromISO(v) <= DateTime.now()
                      ? "Latest Arrival must be in the future"
                      : undefined,
                },
              }}
            />
          </Grid>
          <Grid item md={4}>
            <FormDurationPicker
              name="service"
              control={appointmentForm.control}
            />
          </Grid>
          <Grid item md={12}>
            <FormTextField
              label="Driver Note"
              name="comment"
              multiline
              maxRows={4}
              control={appointmentForm.control}
              errors={appointmentForm.formState.errors}
            />
          </Grid>
          <Grid item md={12}>
            <Box my={4}>
              <Typography variant="h2">Contact</Typography>
            </Box>
            <ContactForm parentForm={appointmentForm} disabled={disabled} />
          </Grid>
        </Grid>
      </Box>
      <RFormButtons>
        {!props.hideDeleteButton && (
          <RButton
            color="secondary"
            onClick={handleDelete}
            loading={isDeleting}
          >
            Delete
          </RButton>
        )}
        <RButton type="submit" loading={appointmentForm.formState.isSubmitting}>
          {stopId ? "Save" : "Next"}
        </RButton>
      </RFormButtons>
    </form>
  );
};
