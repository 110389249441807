import { Box, Tab, Tabs } from "@mui/material";
import { FC, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { LoadingScreen } from "../../components/LoadingScreen";
import { RButton } from "../../components/RButton";
import { RDialog } from "../../components/RDialog";
import { RFormButtons } from "../../components/RFormButtons";
import { RouteStopForm } from "../../components/forms/RouteStopForm";
import { useShowStop } from "../../hooks/queries/useShowStop";
import { useLatestCoords } from "../../hooks/useLatestCoords";
import { errorSnackbar, useSnackbar } from "../../hooks/useSnackbar";
import { del } from "../../lib/amplify";
import type {
  DeepRoute,
  RemoveRouteStopRequest,
  RemoveRouteStopResponse,
  Stop,
} from "../../shared/api_schema";
import { StopShipments } from "../shipments/StopShipments";
import { EstimatedRoutesDialog } from "./EstimatedRoutesDialog";

type Props = {
  route: DeepRoute;
  stopId: string;
  onClose: () => void;
  onShipmentAdded?: () => void;
  onShipmentRemoved?: () => void;
  onStopUpdated?: () => void;
};

export const RouteStop: FC<Props> = ({
  route,
  stopId,
  onClose,
  onShipmentAdded,
  onShipmentRemoved,
  onStopUpdated,
}) => {
  const stopQuery = useShowStop(stopId);

  const queryClient = useQueryClient();

  const [removeRouteStopResponse, setRemoveRouteStopResponse] =
    useState<RemoveRouteStopResponse>();

  const snackbar = useSnackbar();
  const [tabIndex, setTabIndex] = useState(0);

  const removeRouteStopMutation = useMutation(
    async (request: RemoveRouteStopRequest & { stopId: string }) =>
      del<RemoveRouteStopResponse, RemoveRouteStopRequest>(
        `/stops/${request.stopId!}`,
        { ...request }
      ),
    {
      onSuccess: (response) => setRemoveRouteStopResponse(response),
      onError: (err) => errorSnackbar(err, snackbar),
    }
  );

  async function removeStop(stop: Stop, deleteStop = false) {
    return removeRouteStopMutation.mutateAsync({
      latestCoord: useLatestCoords.getState().latestCoords[stop.route!.id],
      stopId: stop.id!,
      delete: deleteStop,
    });
  }

  if (stopQuery.isLoading) {
    return <LoadingScreen />;
  }

  const stop = stopQuery.data!.stop;

  return removeRouteStopResponse ? (
    <EstimatedRoutesDialog
      estimatedRoutes={removeRouteStopResponse}
      skippedStops={removeRouteStopResponse.skippedStops}
      deletedStops={removeRouteStopResponse.deletedStops}
      onClose={() => {
        setRemoveRouteStopResponse(undefined);
        onClose();
      }}
      onConfirm={() => {
        queryClient.invalidateQueries("route");
        setRemoveRouteStopResponse(undefined);
        onClose();
      }}
    />
  ) : (
    <RDialog open={true} closeCallback={onClose} title="Edit Stop">
      <Box sx={{ borderBottom: 1, marginBottom: 6, borderColor: "divider" }}>
        <Tabs value={tabIndex} onChange={(e, val) => setTabIndex(val)}>
          <Tab label="Stop" />
          <Tab label="Shipments" />
        </Tabs>
      </Box>

      {tabIndex === 0 ? (
        <RouteStopForm
          stop={stop}
          route={route}
          deleteFunction={async () => removeStop(stop, true)}
          skipFunction={async () => removeStop(stop)}
          onSuccess={() => {
            onStopUpdated?.();
            onClose();
          }}
        />
      ) : (
        <>
          <StopShipments
            stop={stop}
            onShipmentAdded={() => {
              stopQuery.refetch();
              onShipmentAdded?.();
            }}
            onShipmentRemoved={() => {
              stopQuery.refetch();
              onShipmentRemoved?.();
            }}
          />
          <RFormButtons>
            <RButton onClick={onClose}>Done</RButton>
          </RFormButtons>
        </>
      )}
    </RDialog>
  );
};
