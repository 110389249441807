import MarkerClusterGroup from "@changey/react-leaflet-markercluster";
import { makeStyles } from "@mui/styles";
import { useEffect, type FC } from "react";
import { MapContainer, Marker, Tooltip } from "react-leaflet";
import { RegionOfInterestFitter } from "../../components/RegionOfInterestFitter";
import { MapTiles } from "../../components/map/MapTiles";
import { useRegionOfInterest } from "../../hooks/useRegionOfInterest";
import type { Vehicle } from "../../shared/api_schema";
import { MAX_ZOOM } from "../dashboard/OverviewMap";
import { AppointmentIcon } from "./AppointmentIcon";
import type { Stoplike } from "./Stoplike";
import { VehicleIcon } from "./VehicleIcon";

const useStyles = makeStyles(() => ({
  container: { border: "1px solid #999", height: "100%" },
}));

export const RouteBuilderMap: FC<{
  stoplikes: Stoplike[];
  vehicles: Vehicle[];
  onStoplikeClicked?: (stoplike: Stoplike) => void;
}> = ({ stoplikes, vehicles, onStoplikeClicked }) => {
  // Update region of interest for BoundsFitter when stoplikes or vehicles are updated
  useEffect(() => {
    const stoplikeCoords = stoplikes.map((s) => s.location.coord);
    const vehicleCoords = vehicles.map((v) => v.home.coord);
    useRegionOfInterest.setState({
      coords: [...stoplikeCoords, ...vehicleCoords],
    });
  }, [stoplikes, vehicles]);

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <MapContainer maxZoom={MAX_ZOOM} style={{ height: "100%" }}>
        <RegionOfInterestFitter />
        <MapTiles />
        <MarkerClusterGroup>
          {stoplikes
            .map((s) => (
              <Marker
                icon={AppointmentIcon}
                key={s.id}
                position={[
                  parseFloat(s.location.coord.lat),
                  parseFloat(s.location.coord.lon),
                ]}
                eventHandlers={{ click: () => onStoplikeClicked?.(s) }}
              >
                <Tooltip>{s.location.name}</Tooltip>
              </Marker>
            ))
            .reverse()}
        </MarkerClusterGroup>
        {vehicles.map((vehicle) => (
          <Marker
            icon={VehicleIcon}
            position={[
              parseFloat(vehicle.home.coord.lat),
              parseFloat(vehicle.home.coord.lon),
            ]}
            key={vehicle.id}
            zIndexOffset={Number.MAX_SAFE_INTEGER}
          >
            <Tooltip>{vehicle.name}</Tooltip>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};
