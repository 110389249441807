import FileDownload from "@mui/icons-material/FileDownload";
import FileUpload from "@mui/icons-material/FileUpload";
import { Badge } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { FC } from "react";
import { Shipment, ShipmentType } from "../../shared/api_schema";
import { exceptionTypeString } from "../../shared/frontend";

const useStyles = makeStyles(() =>
  createStyles({
    shipmentListItem: {
      display: "flex",
      alignItems: "center",
    },
    shipment: {
      fontSize: "0.8em",
      marginBlock: 0,
      color: "black",
      textDecoration: "none",
      "&:hover": {
        cursor: "pointer",
        textDecoration: "underline",
      },
    },
    clickable: {
      cursor: "pointer",
    },
  })
);

const ShipmentIcon: FC<{ shipment: Shipment }> = ({ shipment }) => {
  if (shipment.type === ShipmentType.PICKUP) {
    return <FileUpload style={{ color: "black" }} />;
  } else {
    return <FileDownload style={{ color: "black" }} />;
  }
};

export function WaypointShipment({
  shipment,
  onShowShipment,
}: {
  shipment: Shipment;
  onShowShipment: (shipmentId: string) => void;
}) {
  const classes = useStyles();
  return (
    <li key={shipment.id} className={classes.shipmentListItem}>
      <Badge
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        overlap="circular"
        color="error"
        variant="dot"
        invisible={!shipment.lastException}
        title={
          shipment.lastException
            ? exceptionTypeString(shipment.lastException)
            : undefined
        }
      >
        <ShipmentIcon shipment={shipment} />
      </Badge>

      <pre
        className={classes.shipment}
        onClick={(ev) => {
          ev.stopPropagation(); // Don't *also* fire off clicking on the Stop -- only the Shipment we just clicked
          onShowShipment(shipment.id);
        }}
      >
        {shipment.code}
      </pre>
    </li>
  );
}
