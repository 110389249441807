import { Grid, TextField } from "@mui/material";
import bbox from "@turf/bbox";
import { multiPoint } from "@turf/helpers";
import type { LatLngTuple } from "leaflet";
import { LatLngBounds } from "leaflet";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { MapContainer, Marker, useMap } from "react-leaflet";
import { MapTiles } from "../../components/map/MapTiles";
import type { LonLat } from "../../shared/api_schema";
import { MAX_ZOOM } from "../dashboard/OverviewMap";

const LonLatFitter: FC<{ lonLats: LonLat[] | undefined }> = ({ lonLats }) => {
  const map = useMap();

  if (!lonLats) {
    return null;
  }

  const bounding = bbox(multiPoint(lonLats));
  map.fitBounds(
    new LatLngBounds([bounding[1], bounding[0]], [bounding[3], bounding[2]]),
    { maxZoom: MAX_ZOOM }
  );

  return null;
};

function swapLonLats(lonLats: LonLat[] | undefined): LatLngTuple[] {
  if (!lonLats) {
    return [];
  }
  return lonLats.map((ll) => [ll[1], ll[0]]);
}

const LatLngDisplay: FC<{ latLngs: LatLngTuple[] }> = ({ latLngs }) => {
  if (latLngs.length === 1) {
    return <Marker position={latLngs[0]} />;
  } else if (latLngs.length > 1) {
    // return <Polyline positions={latLngs} />;
    return (
      <>
        {latLngs.map((ll, index) => (
          <Marker key={index} position={ll} />
        ))}
      </>
    );
  } else {
    return null;
  }
};

export const LonLatDebug: FC = () => {
  const [lonLatData, setLonLatData] = useState<string>("");
  const [lonLats, setLonLats] = useState<LonLat[]>();

  useEffect(() => {
    if (!lonLatData) {
      return;
    }

    try {
      // setLonLats(JSON.parse(lonLatData));
      const lonLats = lonLatData.split(";");
      const asPairs = lonLats.map((ll) =>
        ll.split(",").map((s) => parseFloat(s))
      );
      setLonLats(asPairs as LonLat[]);
    } catch (err: any) {
      // Don't worry about failed parsing
    }
  }, [lonLatData]);

  return (
    <Grid item container direction="column" style={{ height: "100%" }}>
      <Grid item>
        <TextField
          fullWidth
          multiline
          rows="10"
          value={lonLatData}
          onChange={(e) => setLonLatData(e.target.value)}
        />
      </Grid>
      <MapContainer maxZoom={MAX_ZOOM} style={{ flex: 1 }}>
        <LonLatFitter lonLats={lonLats} />
        <MapTiles />
        <LatLngDisplay latLngs={swapLonLats(lonLats)} />
      </MapContainer>
    </Grid>
  );
};
