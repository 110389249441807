export enum ShipmentExceptionType {
  SHIPMENT_NOT_PRESENT = "shipment_not_present", // The shipment couldn'd be located
  SHIPMENT_DAMAGED = "shipment_damaged", // Shipment damaged
  NO_ACCESS = "no_access", // Couldn't access the location
  UNSAFE = "unsafe", // Couldn't pickup/deliver due to unsafe conditions
  CUSTOMER_NOT_PRESENT = "customer_not_present", // Sender/receiver wasn't present at time of pickup/delivery
  INCORRECT_ADDRESS = "incorrect_address", // Incorrect/incomplete address
  BUSINESS_CLOSED = "business_closed",
  UNIT_BUZZER_MISSING = "unit_buzzer_missing",
  REFUSED_BY_CUSTOMER = "refused_by_customer",
}
