import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { DateTime } from "luxon";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { FormTextField } from "../../components/forms/FormTextField";
import { Page } from "../../components/Page";
import { PaginatedDataGrid } from "../../components/PaginatedDataGrid";
import { TimeSwap } from "../../components/TimeSwap";
import { get } from "../../lib/amplify";
import { Customer, Route, Stop, Zone } from "../../shared/api_schema";
import { ListStopsResponse } from "../../shared/api_schema/stops/list_stops";
import { locationFormatted } from "../../shared/frontend";

type SearchForm = { search: string };

export const Stops: FC = () => {
  const navigate = useNavigate();
  const form = useForm<SearchForm>({ defaultValues: { search: "" } });
  const [query, setQuery] = useState<string>();

  const searchQuery = useQuery<ListStopsResponse>(
    ["stop", "search", query],
    async () => get(`/stops?query=${query}`),
    { enabled: !!query }
  );

  const columns: GridColumns<Stop> = [
    {
      field: "zone",
      headerName: "Zone",
      valueGetter: ({ row }) => (row.zone as Zone).name,
      flex: 1,
    },
    {
      field: "customer",
      headerName: "Customer",
      renderCell: ({ row }) => {
        if (row.customer) {
          const customer = row.customer as Customer;
          return customer.location.name;
        } else {
          return "—";
        }
      },
      flex: 1,
    },
    {
      field: "name",
      headerName: "Name",
      valueGetter: ({ row }) => row.location.name,
      flex: 1,
    },
    {
      field: "address",
      headerName: "Address",
      renderCell: ({ row }) => {
        const address = locationFormatted(row.location);
        return (
          <Tooltip title={address}>
            <span>{address}</span>
          </Tooltip>
        );
      },
      flex: 2,
    },
    {
      headerName: "Date",
      field: "date",
      renderCell: ({ row }) => {
        const route = row.route as Route;
        const zone = row.zone as Zone;
        return DateTime.fromISO(route.begin!.expected!)
          .setZone(zone.timeZone)
          .toISODate();
      },
    },
    {
      field: "arrive",
      headerName: "Arrive",
      renderCell: ({ row }) => (
        <TimeSwap pair={row.arrive} tz={row.location.timeZone} />
      ),
    },
    {
      field: "depart",
      headerName: "Depart",
      renderCell: ({ row }) => (
        <TimeSwap pair={row.depart} tz={row.location.timeZone} />
      ),
    },
    {
      field: "route",
      headerName: "",
      renderCell: ({ row }) =>
        row.route?.id ? (
          <Button onClick={() => navigate(`/routes/${row.route!.id}`)}>
            View Route
          </Button>
        ) : null,
      width: 150,
    },
  ];

  async function formSubmit(data: SearchForm) {
    console.log(data.search);
    setQuery(data.search);
  }

  return (
    <Page>
      <Grid container>
        <Grid item md={4}>
          <form
            noValidate
            autoComplete="off"
            onSubmit={form.handleSubmit(formSubmit)}
          >
            <FormTextField
              label="Search"
              name="search"
              control={form.control}
              errors={form.formState.errors}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ display: query ? "flex" : "none" }}
                  >
                    <IconButton
                      onClick={() => {
                        form.reset();
                        setQuery(undefined);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </form>
          {query && (
            <Box>
              <Typography mt={4}>Searching for &quot;{query}&quot;</Typography>
              <Typography style={{ fontSize: 13 }}>
                (Results are limited to 100)
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid paddingTop={4} item>
        {query ? (
          <DataGrid
            loading={searchQuery.isLoading}
            rows={searchQuery.data?.stops ?? []}
            columns={columns}
            autoHeight
          />
        ) : (
          <PaginatedDataGrid model="stops" columns={columns} />
        )}
      </Grid>
    </Page>
  );
};
