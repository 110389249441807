import MoreVert from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem } from "@mui/material";
import type { FC } from "react";
import { useRef, useState } from "react";
import { routeHasBegun } from "../../lib/map_helpers";
import type { DeepRoute } from "../../shared/api_schema";
import { DeleteRouteDialog } from "./DeleteRouteDialog";
import { RedispatchRouteDialog } from "./RedispatchRouteDialog";

export const RouteDropdownMenu: FC<{ route: DeepRoute }> = ({ route }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const buttonEl = useRef(null);
  const [showRedispatchDialog, setShowRedispatchDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const canRedispatch = route.driver && !routeHasBegun(route);

  return (
    <>
      <IconButton onClick={() => setMenuOpen(true)} ref={buttonEl} size="large">
        <MoreVert />
      </IconButton>
      <Menu
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        anchorEl={buttonEl.current}
      >
        {canRedispatch && (
          <MenuItem onClick={() => setShowRedispatchDialog(true)}>
            Redispatch
          </MenuItem>
        )}
        <MenuItem onClick={() => setShowDeleteDialog(true)}>Delete</MenuItem>
      </Menu>
      <DeleteRouteDialog
        route={route}
        open={showDeleteDialog}
        onClose={() => {
          setShowDeleteDialog(false);
          setMenuOpen(false);
        }}
      />
      {canRedispatch && (
        <RedispatchRouteDialog
          route={route}
          open={showRedispatchDialog}
          onClose={() => {
            setShowRedispatchDialog(false);
            setMenuOpen(false);
          }}
        />
      )}
    </>
  );
};
