import { DateTime } from "luxon";
import type { FC } from "react";
import { useEffect, useState } from "react";
import type { TimePair } from "../shared/api_schema";
import { Colors, toLocale } from "../shared/frontend";

export const TimeSwap: FC<{
  pair: TimePair;
  tz: string;
  fmt?: Intl.DateTimeFormatOptions;
}> = ({ pair, tz, fmt = DateTime.TIME_24_SIMPLE }) => {
  // Use TIME_24_SIMPLE so it visually aligns with DurationSwap
  const [showActual, setShowActual] = useState<boolean>();
  useEffect(() => setShowActual(pair.actual != null), [pair]);
  return (
    <div
      style={{
        display: "inline-grid",
      }}
      onMouseOver={() => setShowActual(false)}
      onMouseOut={() => setShowActual(pair.actual != null)}
    >
      {pair.expected != null && (
        <pre
          style={{
            gridRow: 1,
            gridColumn: 1,
            visibility: showActual ? "hidden" : "visible",
          }}
        >
          {toLocale(pair.expected, tz, fmt)}
        </pre>
      )}
      {pair.actual != null && (
        <pre
          style={{
            gridRow: 1,
            gridColumn: 1,
            visibility: showActual ? "visible" : "hidden",
            color:
              pair.actual <= pair.expected! ? Colors.DARK_GREEN : Colors.ERROR,
          }}
        >
          {toLocale(pair.actual, tz, fmt)}
        </pre>
      )}
    </div>
  );
};
