import { Loader } from "@googlemaps/js-api-loader";
import { create } from "zustand";
import config from "../config";

const loader = new Loader({
  apiKey: config.google.API_KEY,
  version: config.google.MAPS_VERSION,
});

loader.importLibrary("maps").then((maps) => useGoogleMaps.setState({ maps }));
loader
  .importLibrary("places")
  .then((places) => useGoogleMaps.setState({ places }));

export const useGoogleMaps = create<{
  maps: google.maps.MapsLibrary | undefined;
  places: google.maps.PlacesLibrary | undefined;
}>(() => ({
  maps: undefined,
  places: undefined,
}));
