import { Box } from "@mui/material";
import { useQuery } from "react-query";
import { gitTags } from "../config";
import { get } from "../lib/amplify";

const Version = () => {
  const { data } = useQuery("version", async () => get("/version"));

  return (
    <Box
      sx={{
        marginTop: 5,
        padding: 5,
        display: "none",
      }}
      id="version-info"
    >
      <pre style={{ fontSize: 10 }}>Web Version: {gitTags()}</pre>
      {data && <pre style={{ fontSize: 10 }}>API Version: {data.version}</pre>}
    </Box>
  );
};

export default Version;
