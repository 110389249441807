import type {
  DialogProps,
  Theme} from "@mui/material";
import { Box, Dialog, DialogTitle, Grid } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import type { FC } from "react";

export const RDialog: FC<
  DialogProps & {
    closeCallback?: () => void;
  }
> = (props) => {
  const classes = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        position: "absolute",
      },
      content: {
        paddingBottom: theme.spacing(6),
        paddingRight: theme.spacing(6),
        paddingLeft: theme.spacing(6),
      },
    })
  )();

  function handleClose() {
    props.closeCallback?.();
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      classes={{ paper: classes.root }}
      maxWidth={props.maxWidth ?? "md"}
      fullWidth
    >
      <Grid container justifyContent="space-between" alignItems="flex-start">
        <Grid item>
          <DialogTitle>{props.title}</DialogTitle>
        </Grid>
        <Grid item>
          <Box>
            <IconButton aria-label="close" onClick={handleClose} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <Box className={classes.content}>{props.children}</Box>
    </Dialog>
  );
};
