import { useQuery } from "react-query";
import { get } from "../../lib/amplify";
import { AvailableVehiclesResponse } from "../../shared/api_schema";

export const useAvailableVehicles = (
  zoneId: string,
  dateISO: string,
  options?: { onSuccess: (data: AvailableVehiclesResponse) => void }
) =>
  useQuery(
    ["available_vehicles", dateISO],
    async () =>
      get<AvailableVehiclesResponse>(
        `/zones/${zoneId}/vehicles/available?date=${dateISO}`
      ),
    {
      onSuccess: options?.onSuccess,
    }
  );
