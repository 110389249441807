export enum TrackingEventType {
  UNLOADED = "unloaded", // Could be either a pickup or delivery.
  LOADED = "loaded", // Could be either a pickup or delivery.

  ENROUTE = "enroute", // Used to indicate that a vehicle with this shipment has started the route (i.e. the vehicle should arrive "today")
  ARRIVING_SOON = "arriving_soon", // Used to indicate a vehicle will be arriving soon -- after this has event been sent, the vehicle's position can be publicly tracked in relation to the shipment.

  EXCEPTION = "exception", // Used to indicate an exception took place (see ShipmentException type)

  // The following codes are "made up" and were used for TFI functionality
  // Keeping them around in case we decide to make more use of them
  DESTINATION_CHANGED = "destination_changed",
  CANCELLED = "cancelled",
}
