import MarkerClusterGroup from "@changey/react-leaflet-markercluster";
import type { FC } from "react";
import { AppointmentMarker } from "../../components/AppointmentMarker";
import { getWaypoints, isWaypointDeparted } from "../../lib/map_helpers";
import type { DeepRoute, Location } from "../../shared/api_schema";

function identicalCoords(a: Location, b: Location) {
  return a.coord.lat === b.coord.lat && a.coord.lon == b.coord.lon;
}

export const RouteAppointments: FC<{ route: DeepRoute }> = ({ route }) => {
  const waypoints = getWaypoints(route);
  const start = waypoints.shift()!;
  const finish = waypoints.pop()!;

  // Sort "last to first" so the start shows as stop 1, rather than finish showing on top of it
  // If the start is departed and sits on top of the finish, don't show it
  return (
    <>
      <AppointmentMarker waypoint={finish} />
      <MarkerClusterGroup>
        {waypoints.reverse().map((stop, idx) => (
          <AppointmentMarker key={idx} waypoint={stop} />
        ))}
      </MarkerClusterGroup>
      {!(
        isWaypointDeparted(start) &&
        identicalCoords(start.location, finish.location)
      ) && <AppointmentMarker waypoint={start} />}
    </>
  );
};
