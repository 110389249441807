import {
  Alert,
  Box,
  Button,
  Chip,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { isAxiosError } from "axios";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingScreen } from "../../components/LoadingScreen";
import { RButton } from "../../components/RButton";
import { useShowStop } from "../../hooks/queries/useShowStop";
import { useAuth } from "../../hooks/useAuth";
import { del, get } from "../../lib/amplify";
import type {
  Requirement,
  ShowShipmentResponseV2,
  Stop,
  TrackingEvent,
} from "../../shared/api_schema";
import { RequirementCard } from "./RequirementCard";
import { TrackingEventsTimeline } from "./TrackingEventsTimeline";

export const Shipment = ({
  shipmentId,
  onDeleted,
  allowDelete = true,
}: {
  shipmentId: string;
  onDeleted?: (shipmentId: string) => void;
  allowDelete?: boolean;
}) => {
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const [deletionError, setDeletionError] = useState<string>();

  const shipmentQuery = useQuery<ShowShipmentResponseV2>(
    ["shipment", shipmentId],
    async () => get(`/shipments/${shipmentId}`)
  );

  const [stopId, setStopId] = useState<string>("");

  // When the shipments query first loads, set the stop ID to be the first (i.e. "newest/most recent") stop
  useEffect(() => {
    if (shipmentQuery.data?.shipment.stops.length) {
      setStopId(shipmentQuery.data?.shipment.stops[0].id);
    }
  }, [shipmentQuery.data]);

  const stopQuery = useShowStop(stopId);

  const handleLinkToRoute = (routeId: string) => {
    navigate(`/routes/${routeId}`);
  };

  const deleteShipmentMutation = useMutation(
    async () => del(`/shipments/${shipmentId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("shipments");
        onDeleted?.(shipmentId);
      },
      onError: (error, variables, context) => {
        if (isAxiosError(error) && error.response?.status === 400) {
          setDeletionError(
            "This shipment cannot be deleted as it is linked to an active route."
          );
        } else {
          setDeletionError("There was an error deleting this shipment.");
        }
      },
    }
  );

  const routeId = stopQuery.data?.stop?.route?.id;

  if (shipmentQuery.isLoading) {
    return <LoadingScreen />;
  }

  const shipment = shipmentQuery.data!.shipment;
  const stops = shipment.stops as Stop[];

  const trackingEvents: TrackingEvent[] = (shipment.trackingEvents ?? []).sort(
    (a, b) =>
      DateTime.fromISO((b as TrackingEvent).occurredAt).toMillis() -
      DateTime.fromISO((a as TrackingEvent).occurredAt).toMillis()
  );

  const requirements = stopQuery.data?.stop.shipments.find(
    (shipment) => shipment.id === shipmentId
  )?.requirements;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h1" data-id={shipment.id}>
          <pre>{shipment.code}</pre>
        </Typography>
        <Typography variant="h4" sx={{ textTransform: "capitalize" }}>
          {shipment.type}
        </Typography>
        {shipment.tags?.map((t) => (
          <Chip label={t} key={t} color="secondary" sx={{ marginX: 0.5 }} />
        ))}
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h2">Tracking Events</Typography>
        </Box>
        <TrackingEventsTimeline
          shipment={shipment}
          trackingEvents={trackingEvents}
        />
      </Grid>

      {stops.length > 0 && (
        <>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" gap={4}>
              <Box flexGrow={1}>
                <TextField
                  select
                  fullWidth
                  label="Stop"
                  value={stopId}
                  onChange={(ev) => setStopId(ev.target.value)}
                >
                  {stops.map((stop) => {
                    const t = DateTime.fromISO(
                      stop.arrive.actual ?? stop.arrive.expected ?? stop.open,
                      { zone: stop.location.timeZone }
                    );

                    return (
                      <MenuItem key={stop.id} value={stop.id}>
                        <pre style={{ display: "inline" }}>
                          {t.toISODate()}{" "}
                        </pre>
                        {`${stop.location.name} (${t.toRelative()})`}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Box>
              <Box flexShrink={1}>
                <Button
                  onClick={() => {
                    if (routeId) {
                      handleLinkToRoute(routeId);
                    }
                  }}
                  disabled={!routeId}
                >
                  View Route
                </Button>
              </Box>
            </Box>
          </Grid>

          {requirements && requirements.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="h2">Requirements</Typography>
              <Grid container spacing={2}>
                {requirements
                  .sort((a, b) => a.type.localeCompare(b.type))
                  .map((r) => (
                    <RequirementCard
                      key={r.id}
                      requirement={r as Requirement}
                      timeZone={currentUser!.timeZone}
                    />
                  ))}
              </Grid>
            </Grid>
          )}
        </>
      )}

      {deletionError && (
        <Grid item xs={12}>
          <Alert severity="error">{deletionError}</Alert>
        </Grid>
      )}

      {allowDelete && (
        <Grid item>
          <RButton
            color="secondary"
            onClick={() => deleteShipmentMutation.mutate()}
            loading={deleteShipmentMutation.isLoading}
            disabled={!!deletionError}
          >
            Delete
          </RButton>
        </Grid>
      )}
    </Grid>
  );
};
