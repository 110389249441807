import { Box, Grid, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useLatestCoords } from "../../hooks/useLatestCoords";
import { errorSnackbar, useSnackbar } from "../../hooks/useSnackbar";
import { post, put } from "../../lib/amplify";
import { EstimatedRoutesDialog } from "../../pages/routes/EstimatedRoutesDialog";
import {
  AbsoluteTime,
  AddRouteStopRequest,
  AddRouteStopResponse,
  Contact,
  Customer,
  DeepRoute,
  EstimateRoutesResponse,
  Location,
  Stop,
  UpdateRouteStopRequest,
  UpdateRouteStopResponse,
} from "../../shared/api_schema";
import { Colors, fromClock, getRawTimeZone } from "../../shared/frontend";
import { CustomerPicker } from "../CustomerPicker";
import { RButton } from "../RButton";
import { RFormButtons } from "../RFormButtons";
import { ContactForm, EMPTY_CONTACT } from "./ContactForm";
import {
  FormDateTimePicker,
  fromTimePicker,
  toTimePicker,
} from "./FormDateTimePicker";
import { FormDurationPicker } from "./FormDurationPicker";
import { FormTextField } from "./FormTextField";
import { EMPTY_LOCATION, LocationForm } from "./LocationForm";
import { nullsToEmptyStrings } from "./form_helpers";

type RouteStopFormTypes = {
  open: AbsoluteTime;
  close: AbsoluteTime;
  location: Location;
  contact: Contact;
  service: number;
  comment: string;
  customer: Customer | null;
};

export const RouteStopForm: FC<{
  route: DeepRoute;
  stop?: Stop;
  skipFunction?: () => Promise<unknown>;
  deleteFunction?: () => Promise<unknown>;
  onSuccess?: () => void;
}> = (props) => {
  const [disabled, setDisabled] = useState(!!props.stop?.customer);
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();
  const [estimatedRoutes, setEstimatedRoutes] =
    useState<EstimateRoutesResponse>();
  const tzDetails = getRawTimeZone(props.route.zone.timeZone);

  const [isDeleting, setIsDeleting] = useState(false);
  const [isSkipping, setIsSkipping] = useState(false);

  const vehicleOpenOnRouteDate = fromClock(
    DateTime.fromISO(props.route.begin.expected!),
    props.route.vehicle.open,
    tzDetails.name
  );

  const vehicleCloseOnRouteDate = fromClock(
    DateTime.fromISO(props.route.begin.expected!),
    props.route.vehicle.close,
    tzDetails.name
  );

  const form = useForm<RouteStopFormTypes>({
    defaultValues: {
      open: toTimePicker(props.stop?.open ?? vehicleOpenOnRouteDate.toISO()),
      close: toTimePicker(props.stop?.close ?? vehicleCloseOnRouteDate.toISO()),
      service: props.stop?.service.expected ?? 600,
      location: props.stop?.location ?? {
        ...EMPTY_LOCATION,
        locality: props.route.vehicle.home.locality ?? tzDetails.mainCities[0],
        region: props.route.vehicle.home.region ?? "",
        country: props.route.vehicle.home.country ?? tzDetails.countryName,
        timeZone: tzDetails.name,
      },
      contact: props.stop?.contact
        ? nullsToEmptyStrings(props.stop.contact)
        : EMPTY_CONTACT,
      comment: props.stop?.comment ?? "",
      customer: props.stop?.customer,
    },
  });

  const addRouteStopMutation = useMutation(
    async (payload: AddRouteStopRequest) =>
      post<AddRouteStopResponse>("/routes/stops", payload),
    {
      onSuccess: setEstimatedRoutes,
      onError: (err: any) => errorSnackbar(err, snackbar),
    }
  );

  const updateRouteStopMutation = useMutation(
    async (payload: UpdateRouteStopRequest) =>
      put<UpdateRouteStopResponse>(`/routes/stops/${payload.id}`, payload),
    {
      onSuccess: (data) => {
        // An update will return either an estimation (if the changes need to trigger an optimization)
        // or a simply a "Stop" (if no optimization was needed, for example, changing the "comment")
        if ("routes" in data) {
          setEstimatedRoutes(data);
        } else {
          props.stop && queryClient.invalidateQueries(["stop", props.stop.id]);
          props.onSuccess?.();
        }
      },
      onError: (err: any) => errorSnackbar(err, snackbar),
    }
  );

  async function formSubmit(data: RouteStopFormTypes) {
    data.open = fromTimePicker(data.open, data.location.timeZone);
    data.close = fromTimePicker(data.close, data.location.timeZone);

    try {
      if (props.stop) {
        await submitUpdate(data, props.stop);
      } else {
        await submitAdd(data);
      }
    } catch {}
  }

  async function submitAdd(data: RouteStopFormTypes) {
    let payload: AddRouteStopRequest;

    if (data.customer) {
      payload = {
        route: { id: props.route.id },
        service: { expected: data.service },
        open: data.open,
        close: data.close,
        comment: data.comment,
        customer: data.customer,
      };
    } else {
      payload = {
        route: { id: props.route.id },
        service: { expected: data.service },
        open: data.open,
        close: data.close,
        location: data.location,
        comment: data.comment,
        contact: data.contact,
      };
    }

    // If we have access to a vehicle's position, include it
    return addRouteStopMutation.mutateAsync({
      ...payload,
      latestCoord: useLatestCoords.getState().latestCoords[props.route.id],
    });
  }

  async function submitUpdate(data: RouteStopFormTypes, stop: Stop) {
    let payload: UpdateRouteStopRequest;

    if (data.customer) {
      payload = {
        id: stop.id,
        route: { id: props.route.id },
        service: { expected: data.service },
        open: data.open,
        close: data.close,
        comment: data.comment,
        customer: data.customer,
      };
    } else {
      payload = {
        id: stop.id,
        route: { id: props.route.id },
        service: { expected: data.service },
        open: data.open,
        close: data.close,
        comment: data.comment,
        location: data.location,
        contact: data.contact,
      };
    }

    // If we have access to a vehicle's position, include it
    return updateRouteStopMutation.mutateAsync({
      ...payload,
      latestCoord: useLatestCoords.getState().latestCoords[props.route.id],
    });
  }

  async function handleSkip() {
    setIsSkipping(true);
    await props.skipFunction?.();
    setIsSkipping(false);
  }

  async function handleDelete() {
    setIsDeleting(true);
    await props.deleteFunction?.();
    setIsDeleting(false);
  }

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={form.handleSubmit(formSubmit)}
    >
      <Box mb={4}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <CustomerPicker
              value={form.getValues("customer")}
              onChange={(customer) => {
                if (customer) {
                  setDisabled(true);
                  form.setValue("customer", customer);
                  form.setValue(
                    "contact",
                    nullsToEmptyStrings(customer.contact)
                  );
                  form.setValue(
                    "location",
                    nullsToEmptyStrings(customer.location)
                  );
                } else {
                  form.reset();
                  setDisabled(false);
                  form.setValue("customer", null);
                }
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <LocationForm parentForm={form} disabled={disabled} />
      <Box mt={4}>
        <Grid container spacing={4}>
          <Grid item md={4}>
            <FormDateTimePicker
              name="open"
              label="Earliest Arrival"
              control={form.control}
              errors={form.formState.errors}
            />
          </Grid>
          <Grid item md={4}>
            <FormDateTimePicker
              name="close"
              label="Latest Arrival"
              control={form.control}
              errors={form.formState.errors}
              rules={{
                validate: {
                  afterOpen: (v, formValues) =>
                    DateTime.fromISO(v) <= DateTime.fromISO(formValues.open)
                      ? "Latest Arrival must be after Earliest Arrival"
                      : undefined,
                  endsInFuture: (v) =>
                    DateTime.fromISO(v) <= DateTime.now()
                      ? "Latest Arrival must be in the future"
                      : undefined,
                },
              }}
            />
          </Grid>
          <Grid item md={4}>
            <FormDurationPicker name="service" control={form.control} />
          </Grid>
          <Grid item md={12}>
            <FormTextField
              label="Driver Note"
              name="comment"
              multiline
              maxRows={4}
              control={form.control}
              errors={form.formState.errors}
            />
          </Grid>
          <Grid item md={12}>
            <Box my={4}>
              <Typography variant="h2">Contact</Typography>
            </Box>
            <ContactForm parentForm={form} disabled={disabled} />
          </Grid>
        </Grid>
      </Box>
      <RFormButtons>
        {props.skipFunction && (
          <RButton
            style={{
              color: Colors.GREY_SLATE,
              borderColor: Colors.GREY_SLATE,
            }}
            variant="outlined"
            onClick={handleSkip}
            loading={isSkipping}
          >
            Skip
          </RButton>
        )}
        <RButton color="secondary" onClick={handleDelete} loading={isDeleting}>
          Delete
        </RButton>
        <RButton type="submit" loading={form.formState.isSubmitting}>
          {props.stop ? "Update" : "Add"}
        </RButton>
      </RFormButtons>

      {estimatedRoutes && (
        <EstimatedRoutesDialog
          estimatedRoutes={estimatedRoutes}
          onClose={() => setEstimatedRoutes(undefined)}
          onConfirm={() => {
            setEstimatedRoutes(undefined);
            props.stop &&
              queryClient.invalidateQueries(["stop", props.stop.id]);
            props.onSuccess?.();
          }}
        />
      )}
    </form>
  );
};
