import type { OrganizationWithRole } from "../users/me";

export enum ProductTier {
  PERSONAL = "Personal",
  BUSINESS = "Business",
  ENTERPRISE = "Enterprise",
}

export function productTierIndex(productTier: ProductTier) {
  return Object.values(ProductTier).indexOf(productTier);
}

export function compareProductTiers(a: ProductTier, b: ProductTier) {
  return productTierIndex(a) - productTierIndex(b);
}

export type Organization = {
  id: string;
  name: string;
  productTier: ProductTier;
  liveTracking: boolean;
  knockr: boolean;
};

export type CreateOrganizationRequest = {
  name: string;
};

export type UpdateOrganizationRequest = Pick<
  Organization,
  "productTier" | "liveTracking" | "knockr"
>;

export type CreateOrganizationResponse = { organization: OrganizationWithRole };

export type ShowOrganizationResponse = {
  organization: Organization & {
    status: string | null;
  };
};

export type UpdateOrganizationResponse = { redirectUrl: string };
