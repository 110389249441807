import MarkerClusterGroup from "@changey/react-leaflet-markercluster";
import { decode } from "@mapbox/polyline";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { MapContainer, Marker, Polyline, Tooltip } from "react-leaflet";
import { useQueries } from "react-query";
import { BoundsFitter } from "../../components/BoundsFitter";
import { MapTiles } from "../../components/map/MapTiles";
import { get } from "../../lib/amplify";
import {
  GetRouteDetailsResponse,
  RelevantRoutesResponse,
  Route,
  ZonesRequiringRouteResponse,
} from "../../shared/api_schema";
import { Colors } from "../../shared/frontend";
import { AppointmentIcon } from "../routes/AppointmentIcon";
import { appointmentStoplike, scheduledStoplike } from "../routes/Stoplike";

// Maximum map zoom, to be shared across various maps
// Useful so when your bounds only include a single point or small area,
// you don't get a crazy-zoomed in view
export const MAX_ZOOM = 18;

export function OverviewMap(props: {
  zonesRequiringRoute: ZonesRequiringRouteResponse;
  relevantRoutes: RelevantRoutesResponse;
  highlightedRoute: Route | undefined;
}) {
  const [swLat, setswLat] = useState<number>();
  const [swLon, setswLon] = useState<number>();
  const [neLat, setneLat] = useState<number>();
  const [neLon, setneLon] = useState<number>();

  const stoplikes = props.zonesRequiringRoute.zones.flatMap((z) => [
    ...z.appointments.map(appointmentStoplike),
    ...z.scheduledAppointments.map(scheduledStoplike),
  ]);

  // Set initial bounds based on appointment and vehicle locations
  useEffect(() => {
    const initialCoords = [
      ...stoplikes.map((a) => a.location.coord),
      ...props.relevantRoutes.routes.map((r) => r.vehicle.home.coord),
    ];

    setswLat((old) =>
      initialCoords.reduce(
        (p, c) => (p ? Math.min(p, parseFloat(c.lat)) : parseFloat(c.lat)),
        old
      )
    );
    setswLon((old) =>
      initialCoords.reduce(
        (p, c) => (p ? Math.min(p, parseFloat(c.lon)) : parseFloat(c.lon)),
        old
      )
    );
    setneLat((old) =>
      initialCoords.reduce(
        (p, c) => (p ? Math.max(p, parseFloat(c.lat)) : parseFloat(c.lat)),
        old
      )
    );
    setneLon((old) =>
      initialCoords.reduce(
        (p, c) => (p ? Math.max(p, parseFloat(c.lon)) : parseFloat(c.lon)),
        old
      )
    );
  }, [stoplikes, props.relevantRoutes]);

  // Grab route details for all relevant routes
  const routeDetails = useQueries(
    props.relevantRoutes.routes.map((r) => ({
      queryKey: ["route_details", r.id],
      queryFn: async () => get(`/routes/${r.id}/details`),
      onSuccess(data: GetRouteDetailsResponse) {
        const allLatLons = [
          ...decode(data.history || "", 5),
          ...decode(data.plan || "", 5),
        ];

        setswLat((old) =>
          allLatLons.reduce((p, ll) => (p ? Math.min(p, ll[0]) : ll[0]), old)
        );
        setswLon((old) =>
          allLatLons.reduce((p, ll) => (p ? Math.min(p, ll[1]) : ll[1]), old)
        );
        setneLat((old) =>
          allLatLons.reduce((p, ll) => (p ? Math.max(p, ll[0]) : ll[0]), old)
        );
        setneLon((old) =>
          allLatLons.reduce((p, ll) => (p ? Math.max(p, ll[1]) : ll[1]), old)
        );
      },
    }))
  );

  // Grab available vehicles for all zones
  // function useZoneAvailableVehicles(zone: Zone) {
  //   return useAvailableVehicles(zone.id, useDateContext.getState().dateContext);
  // }
  // const availableVehicles = props.zonesRequiringRoute.zones.map(
  //   useZoneAvailableVehicles
  // );

  const highlightedIdx = props.relevantRoutes.routes.findIndex(
    (r) => r.id === props.highlightedRoute?.id
  );

  // The map would just be grey (nothing to show), so render some help text
  if (!routeDetails.length && !stoplikes.length) {
    return (
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={4}
        padding={8}
      >
        <Typography variant="h1">Welcome to Routes!</Typography>
        <Typography>
          You don&rsquo;t have any Appointments or Routes today.
        </Typography>
        <Typography>
          Click the <strong>Build</strong> button to create a new Route in the
          given Zone.
        </Typography>
      </Box>
    );
  }

  return (
    <MapContainer style={{ flexGrow: 1, zIndex: 0 }} maxZoom={MAX_ZOOM}>
      {swLat && swLon && neLat && neLon && (
        <BoundsFitter
          coords={[
            { lat: swLat.toString(), lon: swLon.toString() },
            { lat: neLat.toString(), lon: neLon.toString() },
          ]}
        />
      )}
      <MapTiles />
      {/* {availableVehicles
        .flatMap((response) => response.data?.vehicles ?? [])
        .map((vehicle) => (
          <Marker
            icon={VehicleIcon}
            position={[
              parseFloat(vehicle.home.coord.lat),
              parseFloat(vehicle.home.coord.lon),
            ]}
            key={vehicle.id}
            zIndexOffset={Number.MAX_SAFE_INTEGER}
          >
            <Tooltip>{vehicle.name}</Tooltip>
          </Marker>
        ))} */}
      {routeDetails.map((d, idx) =>
        d.data?.history ? (
          <Polyline
            key={idx}
            positions={decode(d.data.history)}
            pathOptions={{
              color: idx === highlightedIdx ? Colors.GOLD : Colors.DARK_GREEN,
            }}
          />
        ) : null
      )}
      {routeDetails.map((d, idx) =>
        d.data?.plan ? (
          <Polyline
            key={idx}
            positions={decode(d.data.plan)}
            pathOptions={{
              color: idx === highlightedIdx ? Colors.GOLD : Colors.BLUE,
            }}
          />
        ) : null
      )}
      <MarkerClusterGroup>
        {stoplikes.map((s) => (
          <Marker
            icon={AppointmentIcon}
            key={s.id}
            position={[
              parseFloat(s.location.coord.lat),
              parseFloat(s.location.coord.lon),
            ]}
          >
            <Tooltip>{s.location.name}</Tooltip>
          </Marker>
        ))}
      </MarkerClusterGroup>
    </MapContainer>
  );
}
