import type { Theme } from "@mui/material";
import { Box, Paper } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import type { FC, ReactNode } from "react";
import { Logo } from "./Logo";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      width: 200,
      margin: "20px auto",
    },
    paper: {
      width: 500,
      padding: theme.spacing(6),
      marginTop: theme.spacing(12),
      border: "1px solid black",
    },
  })
);

export const BrandedCenteredBox: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Paper className={classes.paper}>
        <div className={classes.logo}>
          <Logo />
        </div>
        {children}
      </Paper>
    </Box>
  );
};
