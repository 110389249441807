import { number, object, ObjectSchema, string } from "yup";
import { dimensionsSchema } from "../dimensions";
import { idSchema } from "../id_only";
import { locationSchema } from "../location";
import type { Vehicle } from "./vehicle";

export type CreateVehicleRequest = Omit<Vehicle, "id" | "licensePlate"> & {
  licensePlate?: string;
};

export const createVehicleSchema: ObjectSchema<CreateVehicleRequest> = object({
  name: string().required("Name is required"),
  open: string().required("Open time is required"),
  close: string().required("Close time is required"),
  home: locationSchema,
  zone: idSchema,
  capacity: dimensionsSchema,
  speedFactor: number()
    .min(0.01, "Speed factor cannot be less than 0.01")
    .max(5.0, "Speed factor cannot be more than 5.00"),
  licensePlate: string(),
});
