import { Box, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { saveAs } from "file-saver";
import { DateTime } from "luxon";
import { useState } from "react";
import { useMutation } from "react-query";
import { Page } from "../../components/Page";
import { RButton } from "../../components/RButton";
import { get } from "../../lib/amplify";

export function Reports() {
  const [dailyRouteSummaryDate, setDailyRouteSummaryDate] = useState(
    DateTime.now()
  );

  const dailyRouteSummaryQuery = useMutation(async () =>
    get(
      `/reports/daily_route_summary?date=${dailyRouteSummaryDate.toISODate()}`
    )
  );

  return (
    <Page title="Reports">
      <Typography variant="h2" sx={{ marginBottom: 4 }}>
        Daily Route Summary
      </Typography>
      <Box display="flex" alignItems="center" gap={4}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DatePicker
            value={dailyRouteSummaryDate}
            onChange={(value) =>
              setDailyRouteSummaryDate(value ?? DateTime.now())
            }
            inputFormat="yyyy-MM-dd"
            renderInput={(props) => <TextField {...props} />}
          />
        </LocalizationProvider>
        <RButton
          type="submit"
          loading={dailyRouteSummaryQuery.isLoading}
          onClick={async () => {
            dailyRouteSummaryQuery.mutateAsync(undefined, {
              onSuccess: (csv) =>
                saveAs(
                  new Blob([csv], { type: "text/csv" }),
                  `daily_route_summary_${dailyRouteSummaryDate.toISODate()}`
                ),
            });
          }}
        >
          Generate
        </RButton>
      </Box>
    </Page>
  );
}
