import type { FC, ReactNode } from "react";

export const RouteStopLine: FC<{ label: string; children: ReactNode }> = ({
  label,
  children,
}) => {
  return (
    <div style={{ opacity: 0.7, lineHeight: 0.98 }}>
      <small>
        <span style={{ display: "inline-block", width: 50, marginRight: 12 }}>
          {label}
        </span>
        {children}
      </small>
    </div>
  );
};
