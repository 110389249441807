import { Box, Button, Chip } from "@mui/material";
import type { GridColumns } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import type { FC } from "react";
import type { Location, Shipment } from "../../shared/api_schema";
import { Colors, toDurationShort, toLocale } from "../../shared/frontend";
import { Stoplike, stoplikeTypeString } from "./Stoplike";

export const RouteBuilderAppointments: FC<{
  stoplikes: Stoplike[];
  onSkip: (stoplike: Stoplike) => void;
  onStoplikeClicked: (stoplike: Stoplike) => void;
}> = ({ stoplikes, onSkip: onRemove, onStoplikeClicked }) => {
  const columns: GridColumns<Stoplike> = [
    {
      field: "location",
      headerName: "Name",
      flex: 4,
      valueFormatter: ({ value }) => (value as Location).name,
    },
    {
      field: "tags",
      headerName: "Tags",
      flex: 2,
      renderCell: ({ row }) =>
        (row.appointment?.shipments as Shipment[])
          ?.flatMap((s) => s.tags)
          .map((t) => (
            <Chip label={t} key={t} color="secondary" sx={{ marginX: 0.5 }} />
          )),
    },
    {
      field: "apptType",
      headerName: "Type",
      flex: 1,
      valueGetter: ({ row }) => stoplikeTypeString(row as Stoplike),
    },
    {
      field: "open",
      headerName: "Open",
      flex: 1,
      valueGetter: ({ row }) => toLocale(row.localOpen, row.location.timeZone),
    },
    {
      field: "close",
      headerName: "Close",
      flex: 1,
      valueGetter: ({ row }) => toLocale(row.localClose, row.location.timeZone),
    },
    {
      field: "service",
      headerName: "Service",
      flex: 1,
      valueGetter: ({ row }) => toDurationShort(row.service),
    },
    {
      field: "priority",
      headerName: "Priority",
      flex: 1,
      renderCell: ({ row }) =>
        row.appointment?.priority ? (
          <Chip
            size="small"
            label={row.appointment.priority}
            color="secondary"
          />
        ) : null,
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => {
        return (
          <Button
            size="small"
            variant="outlined"
            style={{
              color: Colors.GREY_SLATE,
              borderColor: Colors.GREY_SLATE,
              marginRight: "1em",
            }}
            onClick={(event) => {
              event.stopPropagation();
              onRemove(params.row as Stoplike);
            }}
          >
            Skip
          </Button>
        );
      },
    },
  ];

  return (
    <Box>
      <DataGrid
        rows={stoplikes}
        disableColumnSelector
        disableSelectionOnClick
        hideFooterSelectedRowCount
        autoHeight
        columns={columns}
        pageSize={50}
        onRowClick={({ row }) => onStoplikeClicked(row as Stoplike)}
      />
    </Box>
  );
};
