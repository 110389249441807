export function getTimeZoneCA(provinceOrTerritory: string): string {
  switch (provinceOrTerritory) {
    case "BC":
      return "Canada/Pacific";
    case "YT":
      return "Canada/Yukon";
    case "AB":
    case "NT":
      return "Canada/Mountain";
    case "MB":
      return "Canada/Central";
    case "SK":
      return "Canada/Saskatchewan";
    case "ON":
    case "QC":
    case "NU":
      return "Canada/Eastern";
    case "NB":
    case "NS":
      return "Canada/Atlantic";
    case "NL":
      return "Canada/Newfoundland";
    default:
      console.error(`Can't get timezone for ${provinceOrTerritory}`);
      return "Canada/Eastern";
  }
}
