import { Auth } from "aws-amplify";

async function userKey() {
  const cognitoSub = (await Auth.currentUserPoolUser()).username;
  return `user_${cognitoSub}`;
}

async function userInfo(): Promise<any> {
  return JSON.parse(localStorage.getItem(await userKey()) ?? "{}");
}

export async function setUserLocalStorage<T>(key: string, value: T) {
  const info = await userInfo();
  info[key] = value;
  localStorage.setItem(await userKey(), JSON.stringify(info));
}

export async function getUserLocalStorage<T>(
  key: string
): Promise<T | undefined> {
  return (await userInfo())[key];
}
