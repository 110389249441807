import polyline from "@mapbox/polyline";
import {
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import bbox from "@turf/bbox";
import type { LatLngExpression } from "leaflet";
import { LatLngBounds } from "leaflet";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { MapContainer, Polyline, useMap } from "react-leaflet";
import { MapTiles } from "../../components/map/MapTiles";
import { MAX_ZOOM } from "../dashboard/OverviewMap";

const LatLngFitter: FC<{ polylineData: string; precision: string }> = ({
  polylineData,
  precision,
}) => {
  const map = useMap();

  if (!polylineData) {
    return null;
  }

  const bounding = bbox(polyline.toGeoJSON(polylineData, parseInt(precision)));
  map.fitBounds(
    new LatLngBounds([bounding[1], bounding[0]], [bounding[3], bounding[2]])
  );

  return null;
};

export const PolylineDebug = () => {
  const [polylineData, setPolylineData] = useState<string>("");
  const [positions, setPositions] = useState<LatLngExpression[]>();
  const [precision, setPrecision] = useState("5");

  useEffect(() => {
    if (!polylineData) return;
    setPositions(polyline.decode(polylineData, parseInt(precision)));
  }, [polylineData, precision]);

  return (
    <Grid item container direction="column" style={{ height: "100%" }}>
      <Grid item>
        <Typography variant="h1">
          Watch out for backslashes in pasted content!
        </Typography>
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          multiline
          rows="10"
          value={polylineData}
          onChange={(e) => setPolylineData(e.target.value)}
        />
      </Grid>
      <Grid item>
        <InputLabel>Precision</InputLabel>
        <Select
          fullWidth
          name="precision"
          value={precision}
          onChange={(e) => setPrecision(e.target.value as string)}
        >
          <MenuItem value="5">5</MenuItem>
          <MenuItem value="6">6</MenuItem>
        </Select>
      </Grid>
      <MapContainer maxZoom={MAX_ZOOM} style={{ flex: 1 }}>
        <LatLngFitter polylineData={polylineData} precision={precision} />
        <MapTiles />
        <Polyline positions={positions ?? []} />
      </MapContainer>
    </Grid>
  );
};
