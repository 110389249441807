import { StoreApi, UseBoundStore, create } from "zustand";
import {
  WebsocketPrivateResponse,
  WebsocketRequest,
  WebsocketResponse,
  WebsocketSubscribeRequest,
  WebsocketSubscriptionResponse,
  WebsocketUnsubscribeRequest,
} from "../../../api_schema";
import {
  ConnectionState,
  PrivateSubscription,
  Subscription,
} from "./useWebsockets";

const INTENTIONAL_CLOSE_CODE = 1000;
const CONNECTING_TIMEOUT_MS = 10_000; // How long until we've decided a connection attempt has failed (we'll abort and try again)
const WAITING_TIMEOUT_MS = 10_000; // How long to wait before attempting a reconnection (so we don't spam connection attempts if it's unreachable)

type SubscriptionCallback = (message: WebsocketSubscriptionResponse) => void;
type PrivateSubscriptionCallback = (message: WebsocketPrivateResponse) => void;

// Return types of functions are undefined unless the connection state is CONNECTED
// That way, our consumers don't try to call something like `push` or `subscribe` *before*
// we're properly connected
type WebsocketContextType = {
  token: string;
  connectionState: ConnectionState;
  stateTimeout: unknown | undefined;
  clearStateTimeout: () => void;
  ws: WebSocket | undefined;
  subscriptions: Subscription[];
  privateSubscriptions: PrivateSubscription[];
  informSubscribers: (message: WebsocketSubscriptionResponse) => void;
  informPrivateSubscribers: (message: WebsocketPrivateResponse) => void;
  privateSubscribe:
    | ((callback: PrivateSubscriptionCallback, name: string) => () => void)
    | undefined;
  push: ((message: WebsocketRequest) => void) | undefined;
  connect: (url: string, token: string) => void;
  close: () => void;
  subscribe:
    | ((
        subject: string,
        id: string,
        callback: SubscriptionCallback,
        name: string,
        meta?: boolean
      ) => () => void)
    | undefined;
  errorEvent: Event | undefined;
};

export const useWebsocketsZ = create<WebsocketContextType>((set, get) => ({
  errorEvent: undefined,
  token: "",
  connectionState: ConnectionState.CLOSED,
  stateTimeout: undefined,
  clearStateTimeout: () => {
    console.debug("[WS]", "clearStateTimeout", get().stateTimeout);
    clearTimeout(get().stateTimeout as any);
    set({ stateTimeout: undefined });
  },
  ws: undefined,
  subscriptions: [],
  subscribe: undefined,
  informSubscribers(message: WebsocketSubscriptionResponse) {
    get()
      .subscriptions.filter(
        (subscription) =>
          subscription.subject === message.subject &&
          subscription.id === message.id
      )
      .forEach((listener) => listener.callback(message));
  },
  privateSubscriptions: [],
  informPrivateSubscribers(message: WebsocketPrivateResponse) {
    get().privateSubscriptions.forEach((listener) =>
      listener.callback(message)
    );
  },
  privateSubscribe: undefined,
  push: undefined,
  connect(url: string, token: string) {
    if (!token) {
      console.warn("[WS]", "connect requires a non-empty token");
      return;
    }

    console.debug("[WS]", "connect", url, token.slice(-4));

    // Clear previous callbacks and WebSocket
    get().clearStateTimeout();
    get().ws?.close(INTENTIONAL_CLOSE_CODE);

    // Create a new one
    const ws = new WebSocket(`${url}?token=${token}`);

    ws.onopen = () => {
      if (get().ws !== ws) {
        console.debug("[WS]", "onopen on stale websocket -- ignoring");
        return;
      }

      console.debug("[WS]", "onopen");
      get().clearStateTimeout();

      // We're connected!
      // Any of the functions requiring a connected websocket now become defined for
      // consumers of this store
      set({
        connectionState: ConnectionState.CONNECTED,
        push: (message: WebsocketRequest) => {
          console.debug("[WS]", "push");

          if (!ws) {
            console.debug("[WS]", "cannot push: No Websocket!");
          } else if (ws.readyState !== WebSocket.OPEN) {
            console.debug("[WS]", "cannot push: Ready state not OPEN.");
          } else {
            // console.debug("useWebsockets.push", message.action);
            ws.send(JSON.stringify(message));
          }
        },
        privateSubscribe: (callback, name) => {
          console.debug("[WS]", "privateSubscribe", name);

          const privateSubscription = { callback, name };
          set((prev) => ({
            privateSubscriptions: [
              ...prev.privateSubscriptions,
              privateSubscription,
            ],
          }));

          return () => {
            console.debug("[WS]", "privateUnsubscribe", name);

            set((prev) => ({
              privateSubscriptions: prev.privateSubscriptions.filter(
                (s) => s !== privateSubscription
              ),
            }));
          };
        },
        subscribe: (subject, id, callback, name, meta) => {
          console.debug("[WS]", "subscribe", name, subject, id, meta);

          const subReq: WebsocketSubscribeRequest = {
            action: "subscribe",
            subject,
            id,
            meta,
          };

          get().push?.(subReq);

          const subscription = { subject, id, callback, name, meta };
          set((prev) => ({
            subscriptions: [...prev.subscriptions, subscription],
          }));

          // Return "unsubscribe"
          return () => {
            console.debug("[WS]", "unsubscribe", name, subject, id);

            const unsubReq: WebsocketUnsubscribeRequest = {
              action: "unsubscribe",
              subject: subscription.subject,
              id: subscription.id,
            };

            get().push?.(unsubReq);

            set((prev) => ({
              subscriptions: prev.subscriptions.filter(
                (s) => s !== subscription
              ),
            }));
          };
        },
      });
    };

    ws.onclose = (ev) => {
      if (get().ws !== ws) {
        console.debug("[WS]", "onclose on stale websocket -- ignoring");
        return;
      }

      console.debug("[WS]", "onclose", ev.code, ev.reason);
      get().clearStateTimeout();

      let connectionState: ConnectionState | undefined;
      let stateTimeout: unknown | undefined;

      // The WebSocket was closed intentionally
      // Either it's an "old" one being discarded, or we're logging out and don't need it
      // Regardless, we do *not* want to try to reconnect
      // Just go into a CLOSED state
      if (ev.code === INTENTIONAL_CLOSE_CODE) {
        connectionState = ConnectionState.CLOSED;
        stateTimeout = undefined;
      }
      // The WebSocket was closed unexpectedly
      // We want to *wait* a short period of time before attempting reconnection
      // so we don't hammer a backend with requests if it fails instantly
      else {
        const waitingTimeout = setTimeout(() => {
          console.debug(
            "[WS]",
            "stateTimeout (waiting) elapsed",
            waitingTimeout
          );
          get().connect(url, get().token);
        }, WAITING_TIMEOUT_MS);

        console.debug("[WS]", "stateTimeout (waiting) created", waitingTimeout);
        connectionState = ConnectionState.WAITING;
        stateTimeout = waitingTimeout;
      }

      set({
        connectionState,
        stateTimeout,
        ws: undefined,
        push: undefined,
        subscribe: undefined,
        privateSubscribe: undefined,
      });
    };

    ws.onerror = (ev) => {
      if (get().ws !== ws) {
        console.debug("[WS]", "onerror on stale websocket -- ignoring");
        return;
      }

      console.debug("[WS]", "onerror");
      set({ errorEvent: ev });
    };

    ws.onmessage = (ev) => {
      if (get().ws !== ws) {
        console.debug("[WS]", "onmessage on stale websocket -- ignoring");
        return;
      }

      const message: WebsocketResponse = JSON.parse(ev.data);

      switch (message.type) {
        case "subscription":
          console.debug(
            "[WS]",
            "onmessage",
            message.type,
            message.action,
            message.subject,
            message.id
          );
          get().informSubscribers(message);
          break;
        case "private":
          console.debug("[WS]", "onmessage", message.type, message.action);
          get().informPrivateSubscribers(message);
          break;
      }
    };

    // Don't allow connection stage to stall for too long
    // If we want until the websocket closes itself, it may take a really long time
    // before deciding if it could connect or not
    const connectingTimeout = setTimeout(() => {
      console.debug(
        "[WS]",
        "stateTimeout (connecting) elapsed",
        connectingTimeout
      );
      get().connect(url, get().token);
    }, CONNECTING_TIMEOUT_MS);
    console.debug(
      "[WS]",
      "stateTimeout (connecting) created",
      connectingTimeout
    );

    set({
      token,
      connectionState: ConnectionState.CONNECTING,
      errorEvent: undefined,
      stateTimeout: connectingTimeout,
      ws,
    });
  },
  close() {
    get().ws?.close(INTENTIONAL_CLOSE_CODE);
  },
})) as UseBoundStore<
  StoreApi<
    Pick<
      WebsocketContextType,
      | "close"
      | "connect"
      | "connectionState"
      | "errorEvent"
      | "privateSubscribe"
      | "push"
      | "subscribe"
      | "token"
    >
  >
>;
