import type { AxiosError } from "axios";
import { useQuery } from "react-query";
import { get } from "../../lib/amplify";
import type { Shipment, ShowRouteResponse } from "../../shared/api_schema";

export default function useShowRoute(routeId: string) {
  return useQuery<ShowRouteResponse, AxiosError>(
    ["route", routeId],
    async () => {
      const response = await get<ShowRouteResponse>(`/routes/${routeId}`);

      // Sort shipments alphabetically
      response.route.stops.forEach((stop) =>
        stop.shipments.sort((a: Shipment, b: Shipment) =>
          a.code.localeCompare(b.code)
        )
      );

      return response;
    }
  );
}
