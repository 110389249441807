import { useEffect } from "react";
import type { WebsocketSubscriptionResponse } from "../../../api_schema";
import { useWebsocketsZ } from "./useWebsocketsZ";

export function useWebsocketSubscribeZ(
  subject: string,
  id: string | undefined,
  callback: (message: WebsocketSubscriptionResponse) => void,
  name: string,
  meta?: boolean
) {
  const subscribe = useWebsocketsZ((state) => state.subscribe);

  useEffect(() => {
    if (!id || !subscribe) {
      return;
    }

    return subscribe(subject, id, callback, name, meta);
  }, [callback, id, meta, name, subject, subscribe]);
}
