import { Box, Typography } from "@mui/material";
import type { FC } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { ContactForm, EMPTY_CONTACT } from "../../components/forms/ContactForm";
import { nullsToEmptyStrings } from "../../components/forms/form_helpers";
import {
  EMPTY_LOCATION,
  LocationForm,
} from "../../components/forms/LocationForm";
import { RButton } from "../../components/RButton";
import { RFormButtons } from "../../components/RFormButtons";
import { useAuth } from "../../hooks/useAuth";
import { useSnackbar } from "../../hooks/useSnackbar";
import { del, post, put } from "../../lib/amplify";
import type {
  Contact,
  CreateCustomerRequest,
  Customer,
  IdOnly,
  Location,
  UpdateCustomerRequest,
} from "../../shared/api_schema";

type CustomerFormTypes = {
  location: Location;
  contact: Contact;
};

export const CustomerForm: FC<{
  customer?: Customer;
  onSubmitSuccess?(): void;
}> = ({ customer, onSubmitSuccess }) => {
  const { currentUser } = useAuth();
  const tzDetails = currentUser!.timeZoneDetails;

  const customerForm = useForm<CustomerFormTypes>({
    defaultValues: {
      location: nullsToEmptyStrings(customer?.location) ?? {
        ...EMPTY_LOCATION,
        locality: tzDetails.mainCities[0],
        country: tzDetails.countryName,
        timeZone: tzDetails.name,
      },
      contact: nullsToEmptyStrings(customer?.contact) ?? EMPTY_CONTACT,
    },
  });
  const { handleSubmit } = customerForm;
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();

  const addCustomerMutation = useMutation(
    async (payload: CreateCustomerRequest) => post("/customers", payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("customers");
        snackbar.show("Customer added!");
        onSubmitSuccess?.();
      },
    }
  );

  const updateCustomerMutation = useMutation(
    async (customer: UpdateCustomerRequest & IdOnly) =>
      put(`/customers/${customer.id}`, customer),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("customers");
        snackbar.show("Customer updated!");
        onSubmitSuccess?.();
      },
    }
  );

  const deleteCustomerMutation = useMutation(
    async (customerId: string) => del(`/customers/${customerId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("customers");
        snackbar.show("Customer deleted!");
        onSubmitSuccess?.();
      },
    }
  );

  function formSubmit(data: CustomerFormTypes) {
    if (customer) {
      updateCustomerMutation.mutate({
        ...data,
        id: customer.id,
      });
    } else {
      addCustomerMutation.mutate(data);
    }
  }

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit(formSubmit)}>
      <Box mb={4}>
        <Typography variant="h2">Location</Typography>
      </Box>

      <LocationForm parentForm={customerForm} />

      <Box my={4}>
        <Typography variant="h2">Contact</Typography>
      </Box>

      <ContactForm parentForm={customerForm} />

      <RFormButtons>
        {customer && (
          <RButton
            color="secondary"
            onClick={async () =>
              deleteCustomerMutation.mutateAsync(customer.id)
            }
            loading={deleteCustomerMutation.isLoading}
          >
            Delete
          </RButton>
        )}
        <RButton
          loading={
            addCustomerMutation.isLoading || updateCustomerMutation.isLoading
          }
          type="submit"
        >
          Save
        </RButton>
      </RFormButtons>
    </form>
  );
};
