import { useQuery } from "react-query";
import { get } from "../../lib/amplify";
import type { ListUsersResponse } from "../../shared/api_schema";

export function getUsersQueryKey() {
  return "users";
}

export default function useUsersQuery() {
  return useQuery<ListUsersResponse, Error>(getUsersQueryKey(), async () =>
    get<ListUsersResponse>("/users")
  );
}
