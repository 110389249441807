import { TextField } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  UseControllerProps,
} from "react-hook-form";

// Outputs an always-local ISO string for use in a TimePicker/DateTimePicker, since
// they have no concept of time zone.
// "2023-03-22T08:00-06:00" -> "2023-03-22T08:00-07:00" (KEEPS local time!)
// "09:00" -> "2023-03-22T09:00-07:00" (assumes local time!)
export function toTimePicker(dateTime: string) {
  return DateTime.fromISO(dateTime, { setZone: true })
    .setZone("local", { keepLocalTime: true })
    .toISO();
}

export function fromTimePicker(dateTime: string, targetTimeZone: string) {
  return DateTime.fromISO(dateTime)
    .setZone(targetTimeZone, { keepLocalTime: true })
    .toISO();
}

export function FormDateTimePicker<
  T extends FieldValues,
  N extends Path<T>
>(props: {
  name: N;
  label: string;
  control: Control<T>;
  errors?: FieldErrors<T>;
  rules?: UseControllerProps<T, N>["rules"];
  disabled?: boolean;
}) {
  return (
    <Controller
      control={props.control}
      rules={props.rules}
      name={props.name}
      render={({ field }) => {
        // IMPORTANT NOTE!
        // The Luxon DateTime values inside the date picker are *always in local time*
        // We explicitly move them to local time, and the caller is expected to translate
        // to whatever time zone they desire *outside* of this component
        // If we try to keep the DateTime "zoned", the only way to do it is to parse the inputted
        // value with setZone: true, but that gives you a *fixed offset* time that doesn't behave
        // properly when dates are adjusted across DST boundaries
        const value = DateTime.fromISO(field.value, { setZone: true }).setZone(
          "local",
          { keepLocalTime: true }
        );
        // console.log(value.toISO());

        return (
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DateTimePicker
              label={props.label}
              ampm={true}
              value={value}
              onChange={(date) => {
                field.onChange(date ? date.toISO() : null);
              }}
              inputFormat="ff"
              disableMaskedInput={true}
              disabled={props.disabled}
              renderInput={(textFieldProps) => (
                <TextField
                  {...textFieldProps}
                  error={!!props.errors?.[props.name]}
                  helperText={props.errors?.[props.name]?.message as string}
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
}
