import AddCircle from "@mui/icons-material/AddCircle";
import { Box, Button, Grid } from "@mui/material";
import type { FC } from "react";
import { useState } from "react";
import { Page } from "../../components/Page";
import { PaginatedDataGrid } from "../../components/PaginatedDataGrid";
import { RDialog } from "../../components/RDialog";
import { useAuth } from "../../hooks/useAuth";
import { ProductTier, Vehicle } from "../../shared/api_schema";
import { toLocale } from "../../shared/frontend";
import { VehicleForm } from "./VehicleForm";

export const Vehicles: FC = () => {
  const { currentUser } = useAuth();
  const [vehicleDialogOpen, setVehicleDialogOpen] = useState<boolean>(false);
  const [editingVehicle, setEditingVehicle] = useState<Vehicle>();

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    // { field: "licensePlate", headerName: "License Plate", flex: 1 },
    {
      field: "zone",
      headerName: "Zone",
      width: 200,
      flex: 1,
      valueGetter: ({ row }: { row: any }) => row.zone.name,
    },
    {
      field: "home",
      headerName: "Home",
      flex: 1,
      valueGetter: ({ row }: { row: any }) => row.home.name,
    },
    {
      field: "open",
      headerName: "Day Start",
      flex: 1,
      valueGetter: ({ row }: { row: any }) =>
        toLocale(row.open as string, row.zone.timeZone),
    },
    {
      field: "close",
      headerName: "Day End",
      flex: 1,
      valueGetter: ({ row }: { row: any }) =>
        toLocale(row.close as string, row.zone.timeZone),
    },
  ];

  return (
    <Page>
      {currentUser?.activeOrganization.productTier !== ProductTier.PERSONAL && (
        <Box marginY={4}>
          <Grid spacing={2} container justifyContent="flex-end">
            <Grid item>
              <Button
                onClick={() => {
                  setEditingVehicle(undefined);
                  setVehicleDialogOpen(true);
                }}
                variant="contained"
                color="primary"
                startIcon={<AddCircle />}
              >
                Add Vehicle
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
      <Box>
        <PaginatedDataGrid
          model="vehicles"
          columns={columns}
          onRowClick={(params) => {
            setEditingVehicle(params.row as Vehicle);
            setVehicleDialogOpen(true);
          }}
        />
      </Box>

      <RDialog
        open={vehicleDialogOpen}
        title={`${editingVehicle ? "Edit" : "Add"} Vehicle`}
        closeCallback={() => setVehicleDialogOpen(false)}
        maxWidth="lg"
      >
        <VehicleForm
          vehicle={editingVehicle}
          onSubmitSuccess={() => setVehicleDialogOpen(false)}
        />
      </RDialog>
    </Page>
  );
};
