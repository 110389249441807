import AddCircle from "@mui/icons-material/AddCircle";
import { Box, Button, Grid } from "@mui/material";
import type { GridColumns } from "@mui/x-data-grid";
import { DateTime } from "luxon";
import type { FC } from "react";
import { useState } from "react";
import { Page } from "../../components/Page";
import { PaginatedDataGrid } from "../../components/PaginatedDataGrid";
import type {
  Appointment,
  Customer,
  Location,
  Zone,
} from "../../shared/api_schema";
import { toLocale } from "../../shared/frontend";
import { AppointmentDialog } from "./AppointmentDialog";

const columns: GridColumns = [
  {
    field: "open",
    headerName: "Open",
    flex: 1,
    valueGetter: ({ row }) =>
      toLocale(row.open, row.location.timeZone, DateTime.DATETIME_MED),
  },
  {
    field: "close",
    headerName: "Close",
    flex: 1,
    valueGetter: ({ row }) =>
      toLocale(row.close, row.location.timeZone, DateTime.DATETIME_MED),
  },
  {
    field: "zone",
    headerName: "Zone",
    flex: 1,
    valueFormatter: ({ value }) => (value as Zone).name,
  },
  {
    field: "customer",
    headerName: "Customer",
    flex: 1,
    valueFormatter: ({ value }) => (value as Customer | null)?.location.name,
  },
  {
    field: "location",
    headerName: "Name",
    flex: 1,
    valueFormatter: ({ value }) => (value as Location).name,
  },
];

export const AppointmentsList: FC = () => {
  const [appointmentDialogOpen, setAppointmentDialogOpen] = useState(false);
  const [editingAppointment, setEditingAppointment] = useState<Appointment>();

  return (
    <Page>
      <Box marginY={4}>
        <Grid spacing={2} container justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setAppointmentDialogOpen(true);
              }}
              startIcon={<AddCircle />}
            >
              Add Appointment
            </Button>
          </Grid>
        </Grid>
      </Box>
      <PaginatedDataGrid
        model="appointments"
        columns={columns}
        onRowClick={(params) => {
          setEditingAppointment(params.row as Appointment);
          setAppointmentDialogOpen(true);
        }}
      />
      {appointmentDialogOpen && (
        <AppointmentDialog
          appointment={editingAppointment}
          title={`${editingAppointment ? "Edit" : "Add"} Appointment`}
          onClose={() => {
            setAppointmentDialogOpen(false);
            setEditingAppointment(undefined);
          }}
        />
      )}
    </Page>
  );
};
